import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const Private = () => {
  const authentication = localStorage.getItem("token");

  return authentication ? <Outlet /> : <Navigate to="/LoginPage" />;
};

export default Private;
