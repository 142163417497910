import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const PageNotFound = () => {

  return (
    <StatusPage404>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 306.8 404.9"
        style={{ enableBackground: "new 0 0 306.8 404.9" }}
        xmlSpace="preserve"
      >
        <g>
          <g className="st6">
            <g>
              <path
                className="st7"
                d="M107,155.1h14.8c4.1-0.2,8.2-0.5,12.3-0.6c3.4-0.1,6.9,0.1,10.3-0.1c3.2-0.2,6.3-1,9.5-1.3
                       c3.6-0.3,7.2-0.3,9.3-3.7c0.7-1.1,1.3-2.1,2.1-3.1c-0.1-0.7-0.2-1.3-0.5-2.1c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
                       c-2-2-3.9-4-5.9-6c-0.2-0.2-0.5-0.4-0.9-0.7c-0.4,2.6-0.7,5.1-1.1,7.6c-0.1,0.7-0.3,1.5-0.5,2.2c-0.3,1.1-0.9,1.3-1.7,0.4
                       c-2.3-2.5-4.6-5.1-6.9-7.6c-0.2-0.3-0.5-0.5-0.9-1c-0.7,1.8-1.3,3.5-2,5.2c-0.8,2.2-1.6,4.5-2.5,6.7c-0.2,0.4-0.6,0.9-0.9,0.9
                       c-0.3,0-0.7-0.5-0.9-0.9c-1.8-2.9-3.5-5.8-5.2-8.7c-0.1-0.2-0.2-0.3-0.4-0.5c-0.9-1.1-1.6-1.1-2.3,0.1c-1.1,2-2.1,4-3.1,6.1
                       c-0.6,1.2-1.2,2.4-1.8,3.5c-0.6,1-1.1,1-1.7,0.1c-1.9-3.1-3.7-6.3-5.6-9.5c-0.2-0.4-0.6-0.8-0.9-1.1c-0.4,0.3-0.9,0.6-1.1,1
                       c-2,3-3.9,6-5.9,9c-0.8,1.2-1.1,1.2-2.1,0.2c-2.3-2.4-3.7-5.4-5.1-8.5c-0.4-0.9-0.8-1.7-1.2-2.8c-0.3,0.4-0.5,0.6-0.6,0.8
                       c-1.7,3.4-3.4,6.8-5.2,10.1c-0.1,0.1-0.2,0.3-0.3,0.4v1.3C100.7,153.9,103.8,154.7,107,155.1z"
              />
              <path
                className="st7"
                d="M144.9,46.1c-9.3-2.8-18.9-3.3-28.5-3.8c-9-0.5-18.1,0.6-27.1,0c-0.1,0-0.2,0-0.3-0.1
                       c0.4,0.9,0.6,1.8,0.6,2.8c0.2,0.1,0.3,0.1,0.4,0.3c0.7,1.3,1.4,2.6,2,4c1.3,3,2.4,6,3.7,9.3c1.2-2.2,2.3-4.3,3.6-6.3
                       c1.5-2.3,3.1-4.5,4.7-6.6c0.3-0.4,1-0.5,1.5-0.8c0.2,0.5,0.4,0.9,0.5,1.4c0.9,4.5,1.8,9,2.7,13.5c0.2,0,0.3,0,0.5,0
                       c0.6-0.7,1.2-1.3,1.7-2c2.7-3.5,5.5-7.1,8.2-10.6c0.1-0.2,0.3-0.4,0.5-0.5c0.5-0.3,0.9-0.6,1.4-0.9c0.2,0.5,0.6,1,0.6,1.5
                       c0.7,4.5,1.3,9.1,2,14c0.5-0.6,0.9-0.9,1.1-1.3c2.4-3.6,4.9-7.1,7.3-10.7c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.6-0.4,0.9-0.4
                       c0.2,0,0.5,0.4,0.5,0.7c0.3,2.3,0.7,4.5,0.9,6.8c0.3,2.6,0.5,5.1,0.8,7.7c0.1,0.1,0.3,0.2,0.4,0.3c0.9-1,1.7-1.9,2.6-2.9
                       c2.2-2.4,4.5-4.9,6.8-7.3c0.5-0.6,1.4-0.8,2.1-1.2c0.2,0.8,0.7,1.5,0.7,2.3c0.1,3.8,0.1,7.5,0.1,11.3c0,0.6,0,1.1,0,1.9
                       c1.3-1.2,2.4-2.4,3.6-3.4c2.1-1.7,4.2-3.4,6.3-5c1.1-0.8,1.6-0.5,1.7,0.9c0.3,4.2-0.7,8.2-1.9,12.1c-0.2,0.6-0.4,1.3-0.6,2.2
                       c0.9-0.5,1.6-0.9,2.2-1.4c2.7-1.8,5.3-3.6,8-5.3c1.5-1,1.9-0.8,2.1,1c0.7,4.6,0.1,9.1-0.6,13.6c-0.1,0.5-0.2,0.9-0.2,1.5
                       c1.4,0.2,2.6-0.4,3.8-1c1.8-0.9,3.6-1.9,5.5-2.7c0.1-0.1,0.3-0.1,0.6-0.1c0.1-0.3,0.3-0.6,0.7-0.6c1-0.1,1.9-0.7,2.7-1.2
                       C176.5,61.7,161,50.9,144.9,46.1z"
              />
              <path
                className="st8"
                d="M95.7,58.4c1.2-2.2,2.3-4.3,3.6-6.3c1.5-2.3,3.1-4.5,4.7-6.6c0.3-0.4,1-0.5,1.5-0.8
                       c0.2,0.5,0.4,0.9,0.5,1.4c0.9,4.5,1.8,9,2.7,13.5c0.2,0,0.3,0,0.5,0c0.6-0.7,1.2-1.3,1.7-2c2.7-3.5,5.5-7.1,8.2-10.6
                       c0.1-0.2,0.3-0.4,0.5-0.5c0.5-0.3,0.9-0.6,1.4-0.9c0.2,0.5,0.6,1,0.6,1.5c0.7,4.5,1.3,9.1,2,14c0.5-0.6,0.9-0.9,1.1-1.3
                       c2.4-3.6,4.9-7.1,7.3-10.7c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.6-0.4,0.9-0.4c0.2,0,0.5,0.4,0.5,0.7c0.3,2.3,0.7,4.5,0.9,6.8
                       c0.3,2.6,0.5,5.1,0.8,7.7c0.1,0.1,0.3,0.2,0.4,0.3c0.9-1,1.7-1.9,2.6-2.9c2.2-2.4,4.5-4.9,6.8-7.3c0.5-0.6,1.4-0.8,2.1-1.2
                       c0.2,0.8,0.7,1.5,0.7,2.3c0.1,3.8,0.1,7.5,0.1,11.3c0,0.6,0,1.1,0,1.9c1.3-1.2,2.4-2.4,3.6-3.4c2.1-1.7,4.2-3.4,6.3-5
                       c1.1-0.8,1.6-0.5,1.7,0.9c0.3,4.2-0.7,8.2-1.9,12.1c-0.2,0.6-0.4,1.3-0.6,2.2c0.9-0.5,1.6-0.9,2.2-1.4c2.7-1.8,5.3-3.6,8-5.3
                       c1.5-1,1.9-0.8,2.1,1c0.7,4.6,0.1,9.1-0.6,13.6c-0.1,0.5-0.2,0.9-0.2,1.5c1.4,0.2,2.6-0.4,3.8-1c1.8-0.9,3.6-1.9,5.5-2.7
                       c0.4-0.2,1.2-0.1,1.3,0.1c0.4,0.6-0.2,1-0.7,1.2c-2,0.9-3.9,2-6,2.8c-1.3,0.6-2.7,0.9-4.1,1.3c-1.4,0.3-1.7-0.1-1.5-1.5
                       c0.4-2.3,0.9-4.6,1.2-7c0.2-2.1,0.2-4.1,0.2-6.2c0-1.2-0.6-1.5-1.7-0.9c-1.2,0.7-2.4,1.6-3.6,2.4c-2,1.3-4.1,2.7-6.1,4
                       c-0.4,0.2-0.9,0.2-1.4,0.2c0-0.5-0.2-1-0.1-1.4c0.8-3.2,1.7-6.4,2.4-9.6c0.3-1.4,0.3-2.9,0.5-4.4c-0.1-0.1-0.2-0.1-0.3-0.2
                       c-1.5,1.2-3.1,2.4-4.6,3.7c-1.7,1.6-3.3,3.2-5,4.8c-0.3,0.3-0.9,0.5-1.3,0.7c-0.2-0.5-0.6-1-0.6-1.4c0-3.3,0.1-6.6,0.2-9.9
                       c0-0.7,0-1.5-0.2-2.1c-0.1-0.5-0.4-1.2-0.9-1.4c-0.3-0.1-1,0.4-1.3,0.7c-0.7,0.7-1.4,1.6-2.1,2.3c-2,2.2-4.1,4.4-6.2,6.5
                       c-0.4,0.4-1,0.5-1.5,0.8c-0.2-0.6-0.6-1.1-0.6-1.7c-0.4-3.9-0.8-7.8-1.2-11.7c0-0.3-0.1-0.6-0.2-1.3c-0.4,0.6-0.6,0.9-0.8,1.3
                       c-2.6,3.5-5.1,7-7.7,10.5c-0.3,0.3-0.8,0.5-1.3,0.7c-0.2-0.4-0.5-0.8-0.6-1.2c-0.8-4.6-1.6-9.2-2.4-13.9
                       c-0.3,0.4-0.6,0.7-0.9,1c-3,3.8-6,7.7-9,11.5c-0.4,0.5-1.1,0.9-1.7,1.3c-0.3-0.6-0.8-1.3-0.9-1.9c-1.1-4.2-2.1-8.3-3.2-12.6
                       c-0.2,0.2-0.5,0.4-0.7,0.7c-1.5,2.3-2.9,4.6-4.3,6.9c-1.1,1.8-2.2,3.6-3.3,5.4c-0.2,0.3-0.7,0.5-1.1,0.8
                       c-0.2-0.3-0.6-0.7-0.7-1c-1.2-4.6-3-9-5.2-13.2c-0.2-0.4-0.5-0.7-0.5-1.1c0-0.4,0.2-0.8,0.3-1.1c0.4,0.2,0.9,0.2,1,0.5
                       c0.7,1.3,1.4,2.6,2,4C93.2,52.2,94.4,55.2,95.7,58.4z"
              />
              <path
                className="st8"
                d="M157.8,137.1c-0.4,2.6-0.7,5.1-1.1,7.6c-0.1,0.7-0.3,1.5-0.5,2.2c-0.3,1.1-0.9,1.3-1.7,0.4
                       c-2.3-2.5-4.6-5.1-6.9-7.6c-0.2-0.3-0.5-0.5-0.9-1c-0.7,1.8-1.3,3.5-2,5.2c-0.8,2.2-1.6,4.5-2.5,6.7c-0.2,0.4-0.6,0.9-0.9,0.9
                       c-0.3,0-0.7-0.5-0.9-0.9c-1.8-2.9-3.5-5.8-5.2-8.7c-0.1-0.2-0.2-0.3-0.4-0.5c-0.9-1.1-1.6-1.1-2.3,0.1c-1.1,2-2.1,4-3.1,6.1
                       c-0.6,1.2-1.2,2.4-1.8,3.5c-0.6,1-1.1,1-1.7,0.1c-1.9-3.1-3.7-6.3-5.6-9.5c-0.2-0.4-0.6-0.8-0.9-1.1c-0.4,0.3-0.9,0.6-1.1,1
                       c-2,3-3.9,6-5.9,9c-0.8,1.2-1.1,1.2-2.1,0.2c-2.3-2.4-3.7-5.4-5.1-8.5c-0.4-0.9-0.8-1.7-1.2-2.8c-0.3,0.4-0.5,0.6-0.6,0.8
                       c-1.7,3.4-3.4,6.8-5.2,10.1c-0.2,0.4-0.8,0.9-1.1,0.9c-0.8-0.1-0.5-0.8-0.3-1.3c1.6-4.4,4-8.4,6.5-12.4
                       c0.6-0.9,1.2-0.8,1.6,0.2c1.1,2.6,2.2,5.2,3.4,7.7c0.6,1.3,1.6,2.5,2.5,3.8c2.5-3.4,4.8-6.6,7.2-9.7c1.6-2.1,1.7-2.1,3,0.2
                       c1.3,2.4,2.6,4.8,3.8,7.2c0.2,0.3,0.3,0.6,0.5,0.8c0.4,0.4,0.9,0.8,1.4,1.2c0.4-0.4,0.8-0.9,1.1-1.4c1.6-2.9,3.2-5.9,4.9-8.8
                       c0.9-1.6,1.4-1.5,2.4,0c2.1,3.2,4.2,6.5,6.5,9.9c0.6-1.6,1.1-3.2,1.7-4.7c0.9-2.2,1.7-4.5,2.7-6.7c0.6-1.3,1-1.3,2-0.2
                       c2.2,2.5,4.4,5,6.6,7.6c0.2,0.2,0.3,0.3,0.8,0.7c0.3-2.5,0.6-4.8,0.9-7.1c0.1-0.9,0.2-1.9,0.4-2.8c0.2-1.1,0.7-1.3,1.6-0.5
                       c1.8,1.7,3.5,3.4,5.3,5.1c0.8,0.8,1.6,1.7,2.4,2.5c0.4,0.4,1,0.9,0.4,1.5c-0.7,0.7-1.1-0.1-1.6-0.5c-2-2-3.9-4-5.9-6
                       C158.5,137.6,158.2,137.4,157.8,137.1z"
              />
            </g>
            <path
              className="st8"
              d="M176.7,166.3c-5,2.6-10,4.3-15.2,5.3c-7.1,1.4-14.3,1.8-21.5,1.5c-8.2-0.3-16.2-1.3-24.1-3.7
                     c-9.3-2.9-17.6-7.5-24.5-14.5c-0.6-0.6-1.2-1.2-1.7-1.8c-0.8-1-1-2.1-0.4-3.2c0.5-1.1,1.6-1.2,2.7-1.1
                     c10.8,0.5,21.6,1.1,32.3,1.3c10.3,0.2,20.4-0.8,30.4-3.4c4.9-1.3,9.6-3.1,14-5.9c8.4-5.3,13.4-12.8,14.5-22.7
                     c1.2-11.4-0.2-22.4-4.7-33c-5.2-12.5-14.1-21.6-26.1-27.8c-8.3-4.2-16.9-7.4-26.1-8.7c-7.5-1-15-1.1-22.5-0.7
                     c-5.5,0.3-11,0.8-16.5,1.3c-1.2,0.1-2.3-0.1-2.9-1.2c-0.6-1.2-0.3-2.2,0.5-3.2c5.3-7.1,12.2-12.3,20-16.5
                     c10.2-5.5,21.1-8.7,32.5-10c10-1.1,20-1.1,29.9,0.3c16.8,2.4,32.1,8.2,44.8,19.7c10.1,9.2,17,20.4,21.5,33.2
                     c4.8,14,6.2,28.3,4.9,43c-1.4,16.1-6,31.4-11.7,46.4c-3.8,10.2-7.7,20.3-11.3,30.6c-2.9,8.3-4.7,16.9-5.4,25.6
                     c-0.9,10.8,0.4,21.3,4.8,31.3c0.7,1.6,0.4,2.9-0.8,3.7c-1.3,0.9-2.8,0.6-3.4-0.9c-0.3-0.8-0.8-1-1.6-0.9
                     c-7.1,0.5-14.2,1-21.3,1.6c-5.2,0.4-10.4,0.8-15.5,1.1c-0.8,0.1-1.5,0.1-1.7,1.2c-0.2,1-1.7,1.7-2.7,1.6c-1.2-0.1-1.8-0.9-2-2.1
                     c-1.7-9.7-3.1-19.3-3.2-29.2c-0.1-9.7,1.6-19,4.5-28.1c2.8-8.7,5.6-17.5,8.4-26.2C176,169,176.3,167.8,176.7,166.3z"
            />
            <path
              className="st7 tongue"
              d="M185.4,106.7c-5.7,4.1-13.5,0.8-18.1-3.2c-8.6-7.3-17.6-13.5-29.7-13.2c-5.9,0.3-10,3.2-15.1,5.4
                            c-3.8,1.6-8.6,2.2-12.7,2.4c-7,0.3-16.2-3-21.6,2.7c-5.7,6.5-1.9,15.9,5.9,17.8c5.9,1.6,14.8,1.1,20.3-1.6
                            c7-3,11.6-10.5,18.9-11.6c6.8-1.1,12.7,1.4,18.6,4.1c5.1,2.7,10,6.5,15.4,8.4c5.7,2.2,11.9,0.8,17.5-0.5"
            />
            <g>
              <path
                className="st7"
                d="M167.3,39.3c7.7,0,14.2-6.4,14.2-14.2c0-7.7-6.4-14.2-14.2-14.2c-7.7,0-14.2,6.4-14.2,14.2
                       C153.1,32.9,159.6,39.3,167.3,39.3L167.3,39.3z"
              />
            </g>
            <g>
              <path
                className="st7"
                d="M129.8,36.9c7.7,0,14.2-6.4,14.2-14.2c0-7.7-6.4-14.2-14.2-14.2c-7.7,0-14.2,6.4-14.2,14.2
                       C115.6,30.4,122,36.9,129.8,36.9L129.8,36.9z"
              />
            </g>
            <path
              className="st8"
              d="M269.4,214.4c-1.8,1.9-3.5,3.8-5.3,5.6c-0.6,0.7-1.3,1.3-2,1.9c-1.4,1.3-3,1.2-4.1-0.4
                     c-1.4-2-2.6-4-3.9-6.1c-0.7-1.2-1.4-2.5-2.1-3.8c-1.8,1.1-3.5,2.2-5.3,3.1c-1.8,0.9-3.7,1.7-5.6,2.4c-2.3,0.8-3.5-0.1-3.8-2.4
                     c-1.2-9.5,2.9-18.3,10.5-22.5c3.3-1.8,6.8-2.5,10.5-2.4c4.8,0.1,9.4,0.9,13.7,2.9c7.4,3.5,11.3,10.2,10.8,18.4
                     c-0.2,3.2-1,6.2-2.2,9.2c-0.9,2.2-2.7,2.7-4.5,1.2c-2-1.8-3.8-3.8-5.7-5.8C270.1,215.3,269.8,214.9,269.4,214.4z"
            />
            <path
              className="st8"
              d="M76.2,213.2c-2,2.1-3.9,4.2-5.8,6.2c-0.6,0.6-1.2,1.2-1.8,1.8c-1.7,1.5-3.5,1.2-4.4-0.9
                     c-1.4-3.1-2.6-6.3-3.9-9.4c-0.1-0.4-0.3-0.7-0.5-1.3c-1.1,0.6-2.1,1.2-3.1,1.8c-2.3,1.3-4.6,2.6-6.9,3.8
                     c-2.5,1.2-4.1,0.1-4.1-2.7c0-6.1,1.5-11.8,5.4-16.6c3.5-4.3,8.2-6.5,13.7-6.7c4.6-0.2,9.2,0.2,13.6,1.9
                     c7.4,2.8,11.6,9.3,11.2,17.2c-0.2,4-1.4,7.7-3.1,11.2c-0.8,1.6-2.7,2-4.1,0.6c-1.8-1.8-3.4-3.8-5.1-5.7
                     C76.8,214,76.5,213.7,76.2,213.2z"
            />
          </g>
          <g>
            <g>
              <path
                className="st9"
                d="M97,158.1h14.8c4.1-0.2,8.2-0.5,12.3-0.6c3.4-0.1,6.9,0.1,10.3-0.1c3.2-0.2,6.3-1,9.5-1.3
                       c3.6-0.3,7.2-0.3,9.3-3.7c0.7-1.1,1.3-2.1,2.1-3.1c-0.1-0.7-0.2-1.3-0.5-2.1c0,0,0-0.1,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
                       c-2-2-3.9-4-5.9-6c-0.2-0.2-0.5-0.4-0.9-0.7c-0.4,2.6-0.7,5.1-1.1,7.6c-0.1,0.7-0.3,1.5-0.5,2.2c-0.3,1.1-0.9,1.3-1.7,0.4
                       c-2.3-2.5-4.6-5.1-6.9-7.6c-0.2-0.3-0.5-0.5-0.9-1c-0.7,1.8-1.3,3.5-2,5.2c-0.8,2.2-1.6,4.5-2.5,6.7c-0.2,0.4-0.6,0.9-0.9,0.9
                       c-0.3,0-0.7-0.5-0.9-0.9c-1.8-2.9-3.5-5.8-5.2-8.7c-0.1-0.2-0.2-0.3-0.4-0.5c-0.9-1.1-1.6-1.1-2.3,0.1c-1.1,2-2.1,4-3.1,6.1
                       c-0.6,1.2-1.2,2.4-1.8,3.5c-0.6,1-1.1,1-1.7,0.1c-1.9-3.1-3.7-6.3-5.6-9.5c-0.2-0.4-0.6-0.8-0.9-1.1c-0.4,0.3-0.9,0.6-1.1,1
                       c-2,3-3.9,6-5.9,9c-0.8,1.2-1.1,1.2-2.1,0.2c-2.3-2.4-3.7-5.4-5.1-8.5c-0.4-0.9-0.8-1.7-1.2-2.8c-0.3,0.4-0.5,0.6-0.6,0.8
                       c-1.7,3.4-3.4,6.8-5.2,10.1c-0.1,0.1-0.2,0.3-0.3,0.4v1.3C90.7,156.9,93.8,157.7,97,158.1z"
              />
              <path
                className="st9"
                d="M134.9,49.1c-9.3-2.8-18.9-3.3-28.5-3.8c-9-0.5-18.1,0.6-27.1,0c-0.1,0-0.2,0-0.3-0.1
                       c0.4,0.9,0.6,1.8,0.6,2.8c0.2,0.1,0.3,0.1,0.4,0.3c0.7,1.3,1.4,2.6,2,4c1.3,3,2.4,6,3.7,9.3c1.2-2.2,2.3-4.3,3.6-6.3
                       c1.5-2.3,3.1-4.5,4.7-6.6c0.3-0.4,1-0.5,1.5-0.8c0.2,0.5,0.4,0.9,0.5,1.4c0.9,4.5,1.8,9,2.7,13.5c0.2,0,0.3,0,0.5,0
                       c0.6-0.7,1.2-1.3,1.7-2c2.7-3.5,5.5-7.1,8.2-10.6c0.1-0.2,0.3-0.4,0.5-0.5c0.5-0.3,0.9-0.6,1.4-0.9c0.2,0.5,0.6,1,0.6,1.5
                       c0.7,4.5,1.3,9.1,2,14c0.5-0.6,0.9-0.9,1.1-1.3c2.4-3.6,4.9-7.1,7.3-10.7c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.6-0.4,0.9-0.4
                       c0.2,0,0.5,0.4,0.5,0.7c0.3,2.3,0.7,4.5,0.9,6.8c0.3,2.6,0.5,5.1,0.8,7.7c0.1,0.1,0.3,0.2,0.4,0.3c0.9-1,1.7-1.9,2.6-2.9
                       c2.2-2.4,4.5-4.9,6.8-7.3c0.5-0.6,1.4-0.8,2.1-1.2c0.2,0.8,0.7,1.5,0.7,2.3c0.1,3.8,0.1,7.5,0.1,11.3c0,0.6,0,1.1,0,1.9
                       c1.3-1.2,2.4-2.4,3.6-3.4c2.1-1.7,4.2-3.4,6.3-5c1.1-0.8,1.6-0.5,1.7,0.9c0.3,4.2-0.7,8.2-1.9,12.1c-0.2,0.6-0.4,1.3-0.6,2.2
                       c0.9-0.5,1.6-0.9,2.2-1.4c2.7-1.8,5.3-3.6,8-5.3c1.5-1,1.9-0.8,2.1,1c0.7,4.6,0.1,9.1-0.6,13.6c-0.1,0.5-0.2,0.9-0.2,1.5
                       c1.4,0.2,2.6-0.4,3.8-1c1.8-0.9,3.6-1.9,5.5-2.7c0.1-0.1,0.3-0.1,0.6-0.1c0.1-0.3,0.3-0.6,0.7-0.6c1-0.1,1.9-0.7,2.7-1.2
                       C166.5,64.7,151,53.9,134.9,49.1z"
              />
              <path
                className="st8"
                d="M85.7,61.4c1.2-2.2,2.3-4.3,3.6-6.3c1.5-2.3,3.1-4.5,4.7-6.6c0.3-0.4,1-0.5,1.5-0.8
                       c0.2,0.5,0.4,0.9,0.5,1.4c0.9,4.5,1.8,9,2.7,13.5c0.2,0,0.3,0,0.5,0c0.6-0.7,1.2-1.3,1.7-2c2.7-3.5,5.5-7.1,8.2-10.6
                       c0.1-0.2,0.3-0.4,0.5-0.5c0.5-0.3,0.9-0.6,1.4-0.9c0.2,0.5,0.6,1,0.6,1.5c0.7,4.5,1.3,9.1,2,14c0.5-0.6,0.9-0.9,1.1-1.3
                       c2.4-3.6,4.9-7.1,7.3-10.7c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.6-0.4,0.9-0.4c0.2,0,0.5,0.4,0.5,0.7c0.3,2.3,0.7,4.5,0.9,6.8
                       c0.3,2.6,0.5,5.1,0.8,7.7c0.1,0.1,0.3,0.2,0.4,0.3c0.9-1,1.7-1.9,2.6-2.9c2.2-2.4,4.5-4.9,6.8-7.3c0.5-0.6,1.4-0.8,2.1-1.2
                       c0.2,0.8,0.7,1.5,0.7,2.3c0.1,3.8,0.1,7.5,0.1,11.3c0,0.6,0,1.1,0,1.9c1.3-1.2,2.4-2.4,3.6-3.4c2.1-1.7,4.2-3.4,6.3-5
                       c1.1-0.8,1.6-0.5,1.7,0.9c0.3,4.2-0.7,8.2-1.9,12.1c-0.2,0.6-0.4,1.3-0.6,2.2c0.9-0.5,1.6-0.9,2.2-1.4c2.7-1.8,5.3-3.6,8-5.3
                       c1.5-1,1.9-0.8,2.1,1c0.7,4.6,0.1,9.1-0.6,13.6c-0.1,0.5-0.2,0.9-0.2,1.5c1.4,0.2,2.6-0.4,3.8-1c1.8-0.9,3.6-1.9,5.5-2.7
                       c0.4-0.2,1.2-0.1,1.3,0.1c0.4,0.6-0.2,1-0.7,1.2c-2,0.9-3.9,2-6,2.8c-1.3,0.6-2.7,0.9-4.1,1.3c-1.4,0.3-1.7-0.1-1.5-1.5
                       c0.4-2.3,0.9-4.6,1.2-7c0.2-2.1,0.2-4.1,0.2-6.2c0-1.2-0.6-1.5-1.7-0.9c-1.2,0.7-2.4,1.6-3.6,2.4c-2,1.3-4.1,2.7-6.1,4
                       c-0.4,0.2-0.9,0.2-1.4,0.2c0-0.5-0.2-1-0.1-1.4c0.8-3.2,1.7-6.4,2.4-9.6c0.3-1.4,0.3-2.9,0.5-4.4c-0.1-0.1-0.2-0.1-0.3-0.2
                       c-1.5,1.2-3.1,2.4-4.6,3.7c-1.7,1.6-3.3,3.2-5,4.8c-0.3,0.3-0.9,0.5-1.3,0.7c-0.2-0.5-0.6-1-0.6-1.4c0-3.3,0.1-6.6,0.2-9.9
                       c0-0.7,0-1.5-0.2-2.1c-0.1-0.5-0.4-1.2-0.9-1.4c-0.3-0.1-1,0.4-1.3,0.7c-0.7,0.7-1.4,1.6-2.1,2.3c-2,2.2-4.1,4.4-6.2,6.5
                       c-0.4,0.4-1,0.5-1.5,0.8c-0.2-0.6-0.6-1.1-0.6-1.7c-0.4-3.9-0.8-7.8-1.2-11.7c0-0.3-0.1-0.6-0.2-1.3c-0.4,0.6-0.6,0.9-0.8,1.3
                       c-2.6,3.5-5.1,7-7.7,10.5c-0.3,0.3-0.8,0.5-1.3,0.7c-0.2-0.4-0.5-0.8-0.6-1.2c-0.8-4.6-1.6-9.2-2.4-13.9
                       c-0.3,0.4-0.6,0.7-0.9,1c-3,3.8-6,7.7-9,11.5c-0.4,0.5-1.1,0.9-1.7,1.3c-0.3-0.6-0.8-1.3-0.9-1.9c-1.1-4.2-2.1-8.3-3.2-12.6
                       c-0.2,0.2-0.5,0.4-0.7,0.7c-1.5,2.3-2.9,4.6-4.3,6.9c-1.1,1.8-2.2,3.6-3.3,5.4c-0.2,0.3-0.7,0.5-1.1,0.8
                       c-0.2-0.3-0.6-0.7-0.7-1c-1.2-4.6-3-9-5.2-13.2c-0.2-0.4-0.5-0.7-0.5-1.1c0-0.4,0.2-0.8,0.3-1.1c0.4,0.2,0.9,0.2,1,0.5
                       c0.7,1.3,1.4,2.6,2,4C83.2,55.2,84.4,58.2,85.7,61.4z"
              />
              <path
                className="st8"
                d="M147.8,140.1c-0.4,2.6-0.7,5.1-1.1,7.6c-0.1,0.7-0.3,1.5-0.5,2.2c-0.3,1.1-0.9,1.3-1.7,0.4
                       c-2.3-2.5-4.6-5.1-6.9-7.6c-0.2-0.3-0.5-0.5-0.9-1c-0.7,1.8-1.3,3.5-2,5.2c-0.8,2.2-1.6,4.5-2.5,6.7c-0.2,0.4-0.6,0.9-0.9,0.9
                       c-0.3,0-0.7-0.5-0.9-0.9c-1.8-2.9-3.5-5.8-5.2-8.7c-0.1-0.2-0.2-0.3-0.4-0.5c-0.9-1.1-1.6-1.1-2.3,0.1c-1.1,2-2.1,4-3.1,6.1
                       c-0.6,1.2-1.2,2.4-1.8,3.5c-0.6,1-1.1,1-1.7,0.1c-1.9-3.1-3.7-6.3-5.6-9.5c-0.2-0.4-0.6-0.8-0.9-1.1c-0.4,0.3-0.9,0.6-1.1,1
                       c-2,3-3.9,6-5.9,9c-0.8,1.2-1.1,1.2-2.1,0.2c-2.3-2.4-3.7-5.4-5.1-8.5c-0.4-0.9-0.8-1.7-1.2-2.8c-0.3,0.4-0.5,0.6-0.6,0.8
                       c-1.7,3.4-3.4,6.8-5.2,10.1c-0.2,0.4-0.8,0.9-1.1,0.9c-0.8-0.1-0.5-0.8-0.3-1.3c1.6-4.4,4-8.4,6.5-12.4
                       c0.6-0.9,1.2-0.8,1.6,0.2c1.1,2.6,2.2,5.2,3.4,7.7c0.6,1.3,1.6,2.5,2.5,3.8c2.5-3.4,4.8-6.6,7.2-9.7c1.6-2.1,1.7-2.1,3,0.2
                       c1.3,2.4,2.6,4.8,3.8,7.2c0.2,0.3,0.3,0.6,0.5,0.8c0.4,0.4,0.9,0.8,1.4,1.2c0.4-0.4,0.8-0.9,1.1-1.4c1.6-2.9,3.2-5.9,4.9-8.8
                       c0.9-1.6,1.4-1.5,2.4,0c2.1,3.2,4.2,6.5,6.5,9.9c0.6-1.6,1.1-3.2,1.7-4.7c0.9-2.2,1.7-4.5,2.7-6.7c0.6-1.3,1-1.3,2-0.2
                       c2.2,2.5,4.4,5,6.6,7.6c0.2,0.2,0.3,0.3,0.8,0.7c0.3-2.5,0.6-4.8,0.9-7.1c0.1-0.9,0.2-1.9,0.4-2.8c0.2-1.1,0.7-1.3,1.6-0.5
                       c1.8,1.7,3.5,3.4,5.3,5.1c0.8,0.8,1.6,1.7,2.4,2.5c0.4,0.4,1,0.9,0.4,1.5c-0.7,0.7-1.1-0.1-1.6-0.5c-2-2-3.9-4-5.9-6
                       C148.5,140.6,148.2,140.4,147.8,140.1z"
              />
            </g>
            <g>
              <path
                className="st10 tongue"
                d="M175.4,109.7c-5.7,4.1-13.5,0.8-18.1-3.2c-8.6-7.3-17.6-13.5-29.7-13.2c-5.9,0.3-10,3.2-15.1,5.4
                               c-3.8,1.6-8.6,2.2-12.7,2.4c-7,0.3-16.2-3-21.6,2.7c-5.7,6.5-1.9,15.9,5.9,17.8c5.9,1.6,14.8,1.1,20.3-1.6
                               c7-3,11.6-10.5,18.9-11.6c6.8-1.1,12.7,1.4,18.6,4.1c5.1,2.7,10,6.5,15.4,8.4c5.7,2.2,11.9,0.8,17.5-0.5"
              ></path>
            </g>
            <g className="wave">
              <path
                className="st11 tongue"
                d="M175.7,115.3c0,0-0.3,0.1-1,0.2c-0.6,0.1-1.6,0.3-2.8,0.4c-0.6,0.1-1.3,0.2-2,0.2
                               c-0.7,0-1.5,0.1-2.4,0.1c-1.7-0.1-3.7-0.2-5.7-0.9c-1-0.3-2-0.7-3.1-1.1c-1-0.5-2.1-1-3.2-1.6c-2.1-1.2-4.2-2.4-6.5-3.6
                               c-2.3-1.2-4.6-2.4-7.1-3.3c-0.6-0.3-1.3-0.4-1.9-0.7c-0.6-0.2-1.3-0.4-1.9-0.6l-1.9-0.5l-2-0.4c-2.7-0.5-5.3-0.8-7.9-0.8
                               c-2.6,0.1-5.1,0.6-7.5,1.4c-2.4,0.8-4.5,2.1-6.8,3c-2.2,1-4.3,1.7-6.3,2.3c-1,0.3-2,0.5-2.9,0.7c-0.9,0.2-1.8,0.3-2.7,0.4
                               c-0.8,0.1-1.6,0.1-2.4,0.2c-0.4,0-0.7,0-1,0.1c-0.3,0-0.7,0-1,0c-1.2,0-2.2,0-2.8-0.1c-0.6,0-1-0.1-1-0.1s0.3,0,1-0.1
                               c0.6,0,1.6-0.1,2.8-0.2c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.7-0.1,1-0.2c0.7-0.1,1.5-0.2,2.3-0.4c0.8-0.2,1.7-0.4,2.6-0.6
                               c0.9-0.3,1.8-0.5,2.8-0.9c1.9-0.7,4-1.5,6.1-2.6c2.1-1,4.3-2.3,6.8-3.3c2.5-0.9,5.2-1.6,8-1.7c2.8-0.1,5.7,0.2,8.4,0.7l2,0.4
                               l2.1,0.5c0.7,0.2,1.3,0.4,2,0.6c0.7,0.2,1.3,0.4,2,0.7c2.6,1,5,2.3,7.2,3.6c2.3,1.3,4.4,2.6,6.4,3.9c1,0.6,2,1.1,3,1.6
                               c1,0.4,1.9,1,2.9,1.3c0.5,0.2,0.9,0.4,1.4,0.5c0.5,0.1,0.9,0.2,1.4,0.3c0.2,0.1,0.4,0.1,0.7,0.2c0.2,0,0.4,0.1,0.7,0.1
                               c0.4,0.1,0.9,0.1,1.3,0.2c0.8,0.1,1.6,0.1,2.3,0.1c0.7,0,1.4,0,2,0c1.2,0,2.2-0.1,2.8-0.1
                               C175.3,115.4,175.7,115.3,175.7,115.3z"
              />
            </g>
            <g>
              <path
                className="st12"
                d="M166.7,169.3c-5,2.6-10,4.3-15.2,5.3c-7.1,1.4-14.3,1.8-21.5,1.5c-8.2-0.3-16.2-1.3-24.1-3.7
                        c-9.3-2.9-17.6-7.5-24.5-14.5c-0.6-0.6-1.2-1.2-1.7-1.8c-0.8-1-1-2.1-0.4-3.2c0.5-1.1,1.6-1.2,2.7-1.1
                        c10.8,0.5,21.6,1.1,32.3,1.3c10.3,0.2,20.4-0.8,30.4-3.4c4.9-1.3,9.6-3.1,14-5.9c8.4-5.3,13.4-12.8,14.5-22.7
                        c1.2-11.4-0.2-22.4-4.7-33c-5.2-12.5-14.1-21.6-26.1-27.8c-8.3-4.2-16.9-7.4-26.1-8.7c-7.5-1-15-1.1-22.5-0.7
                        c-5.5,0.3-11,0.8-16.5,1.3c-1.2,0.1-2.3-0.1-2.9-1.2c-0.6-1.2-0.3-2.2,0.5-3.2c5.3-7.1,12.2-12.3,20-16.5
                        c10.2-5.5,21.1-8.7,32.5-10c10-1.1,20-1.1,29.9,0.3c16.8,2.4,32.1,8.2,44.8,19.7c10.1,9.2,17,20.4,21.5,33.2
                        c4.8,14,6.2,28.3,4.9,43c-1.4,16.1-6,31.4-11.7,46.4c-3.8,10.2-7.7,20.3-11.3,30.6c-2.9,8.3-4.7,16.9-5.4,25.6
                        c-0.9,10.8,0.4,21.3,4.8,31.3c0.7,1.6,0.4,2.9-0.8,3.7c-1.3,0.9-2.8,0.6-3.4-0.9c-0.3-0.8-0.8-1-1.6-0.9
                        c-7.1,0.5-14.2,1-21.3,1.6c-5.2,0.4-10.4,0.8-15.5,1.1c-0.8,0.1-1.5,0.1-1.7,1.2c-0.2,1-1.7,1.7-2.7,1.6
                        c-1.2-0.1-1.8-0.9-2-2.1c-1.7-9.7-3.1-19.3-3.2-29.2c-0.1-9.7,1.6-19,4.5-28.1c2.8-8.7,5.6-17.5,8.4-26.2
                        C166,172,166.3,170.8,166.7,169.3z"
              />
              <path
                className="st13"
                d="M223.5,74.4c-4.4-12.8-11.4-24-21.5-33.2c-6.2-5.6-12.9-9.8-20.2-13c1.1,1.2,2.1,2.5,3,3.8
                        c2.9,4.3,5.4,8.9,7.7,13.5c4.7,9.4,8.8,19,11.6,29.1c5.9,21.1,5.7,42.3,1.5,63.7c-4.4,22.1-11.6,43.5-16.2,65.6
                        c-2.4,11.6-4.3,23.2-4.1,35.1c0.1,5.1,0.3,10.1,0.6,15.2c4.4-0.3,8.8-0.6,13.2-1c0.8-0.1,1.3,0.1,1.6,0.9
                        c0.6,1.4,2.1,1.8,3.4,0.9c1.2-0.8,1.5-2.1,0.8-3.7c-4.3-10-5.6-20.5-4.8-31.3c0.7-8.8,2.5-17.3,5.4-25.6
                        c3.5-10.3,7.4-20.4,11.3-30.6c5.7-15,10.3-30.3,11.7-46.4C229.7,102.7,228.4,88.4,223.5,74.4z"
              />
              <g className="st14">
                <g>
                  <path
                    className="st15"
                    d="M143.7,48.2l0,0.8c0,0.3,0,0.5,0.1,0.8c0.1,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.5,0.3,0.7
                            c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.7,0.3l0.3,0.1l0.4,0
                            c0.2,0,0.5-0.1,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7
                            c0-0.2,0.1-0.5,0.1-0.7l0-0.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.4l0,0l0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.2
                            c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0.1,0.3,0,0.5,0c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.2-0.1l0.1-0.2
                            c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6l0-0.6l1.6,0l0,0.8c0,0.3,0,0.5,0.1,0.8
                            c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2
                            c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1l0.3-0.1l0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4
                            c0.2-0.1,0.3-0.4,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.5,0.2-0.7c0.1-0.2,0.1-0.5,0.1-0.7l0.1-0.8
                            c0.1-0.5,0.5-0.9,1.1-0.9c0.4,0,0.7,0.3,0.8,0.7l0,0l0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.3,0.4,0.4,0.6
                            c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.3,0.5,0.5c0.2,0.2,0.4,0.3,0.6,0.4c0.4,0.3,0.9,0.4,1.3,0.5c0.2,0.1,0.4,0.1,0.6,0.1
                            c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.3l0.1-0.3l0.1-0.3
                            c0-0.2,0.1-0.4,0.1-0.7l0-0.7v0c0-0.5,0.5-1,1-0.9c0.4,0,0.7,0.3,0.9,0.6c0.2,0.4,0.3,0.8,0.5,1.2c0.1,0.2,0.2,0.4,0.3,0.5
                            c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.4,0.1
                            c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.2-0.1l0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4
                            c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.1-0.8,0.2-1.3l0,0c0.1-0.5,0.5-0.8,1-0.8c0.3,0,0.6,0.3,0.7,0.6
                            l0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.5,0.4,0.7c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.6,0.5l0.3,0.2l0.3,0.1
                            c0.2,0.1,0.4,0.1,0.7,0.2c0.2,0.1,0.5,0,0.7,0c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.3-0.2
                            l0.2-0.3c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.2-0.5,0.2-0.8c0-0.3,0.1-0.5,0.1-0.8l0.1-0.8l0,0
                            c0-0.3,0.3-0.6,0.6-0.5c0.2,0,0.5,0.2,0.5,0.4l0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.5,0.4,0.8
                            c0.2,0.2,0.3,0.5,0.5,0.7c0.2,0.2,0.3,0.5,0.6,0.6c0.2,0.2,0.4,0.4,0.7,0.5c0.2,0.2,0.5,0.3,0.7,0.4c0.3,0.1,0.5,0.2,0.8,0.2
                            c0.3,0.1,0.5,0,0.8,0c0.2-0.1,0.5-0.1,0.7-0.3c0.1-0.1,0.2-0.1,0.3-0.2l0.3-0.3c0.2-0.2,0.3-0.5,0.5-0.7
                            c0.2-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.3,0.1-0.6,0.1-0.9l0-0.9l0.1,0.9c0,0.3,0,0.6,0,0.9
                            c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.1,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.5,0.8l-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
                            c-0.2,0.2-0.6,0.3-0.9,0.5c-0.3,0.1-0.7,0.2-1,0.1c-0.3,0-0.7,0-1-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.1-0.5-0.4-0.8-0.6
                            c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c-0.1-0.3-0.3-0.6-0.4-0.9l-0.3-0.9l1.1-0.2
                            l0,1c0,0.3-0.1,0.6-0.1,1c0,0.3-0.1,0.6-0.2,1c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.3-0.3,0.6-0.5,0.9L186,54
                            c-0.1,0.1-0.3,0.2-0.4,0.4c-0.3,0.3-0.6,0.4-0.9,0.6c-0.3,0.2-0.7,0.2-1,0.4c-0.4,0-0.7,0.1-1.1,0c-0.4-0.1-0.7-0.1-1.1-0.2
                            l-0.5-0.2l-0.5-0.3c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.4-0.6-0.5-0.9c-0.2-0.3-0.3-0.6-0.4-0.9
                            l-0.3-0.9l1.7-0.2c-0.1,0.5-0.1,1.1-0.2,1.6c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.3-0.3,0.5-0.5,0.7
                            c-0.2,0.2-0.4,0.5-0.7,0.6c-0.2,0.2-0.6,0.3-0.9,0.5c-0.3,0.1-0.7,0.2-1,0.2c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.2-0.9-0.4
                            c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.4-0.5-0.7c-0.2-0.2-0.3-0.5-0.4-0.7
                            c-0.3-0.5-0.4-1-0.6-1.5l1.9-0.3l0,1c0,0.3-0.1,0.7-0.1,1l-0.1,0.5l-0.2,0.5c-0.1,0.2-0.2,0.3-0.2,0.5l-0.4,0.5
                            c-0.2,0.3-0.6,0.5-0.9,0.8c-0.3,0.2-0.7,0.3-1.1,0.4c-0.4,0-0.7,0.1-1.1,0.1c-0.3,0-0.7,0-1-0.2c-0.7-0.2-1.3-0.4-1.8-0.8
                            c-0.3-0.2-0.6-0.3-0.8-0.6c-0.2-0.2-0.5-0.4-0.7-0.7c-0.2-0.2-0.5-0.5-0.6-0.7c-0.2-0.3-0.4-0.5-0.5-0.8
                            c-0.1-0.3-0.3-0.6-0.4-0.9l-0.3-1l1.9-0.2l-0.1,1c0,0.3-0.1,0.6-0.2,1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.3-0.3,0.6-0.5,0.9
                            c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.5,0.5-0.8,0.7l-0.5,0.3l-0.5,0.2c-0.3,0.1-0.7,0.2-1.1,0.2c-0.4,0-0.8,0-1.1-0.1
                            c-0.3-0.1-0.7-0.2-1-0.5L157,55c-0.1-0.1-0.3-0.3-0.4-0.4c-0.3-0.3-0.4-0.6-0.6-0.9c-0.2-0.3-0.3-0.6-0.4-0.9
                            c-0.1-0.3-0.2-0.6-0.2-1c0-0.3-0.1-0.6-0.1-1l0-1l0,0c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v0l0,0.8
                            c0,0.2-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.4,0.7l-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
                            c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.5-0.1-0.8-0.3
                            c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.6
                            c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.7l1-0.1l0,0.9c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.2,0.6-0.3,0.8
                            c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.3-0.5,0.4-0.7,0.6c-0.3,0.1-0.5,0.3-0.8,0.3c-0.3,0-0.6,0.1-0.9,0.1l-0.4,0l-0.4-0.1
                            c-0.3-0.1-0.5-0.2-0.8-0.4c-0.3-0.1-0.4-0.4-0.7-0.5c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.5-0.4-0.7
                            c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0-0.8L143.7,48.2z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M188.5,58.6l-0.1,0.7c0,0.3,0,0.5,0,0.7l0,0.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0,0.6,0
                            c0.2-0.1,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.2c0.1-0.1,0.2-0.3,0.2-0.5
                            c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0-0.6l0-0.6c0-0.5,0.4-0.9,0.9-0.9c0.3,0,0.6,0.2,0.8,0.5l0,0.1
                            c0.2,0.4,0.4,0.9,0.7,1.2c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.5,0.3
                            c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.2-0.1l0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.4
                            c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.4,0.1-0.9,0.2-1.4l0-0.1
                            c0.1-0.5,0.5-0.9,1-0.8c0.4,0.1,0.7,0.4,0.8,0.7l0.2,0.7c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.5,0.3,0.7
                            c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.1,0.3,0.4,0.5,0.4c0.2,0.1,0.4,0.3,0.6,0.3
                            c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.5,0.3-0.7
                            c0.1-0.2,0.1-0.5,0.2-0.8c0.1-0.5,0.1-1.1,0.2-1.6c0.1,0.5,0.2,1.1,0.2,1.7c0,0.3,0,0.6,0,0.9c0,0.3-0.1,0.6-0.2,0.9
                            c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.3-0.4,0.6-0.7,0.7c-0.3,0.2-0.6,0.3-1,0.4c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.2-0.9-0.4
                            c-0.3-0.1-0.5-0.4-0.8-0.5c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.3-0.5-0.5-0.8
                            c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.9l1.8-0.1l-0.1,0.9c0,0.3-0.1,0.6-0.1,0.9
                            c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.3-0.3,0.6-0.5,0.8c-0.2,0.3-0.4,0.5-0.6,0.8l-0.4,0.3l-0.5,0.3
                            c-0.3,0.2-0.7,0.3-1,0.4c-0.4,0-0.7,0.1-1.1,0c-0.4,0-0.7-0.1-1-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.4-0.7-0.6
                            c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.3-0.5-0.5-0.7c-0.3-0.5-0.5-1-0.7-1.6l1.7-0.4l0,0.8c0,0.3-0.1,0.6-0.1,0.8
                            c-0.1,0.3-0.1,0.6-0.3,0.8c-0.1,0.3-0.2,0.6-0.5,0.8l-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.2-0.6,0.3-0.9,0.4
                            c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.5-0.3-0.7-0.5
                            c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.2-0.2-0.5-0.3-0.7c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.3,0-0.4l0-0.4c0-0.3,0-0.5,0.1-0.7
                            L188.5,58.6z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M166.6,68.9l0.3,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.5
                            c0.2,0.2,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.7,0.2c0.2,0.1,0.5,0,0.7,0
                            c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.3l0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6
                            c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.4,0.1-0.7l0-0.7c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.4l0,0l0.1,0.6
                            c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3
                            c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3
                            c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6l0-0.6
                            c0-0.5,0.4-0.8,0.9-0.8c0.3,0,0.6,0.2,0.7,0.5l0,0l0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.6
                            c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.2
                            c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.4,0.2-0.6
                            c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0.1-0.9,0.1-1.4c0.1-0.5,0.6-0.9,1.1-0.8c0.5,0.1,0.8,0.4,0.9,0.9l0.1,0.7
                            c0,0.2,0.1,0.4,0.2,0.7c0,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4
                            c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.2-0.1,0.2-0.1
                            c0.1,0,0.1-0.1,0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.5,0-0.7l0-0.7
                            l0,0c0-0.5,0.4-1,0.9-1c0.4,0,0.8,0.3,1,0.7l0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.2
                            c0.2,0.1,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.2-0.5
                            c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0-0.7l0-0.7l0,0c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.7,0.3,0.8,0.6
                            c0.1,0.4,0.2,0.9,0.3,1.3c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.2,0.3,0.4,0.4
                            c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.4
                            c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.3-0.8,0.5-1.2l0,0
                            c0.1-0.2,0.4-0.4,0.6-0.3c0.1,0,0.2,0.1,0.2,0.2l0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5
                            c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.2
                            c0.2,0.1,0.4,0.1,0.5,0.1c0.2,0,0.3-0.1,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.3l0.2-0.2l0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5
                            c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0-0.8,0-1.2c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.4-0.2,0.6l-0.1,0.3
                            l-0.2,0.3c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.6-0.1
                            c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.3-0.5-0.5
                            c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1-0.2-0.3-0.3-0.4-0.5l-0.3-0.6l0.9,0c-0.1,0.5-0.2,0.9-0.5,1.4c-0.1,0.2-0.2,0.5-0.3,0.7
                            c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.8,0.4
                            c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.6,0-0.9-0.2c-0.3-0.1-0.5-0.3-0.8-0.4c-0.3-0.2-0.4-0.4-0.6-0.6
                            c-0.2-0.2-0.3-0.4-0.5-0.7c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.5-0.3-1-0.4-1.4l1.6-0.2l0.1,0.8
                            c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.3,0.6-0.6,0.8c-0.2,0.3-0.5,0.4-0.9,0.6
                            c-0.3,0.2-0.7,0.2-1,0.3c-0.3,0-0.7,0-1,0c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.1-0.6-0.2-0.8-0.4c-0.3-0.1-0.5-0.3-0.7-0.5
                            c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.3-0.5-0.5-0.7c-0.1-0.3-0.3-0.5-0.4-0.8l-0.3-0.8l1.9-0.4
                            l0.1,0.9c0,0.3,0,0.6,0,1c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.3-0.4,0.6-0.7,0.9c-0.1,0.1-0.3,0.3-0.4,0.4
                            l-0.5,0.3c-0.3,0.2-0.7,0.3-1.1,0.4c-0.4,0.1-0.7,0.1-1.1,0.1c-0.3-0.1-0.7-0.1-1-0.2c-0.3-0.1-0.7-0.2-1-0.4
                            c-0.3-0.2-0.6-0.4-0.8-0.6c-0.3-0.2-0.5-0.5-0.7-0.7c-0.2-0.3-0.4-0.5-0.5-0.9c-0.2-0.3-0.3-0.6-0.4-0.9
                            c-0.1-0.3-0.2-0.6-0.2-1l-0.1-1l1.9,0c-0.1,0.6,0,1.1-0.2,1.7c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.3,0.9
                            c-0.2,0.3-0.3,0.6-0.6,0.8c-0.3,0.3-0.5,0.5-0.9,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.7,0-1.1-0.1
                            c-0.3-0.1-0.7-0.2-0.9-0.3c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.6
                            c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.2-0.3-0.5-0.5-0.7c-0.1-0.2-0.3-0.5-0.4-0.7l-0.3-0.8l1.6-0.3l-0.1,0.8
                            c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.5-0.6,0.7
                            c-0.2,0.2-0.5,0.4-0.7,0.5c-0.3,0.2-0.6,0.2-0.8,0.3c-0.3,0-0.6,0.1-0.9,0c-0.3-0.1-0.6-0.1-0.8-0.2
                            c-0.3-0.1-0.5-0.2-0.8-0.4c-0.2-0.1-0.5-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.7
                            c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.7l1.1-0.1l0,0.8c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.3,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.7c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.3c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.8,0.1
                            c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.5-0.1-0.8-0.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.5
                            c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7L166.6,68.9z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M160.3,60.4l0.2,0.6c0,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.4,0.4l0.2,0.2
                            l0.2,0.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.5
                            l0.1-0.5c0.1-0.5,0.5-0.8,1-0.7c0.2,0,0.4,0.2,0.5,0.4l0,0.1l0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.5,0.5
                            c0.2,0.2,0.4,0.3,0.5,0.5c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0,0.6,0
                            c0.2-0.1,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2l0.3-0.1l0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.2,0.3-0.4,0.4-0.6
                            c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.3-0.7l0.2-0.7l0-0.1c0.2-0.5,0.7-0.8,1.2-0.6c0.3,0.1,0.5,0.3,0.6,0.6l0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.3,0.5,0.4
                            l0.3,0.2l0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.1,0.6-0.3
                            c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.7l0.2-0.7l0,0.8
                            c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.1,0.3-0.4,0.4-0.6,0.7c-0.2,0.2-0.5,0.3-0.7,0.5
                            c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.1-0.5-0.3-0.8-0.4
                            c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.3-0.5-0.4-0.8l-0.4-0.8l1.8-0.1
                            l-0.2,0.9c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.3-0.3,0.6-0.5,0.8c-0.2,0.3-0.4,0.5-0.7,0.7l-0.4,0.3
                            l-0.4,0.3c-0.3,0.2-0.6,0.3-1,0.4c-0.3,0.1-0.7,0.1-1,0.2c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.7-0.1-1-0.3
                            c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.2-0.5-0.4-0.8-0.6c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.6-0.7
                            c-0.2-0.3-0.4-0.5-0.5-0.7l-0.4-0.8l1.5-0.3l-0.2,0.7c0,0.2-0.2,0.4-0.3,0.7c-0.1,0.2-0.2,0.4-0.4,0.6
                            c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.3,0.3-0.6,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
                            c-0.2,0.1-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.2-0.3-0.3-0.4-0.5
                            c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6L160.3,60.4z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M174.1,79.7l0,0.7c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0,0.5,0c0.2-0.1,0.4-0.1,0.5-0.1
                            c0.2-0.1,0.3-0.1,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            c0-0.2,0.1-0.4,0.1-0.6l0-0.6c0-0.5,0.4-0.9,0.9-0.9c0.4,0,0.8,0.3,0.8,0.7l0,0.1l0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.1,0.4,0.2,0.5l0.1,0.3l0.2,0.2l0.2,0.2l0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.2
                            c0.2,0.1,0.3,0,0.5,0.1c0.2,0,0.3,0,0.5,0c0.1-0.1,0.3,0,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.3l0.2-0.2l0.2-0.2
                            c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.2-0.6l0.1-0.6l0-0.1c0.1-0.5,0.6-0.8,1-0.7
                            c0.4,0.1,0.6,0.4,0.7,0.7l0.1,0.5c0,0.2,0.1,0.3,0.2,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.4
                            c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0.2,0,0.4,0,0.5,0
                            c0.2,0,0.4-0.1,0.5-0.1c0.2-0.1,0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6
                            c0-0.2,0.1-0.4,0.1-0.6l0.1-0.7l0.1,0.7c0.1,0.2,0,0.5,0,0.7c0,0.2-0.1,0.5-0.1,0.7c0,0.2-0.2,0.5-0.3,0.7
                            c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0-0.5,0.1-0.8,0.1c-0.3,0-0.5,0-0.8,0
                            c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6
                            c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.3-0.5-0.3-0.7l-0.2-0.8l1.7-0.1l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.5,0.8l-0.3,0.4l-0.4,0.3c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.2-0.6,0.2-0.9,0.3
                            c-0.3,0.1-0.6,0.1-1,0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.3-0.7-0.5l-0.3-0.3l-0.3-0.4
                            l-0.3-0.4l-0.2-0.4c-0.1-0.3-0.3-0.5-0.3-0.8c-0.1-0.3-0.2-0.5-0.2-0.8L179,80l1.8-0.1l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.7,0.5
                            c-0.2,0.2-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.2c-0.3-0.1-0.5-0.2-0.8-0.4
                            c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.1-0.5-0.2-0.8c-0.1-0.2,0-0.5,0-0.8c0-0.3,0-0.5,0.1-0.7
                            L174.1,79.7z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M198.6,78.8c0.1,0.5,0.2,1,0.3,1.5c0.1,0.5,0.3,1,0.4,1.4c0.1,0.2,0.2,0.4,0.4,0.5
                            c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.6,0.2l0.3,0.1l0.1,0l0,0l0,0l0,0c-0.1,0,0,0-0.1,0l0,0l0,0l0.2,0
                            c0.2,0,0.5,0.1,0.7,0c0.2-0.1,0.3-0.1,0.5-0.1c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.4
                            c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0.1-0.4,0.1-0.5c0-0.2,0-0.4,0-0.5c0-0.5,0.4-1,1-0.9c0.4,0,0.8,0.3,0.9,0.7l0,0
                            c0.1,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.4-0.1
                            c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.5
                            c0-0.2,0-0.4,0-0.6l0-0.1c0-0.5,0.4-0.8,0.9-0.7c0.3,0,0.6,0.2,0.7,0.5c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.1,0.1,0.3,0.2,0.4
                            c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0,0.4,0
                            c0.1,0,0.2,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5
                            c0.1-0.2,0.1-0.3,0.1-0.5c0.1-0.3,0.1-0.7,0.1-1.1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6
                            c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.2-0.6,0.3c-0.2,0-0.5,0.1-0.7,0.1
                            c-0.2,0-0.5-0.1-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.1-0.3-0.3-0.4-0.5
                            c-0.2-0.2-0.3-0.3-0.4-0.5c-0.3-0.3-0.4-0.7-0.6-1.1l1.6-0.4c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5-0.1,0.8c0,0.3-0.1,0.6-0.2,0.8
                            c-0.1,0.3-0.3,0.5-0.4,0.8c-0.2,0.3-0.4,0.5-0.6,0.7c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2
                            c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.8-0.3l-0.4-0.2l-0.3-0.3c-0.2-0.2-0.4-0.4-0.6-0.6
                            c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8l1.9-0.2
                            c0,0.3,0,0.6,0,1c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.3-0.4,0.5-0.7,0.7c-0.2,0.2-0.5,0.3-0.8,0.5
                            c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.3,0-0.5-0.1-0.8-0.2l-0.2,0l-0.1,0l0,0l0,0l0,0l-0.1,0l-0.4-0.2
                            c-0.3-0.1-0.5-0.3-0.7-0.5c-0.3-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.1-0.8l0-0.8
                            C198.5,79.8,198.6,79.3,198.6,78.8z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M177.4,90.1l0,0.7c0,0.2,0,0.4,0,0.7c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.5l0.2,0.2l0.2,0.2c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0,0.6,0
                            c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.2,0.1-0.4,0.2-0.6
                            c0-0.2,0.1-0.4,0.1-0.6l0-0.6c0-0.3,0.3-0.5,0.5-0.5c0.2,0,0.4,0.2,0.5,0.4l0,0.1l0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6l0.3,0.5
                            L184,93l0.4,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0,0.5,0l-0.1,0c0.2,0,0.3-0.1,0.5-0.1
                            c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.2,0.4-0.5,0.5-0.9c0.3-0.7,0.3-1.6,0.4-2.5c0-0.4,0.4-0.8,0.9-0.7c0.4,0,0.6,0.3,0.7,0.6
                            l0,0.1l0.1,0.7c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.4,0.4
                            c0.1,0.1,0.3,0.3,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0,0.5,0.1c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.2
                            l0.2-0.1l0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6l0.1-0.7
                            l1.9,0.1l0,0.7c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.5
                            c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5l0.1-0.3l0.1-0.3c0-0.2,0.1-0.4,0.1-0.6l0-0.6
                            l0-0.1c0-0.5,0.5-1,1-0.9c0.5,0,0.9,0.4,0.9,0.9c0.1,0.5,0.1,0.9,0.2,1.4l0.2,0.6l0.3,0.5c0.1,0.1,0.2,0.3,0.3,0.4
                            c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.3,0.2,0.4,0.2l0.3,0c0,0,0.1,0,0.1,0l0.2,0c0.3,0,0.4,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.5c0.3-0.8,0.3-1.8,0.5-2.8c0.1-0.5,0.5-0.8,1-0.8c0.4,0,0.7,0.4,0.8,0.7l0,0
                            l0.1,0.7c0,0.2,0.1,0.4,0.2,0.7l0.1,0.3l0.2,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.4,0.5l0.2,0.2l0.3,0.2
                            c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4
                            l0.2-0.2l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6l0.1-0.3l0.1-0.3c0-0.2,0.1-0.5,0.1-0.7v-0.7v0c0-0.3,0.2-0.5,0.5-0.5
                            c0.3,0,0.5,0.2,0.5,0.4c0.1,0.4,0.1,0.9,0.3,1.4c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5
                            c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.3,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1l0.3-0.1
                            c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.5c0.2-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.1-0.5,0.2-0.7
                            c0.1-0.5,0.1-1,0.2-1.5c0,0.5,0.1,1,0,1.5c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.5-0.3,0.7
                            c-0.1,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.2c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0-0.6,0-0.8-0.1
                            c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.2-0.3-0.5-0.4-0.7
                            c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.5-0.3-1-0.4-1.5l1.1-0.1l0.1,0.9c0,0.3,0,0.6,0,0.9l0,0.4l-0.1,0.4
                            c-0.1,0.3-0.2,0.6-0.4,0.9l-0.2,0.4l-0.3,0.4c-0.2,0.3-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-1,0.1
                            c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.2-0.9-0.3l-0.4-0.2l-0.4-0.3c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.2-0.4-0.5-0.5-0.8
                            l-0.2-0.4l-0.2-0.4c-0.1-0.3-0.2-0.6-0.3-0.9l-0.1-0.9l1.7,0l-0.1,1.5c-0.1,0.5-0.1,1.1-0.3,1.7c-0.1,0.3-0.2,0.6-0.4,0.9
                            c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.6,0.4-1,0.5c-0.3,0.1-0.8,0.2-1,0.2l-0.2,0c-0.2,0-0.2,0-0.3,0l-0.5-0.1
                            c-0.3,0-0.7-0.2-1-0.3c-0.3-0.1-0.6-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8c-0.1-0.3-0.3-0.6-0.4-0.8l-0.3-0.8
                            c-0.1-0.5-0.2-1.1-0.3-1.6l2-0.1l0,0.9c0,0.3-0.1,0.6-0.1,0.9l-0.1,0.4l-0.2,0.4c-0.1,0.3-0.2,0.6-0.4,0.8
                            c-0.2,0.3-0.3,0.5-0.6,0.8c-0.2,0.2-0.5,0.5-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.7-0.1-1-0.2
                            c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.7-0.7c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1-0.3-0.2-0.6-0.3-0.9
                            c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.1-0.6-0.1-0.9l0-0.8v0c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1l0,0.1l-0.1,0.9
                            c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.3-0.4,0.5-0.6,0.7
                            c-0.1,0.1-0.2,0.2-0.4,0.3L194,95c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.1c-0.3-0.1-0.7-0.1-1-0.2
                            c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.3-0.5-0.5-0.8
                            c-0.2-0.3-0.3-0.5-0.3-0.8c-0.1-0.3-0.2-0.5-0.2-0.8l-0.1-0.8l1.6,0c-0.1,0.9-0.2,1.8-0.6,2.8c-0.2,0.5-0.5,1-0.9,1.4
                            c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.8,0.2l0,0h0c-0.3,0-0.6-0.1-0.9-0.1c-0.3-0.1-0.5-0.2-0.8-0.3
                            c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7
                            c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3,0-0.5-0.1-0.8l1,0l-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.1,0.5-0.3,0.7
                            c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
                            c-0.2,0.1-0.5,0-0.7,0c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.4-0.2-0.6-0.4l-0.3-0.2l-0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.6
                            c-0.1-0.2-0.1-0.4-0.2-0.7c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.4,0-0.7L177.4,90.1z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M180.6,100.6l0,0.7c0,0.2,0,0.5,0.1,0.7c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5l0.2,0.2l0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.2l0.3-0.1l0.3-0.2
                            c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.6
                            l0.1-0.6c0.1-0.4,0.5-0.7,0.9-0.6c0.3,0,0.5,0.2,0.6,0.4l0,0.1l0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.4,0.5
                            c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.7,0.3,1,0.3c0.2,0,0.3,0,0.4,0
                            c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.5
                            c0-0.2,0.1-0.4,0.1-0.6l0-0.6l2,0l0,0.7l0,0.4l0.1,0.3c0.1,0.2,0,0.5,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.1,0.2,0.4,0.3,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.3,0,0.5,0
                            c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.1,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.4l0.2-0.2l0.2-0.3l0.2-0.3l0.1-0.3
                            c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0.1-0.4,0.1-0.7l0-0.7l0-0.1c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.7,0.3,0.8,0.6
                            c0.3,1.1,0.5,2.1,0.8,3c0.2,0.4,0.4,0.8,0.7,1c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1l0.3,0l0.4,0
                            c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.5-0.9,0.6-2,0.7-3c0.1,1.1,0.2,2.2-0.2,3.3
                            c-0.2,0.5-0.6,1.1-1.1,1.4c-0.3,0.2-0.5,0.3-0.9,0.4l-0.4,0.1c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.9-0.1
                            c-0.3-0.1-0.6-0.2-0.9-0.4c-0.6-0.4-1-0.9-1.3-1.4c-0.6-1.1-0.8-2.2-1.1-3.1l1.6-0.3l0.1,0.9c0,0.3,0,0.6-0.1,0.9
                            c0,0.3-0.1,0.6-0.2,0.9l-0.1,0.5c-0.1,0.1-0.1,0.3-0.2,0.4l-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.4,0.5-0.7,0.7
                            c-0.2,0.2-0.6,0.4-0.9,0.5c-0.3,0.2-0.7,0.2-1,0.3c-0.4,0-0.7,0.1-1.1,0l-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.2
                            c-0.4-0.2-0.6-0.4-0.9-0.7c-0.3-0.2-0.4-0.6-0.6-0.9c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.1-0.6-0.2-0.9l-0.1-0.5l0-0.5
                            l0-0.9v0c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1v0l0,0.8c0,0.3,0,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.4,0.8
                            c-0.2,0.3-0.3,0.5-0.6,0.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.9-0.1
                            c-0.6-0.1-1.1-0.3-1.5-0.6c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6
                            c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.3-0.4-0.3-0.7l-0.3-0.7l1.5-0.1l-0.2,0.8c0,0.3-0.2,0.5-0.3,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.3,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.7,0.5l-0.4,0.2l-0.4,0.1
                            c-0.3,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.6-0.1-0.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.4l-0.3-0.3l-0.3-0.3c-0.2-0.2-0.3-0.5-0.4-0.7
                            c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0-0.7L180.6,100.6z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M211.6,99.3c0.1,0.5,0.1,0.9,0.2,1.4c0.1,0.2,0.1,0.4,0.2,0.6l0.2,0.6l0.3,0.6c0.1,0.1,0.3,0.3,0.4,0.4
                            c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4
                            l0.2-0.5l0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0-0.8,0.1-1.2c0-0.5,0.5-1,1.1-0.9c0.5,0,0.8,0.4,0.9,0.9l0,0.1v0
                            c-0.1,0.8,0,1.6,0.2,2.4c0.1,0.4,0.3,0.7,0.5,0.9c0.2,0.2,0.5,0.3,0.8,0.3l0.3,0c0.1,0,0.2-0.1,0.3-0.1
                            c0.1,0,0.3-0.1,0.4-0.2c0.3-0.2,0.5-0.6,0.6-1c0.3-0.8,0.3-1.8,0.4-2.7c0.1,0.9,0.2,1.8,0.1,2.8c0,0.2-0.1,0.5-0.2,0.7
                            c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.1-0.3,0.1-0.4,0.1
                            c-0.5,0.1-1.2,0-1.7-0.3c-0.5-0.4-0.8-0.8-1.1-1.3c-0.5-1-0.7-1.9-0.8-3v0l2,0.1c-0.1,0.5-0.2,1-0.4,1.5
                            c-0.1,0.2-0.1,0.5-0.3,0.7l-0.3,0.7l-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1
                            c-0.3-0.1-0.6-0.1-0.8-0.3c-0.3-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.2-0.7
                            c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0-0.7C211.5,100.2,211.6,99.8,211.6,99.3z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M180.6,111.4l0.1,0.7c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.4,0.6
                            c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.3,0.3,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.1
                            c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
                            c0-0.2,0.1-0.4,0-0.6l-0.1-0.6l1.1-0.1l0,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5
                            c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
                            c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            c0-0.2,0.1-0.4,0.1-0.6l0-0.6h1.7l0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5l0.1,0.3l0.2,0.2
                            c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1
                            c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            c0-0.2,0.2-0.4,0.2-0.6l0.1-0.6c0.1-0.5,0.6-0.9,1.2-0.8c0.4,0.1,0.7,0.3,0.8,0.7l0,0l0.2,0.5c0,0.2,0.2,0.3,0.2,0.5
                            c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.5,0.2
                            c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
                            c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0.1-0.3,0.1-0.5l0-0.6l0,0c0-0.5,0.5-0.9,1-0.9
                            c0.5,0,0.9,0.4,0.9,0.8l0.1,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.5,0.3,0.7
                            c0.2,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.3,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3l0.3,0.1l0.3,0c0.2,0,0.5,0,0.7-0.1
                            c0.2,0,0.4-0.2,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.5,0.2-0.7
                            c0-0.3,0.1-0.5,0.1-0.8l0-0.8l1.3,0l0,0.7c0,0.3,0,0.5,0,0.7c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.3,0.6l0.2,0.3
                            c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2l0.3,0.2c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0.1,0.5,0,0.7,0.1
                            c0.2,0,0.5-0.1,0.7-0.1c0.2-0.1,0.5-0.1,0.7-0.3c0.2-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.7
                            c0.1-0.2,0.3-0.5,0.3-0.7c0-0.3,0.2-0.5,0.2-0.8l0-0.8l0.1,0.8c0,0.3,0,0.6-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.3-0.4,0.4-0.6,0.7c-0.2,0.2-0.5,0.4-0.7,0.5c-0.2,0.2-0.5,0.3-0.8,0.4
                            c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.9,0c-0.3-0.1-0.6-0.1-0.9-0.2l-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
                            c-0.1-0.1-0.3-0.2-0.4-0.3l-0.3-0.4c-0.2-0.2-0.3-0.5-0.5-0.8c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.1-0.6-0.1-0.9l0-0.9
                            c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0,0l0.1,1c0,0.3,0,0.7-0.1,1c0,0.3-0.2,0.7-0.3,1c-0.1,0.3-0.3,0.6-0.5,0.9
                            c-0.2,0.3-0.5,0.6-0.7,0.8c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.1-0.7,0.3-1,0.4c-0.4,0.1-0.7,0.2-1.1,0.1c-0.2,0-0.4,0-0.6,0
                            l-0.5-0.1c-0.4-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.6-0.4-0.9-0.6c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.4-0.6-0.5-0.9
                            c-0.2-0.3-0.3-0.6-0.4-1c-0.1-0.3-0.2-0.7-0.2-1l-0.1-1l1.9-0.1l0,0.8c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.3-0.3,0.5-0.6,0.7c-0.2,0.2-0.5,0.4-0.7,0.5c-0.3,0.2-0.6,0.2-0.9,0.3
                            c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.1-0.8-0.3c-0.3-0.1-0.5-0.2-0.7-0.4
                            c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.3-0.5-0.3-0.7l-0.2-0.8l2-0.1
                            l-0.1,0.8c0,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.5-0.7,0.6
                            c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.2-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1
                            c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.2-0.6-0.3-0.8-0.5c-0.2-0.2-0.5-0.4-0.6-0.7l-0.3-0.4c-0.1-0.1-0.1-0.3-0.2-0.4
                            c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.8l-0.1-0.8l0,0c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.9,0.3,0.9,0.8
                            c0,0,0,0.1,0,0.1l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.5,0.7
                            c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.9,0c-0.3-0.1-0.6-0.1-0.9-0.2
                            c-0.3-0.1-0.5-0.2-0.8-0.4c-0.3-0.1-0.5-0.3-0.7-0.6c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.2-0.3-0.5-0.4-0.8
                            c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.3-0.1-0.5-0.1-0.8l0-0.8v0c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6l0,0.8
                            c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.4,0.7c-0.1,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.7,0.4
                            c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.6-0.5
                            c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.2-0.1-0.5-0.1-0.7L180.6,111.4z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M180.1,122.2l0,0.7c0,0.3,0.1,0.5,0.1,0.7l0.1,0.3l0.1,0.3c0.1,0.1,0.1,0.2,0.1,0.3l0.2,0.2
                            c0.1,0.2,0.3,0.2,0.4,0.3c0.2,0.1,0.4,0.1,0.5,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2
                            c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0-0.6l0-0.1c0-0.6,0.5-1,1.1-0.9c0.5,0,0.9,0.4,0.9,0.9l0.1,0.6c0,0.2,0.1,0.4,0.1,0.6
                            c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.4,0.3l0.2,0.1
                            c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5-0.1l0.3-0.1l0.2-0.1c0.2-0.1,0.3-0.2,0.5-0.3
                            c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.3-0.9,0.5-1.4l0.1,0.7
                            c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5-0.1,0.8c-0.1,0.2-0.1,0.5-0.2,0.8c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.5-0.6,0.7
                            c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.2c-0.3,0.2-0.6,0.2-0.9,0.3c-0.3,0-0.7,0-1-0.1c-0.2,0-0.3-0.1-0.5-0.1
                            c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3-0.1-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.3-0.5-0.4-0.8
                            c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2-0.5-0.2-1.1-0.3-1.6l2-0.1l-0.1,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.4,0.8
                            c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.5,0.3-0.7,0.5c-0.5,0.3-1.1,0.5-1.7,0.5
                            c-0.3,0.1-0.6,0-0.9,0c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.5-0.4-0.6-0.7l-0.2-0.4
                            c-0.1-0.1-0.1-0.3-0.1-0.4l-0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5,0.1-0.8L180.1,122.2z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M197.9,121.1c0.1,1.2,0,2.4,0.3,3.5c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.2,0.3,0.4,0.5,0.5
                            c0.2,0.1,0.4,0.2,0.6,0.3l0.2,0c0,0,0.1,0,0,0l0.1,0c0.1,0,0.3,0,0.4,0l0.3,0l0.3-0.1l0.3-0.2l0.3-0.2
                            c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.5-0.9,0.9-2,1.1-3c0.1-0.5,0.5-0.8,1-0.7c0.4,0.1,0.7,0.4,0.7,0.8l0,0.1
                            l0,0.7c0,0.2,0,0.5,0.1,0.7c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.1,0.2,0.2
                            l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1l0.2-0.1l0.2-0.2c0.2-0.1,0.3-0.2,0.4-0.4
                            c0.2-0.1,0.2-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6l0.1-0.7l0,0
                            c0-0.5,0.5-0.9,1.1-0.9c0.5,0,0.8,0.4,0.9,0.8l0.1,0.7c0,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1
                            c0.2,0,0.3,0,0.5,0c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.2-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.3-0.6
                            c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.4,0.1-0.9,0.2-1.4l1.3,0.1l0,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.3,0.1,0.4
                            c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2
                            c0.1,0,0.3,0.1,0.4,0c0.1,0,0.3,0,0.4-0.1l0.2-0.1l0.2-0.1c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4
                            c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.3,0.2-0.7,0.2-1c0,0.3,0.1,0.7,0,1.1c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.1,0.4-0.2,0.5
                            c-0.1,0.2-0.2,0.3-0.4,0.4l-0.2,0.2l-0.3,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
                            c-0.2-0.1-0.4-0.1-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.3-0.3-0.5
                            c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6l0-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6l0,0.1l0,0.8
                            c0,0.3,0,0.6-0.1,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.1,0.6-0.3,0.8c-0.2,0.3-0.2,0.6-0.5,0.8c-0.2,0.3-0.4,0.5-0.7,0.7
                            c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.1-0.7,0.1-1.1,0.1c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5
                            c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.5-0.3-0.8
                            c-0.1-0.3-0.2-0.6-0.2-0.8l-0.2-0.8l1.9-0.1l-0.1,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.1,0.6-0.3,0.9
                            c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.5,0.5-0.8,0.6c-0.1,0.1-0.3,0.2-0.4,0.3l-0.5,0.2
                            c-0.3,0.1-0.7,0.2-1,0.2c-0.4,0-0.7,0-1.1-0.1c-0.3-0.1-0.7-0.2-1-0.4c-0.1-0.1-0.3-0.2-0.4-0.3l-0.4-0.4
                            c-0.3-0.3-0.4-0.6-0.6-0.8c-0.2-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.3-0.1-0.6,0-0.9l0.1-0.9l1.7,0.3
                            c-0.3,1.2-0.8,2.4-1.6,3.4c-0.2,0.3-0.4,0.5-0.6,0.8c-0.2,0.2-0.5,0.5-0.8,0.7l-0.5,0.3l-0.5,0.2c-0.2,0-0.4,0.1-0.5,0.1
                            c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3-0.1-0.4-0.1l-0.1,0c-0.2,0-0.1,0-0.2-0.1l-0.2-0.1c-0.3-0.1-0.6-0.3-0.9-0.6
                            c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.3-0.2-0.6-0.2-0.9C197.5,123.4,197.8,122.3,197.9,121.1z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M177.2,131.9l0,0.7c0,0.2,0,0.5,0.1,0.7c0,0.2,0.1,0.4,0.1,0.7c0.1,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5l0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.2
                            c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.6
                            c0-0.2,0.1-0.4,0.1-0.6l0-0.6c0-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.2,0.5,0.4l0,0l0.1,0.7c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3
                            c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.3-0.1,0.5-0.3c0.1-0.1,0.3-0.2,0.4-0.4
                            c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6l0-0.7l1.7,0.2l0,0.5c0,0.2,0,0.3,0.1,0.5
                            c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3,0.1,0.5,0.1
                            c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4
                            c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.5l0-0.6c0-0.5,0.5-1,1-0.9c0.5,0,0.9,0.4,0.9,0.8l0,0
                            l0.1,0.7c0,0.3,0.1,0.5,0.2,0.7l0.1,0.3l0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.3,0.3,0.6,0.4
                            l0.3,0.2l0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0.1,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.3
                            c0.1-0.1,0.3-0.3,0.4-0.5l0.2-0.3c0-0.1,0.1-0.2,0.1-0.3l0.1-0.3c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.5,0-0.7l-0.1-0.8l1.8-0.2
                            l0.1,0.8c0,0.3,0.1,0.5,0.1,0.8c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.3,0.7c0.1,0.2,0.2,0.4,0.4,0.6
                            c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.6-0.3
                            c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.2,0.2-0.5,0.3-0.7
                            l0.2-0.8l0,0c0.1-0.3,0.4-0.5,0.7-0.4c0.2,0.1,0.4,0.3,0.4,0.5l0,0.7c0,0.2,0,0.4,0.1,0.7c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2
                            c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.4,0.4-0.6
                            c0.1-0.2,0.2-0.4,0.2-0.7c0-0.2,0.1-0.5,0.1-0.7l0-0.7l0.1,0.7c0,0.2,0,0.5,0,0.7c0,0.3-0.1,0.5-0.2,0.7
                            c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.2-0.8,0.2
                            c-0.3,0-0.5,0.1-0.8,0c-0.3,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.4-0.4-0.6-0.5c-0.2-0.2-0.3-0.4-0.5-0.6
                            c-0.2-0.2-0.2-0.5-0.4-0.7c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.5-0.2-0.8l-0.1-0.8l1.1,0.1l-0.2,0.9
                            c0,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.3-0.4,0.5-0.6,0.7
                            c-0.2,0.2-0.5,0.5-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.5c-0.3,0.2-0.7,0.1-1.1,0.2c-0.4,0-0.7,0-1.1-0.1c-0.4-0.1-0.7-0.2-1-0.4
                            c-0.3-0.2-0.6-0.4-0.8-0.7c-0.3-0.2-0.4-0.6-0.6-0.8c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.6-0.3-0.9
                            c-0.1-0.3-0.2-0.6-0.2-0.9l-0.1-0.9c-0.1-0.5,0.3-0.9,0.8-1c0.5-0.1,1,0.3,1,0.8l0,0l0.1,1c0.1,0.3,0,0.7,0,1
                            c0,0.2,0,0.3-0.1,0.5l-0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.5l-0.3,0.5c-0.2,0.3-0.5,0.6-0.7,0.9c-0.3,0.3-0.6,0.4-0.9,0.7
                            c-0.3,0.1-0.7,0.3-1.1,0.3c-0.4,0-0.7,0.1-1.1,0.1c-0.4,0-0.7-0.1-1.1-0.2c-0.2-0.1-0.3-0.1-0.5-0.2l-0.5-0.3
                            c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.4-0.6-0.6-0.9l-0.2-0.5l-0.2-0.5c-0.1-0.3-0.2-0.6-0.3-1
                            l-0.1-1l2-0.1l0,0.8c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.2-0.3,0.5-0.6,0.7
                            c-0.2,0.2-0.5,0.4-0.7,0.5c-0.3,0.1-0.5,0.3-0.8,0.3c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.9,0c-0.3,0-0.6-0.1-0.9-0.2
                            c-0.3-0.1-0.5-0.3-0.8-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.4-0.5-0.5-0.8c-0.1-0.3-0.3-0.6-0.3-0.9
                            c0-0.3-0.1-0.6-0.1-0.9l0.1-0.8c0.1-0.5,0.5-0.8,1-0.8c0.5,0.1,0.8,0.5,0.8,0.9l-0.1,0.9c0,0.3-0.1,0.6-0.2,0.9
                            c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.3-0.3,0.5-0.5,0.8c-0.2,0.3-0.5,0.4-0.7,0.6c-0.3,0.2-0.6,0.3-0.9,0.4
                            c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.1-0.9-0.3c-0.3-0.1-0.5-0.3-0.8-0.4
                            c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.3-0.5-0.3-0.8
                            c-0.1-0.3-0.2-0.5-0.2-0.8l-0.1-0.8l1.1,0l-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.1,0.5-0.3,0.7
                            c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.1-0.4,0.3-0.7,0.3
                            c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.5-0.2-0.7-0.4c-0.1-0.1-0.2-0.2-0.3-0.2l-0.2-0.3
                            c-0.2-0.2-0.2-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0-0.7L177.2,131.9z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M134.2,41l0.1,0.7c0,0.2,0.1,0.5,0.1,0.7c0,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5
                            c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.2
                            c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.5l0.1-0.3l0-0.3
                            c0-0.2,0.1-0.4,0.1-0.6l0-0.6c0-0.4,0.3-0.7,0.7-0.7c0.3,0,0.5,0.2,0.6,0.4l0,0.1c0.1,0.2,0.1,0.3,0.2,0.5l0.3,0.5l0.4,0.4
                            c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.5,0.2
                            c0.2,0.1,0.4,0.1,0.5,0.1c0.2,0,0.4,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.3
                            c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5l0-0.5l1.9,0c0,0.2,0,0.4,0,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.3,0.2,0.4
                            c0,0.2,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0
                            c0.7,0,1.4-0.3,1.9-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.4l0.2-0.5l0,0
                            c0.2-0.5,0.7-0.8,1.2-0.6c0.4,0.1,0.7,0.5,0.7,0.9l0,0.7c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.2,0.5
                            l0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.4-0.1,0.5-0.2
                            l0.3-0.1l0.2-0.2c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6
                            c0-0.2,0.1-0.4,0.2-0.6l0.1-0.7l0-0.1c0-0.4,0.4-0.7,0.8-0.6c0.3,0,0.6,0.3,0.6,0.6l0.1,0.7c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.2,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.6,0.2
                            c0.2,0.1,0.5,0,0.7,0.1c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.6-0.4
                            c0.2-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.7l0.2-0.7l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.3-0.3,0.4-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.7,0.4
                            c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.8,0.2c-0.3,0-0.6,0-0.9,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4
                            c-0.3-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.3-0.5-0.3-0.8l-0.2-0.8l1.4-0.1
                            l0,0.8c0,0.3-0.1,0.6-0.1,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.3-0.3,0.5-0.5,0.7
                            c-0.2,0.2-0.4,0.4-0.7,0.6l-0.4,0.3l-0.4,0.2c-0.3,0.2-0.6,0.2-0.9,0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.3-0.1-0.7-0.1-1-0.2
                            c-0.3-0.1-0.6-0.3-0.9-0.5c-0.1-0.1-0.3-0.2-0.4-0.3l-0.3-0.4c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.3-0.2-0.6-0.3-0.9
                            c-0.1-0.3-0.1-0.6-0.1-0.9l0-0.9l1.9,0.2l-0.3,0.8c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.2-0.3,0.5-0.5,0.7
                            c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.7,0.5c-0.2,0.2-0.5,0.2-0.8,0.4c-0.3,0.1-0.5,0.2-0.8,0.2
                            c-0.3,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.5-0.3-0.7-0.6
                            c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.3-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.8l0-0.8l0,0c0-0.5,0.4-1,0.9-1
                            c0.5,0,1,0.4,1,0.9l0,0l0,0.8c0,0.3-0.1,0.6-0.2,0.9c0,0.3-0.3,0.6-0.4,0.9c-0.2,0.3-0.5,0.5-0.7,0.7
                            c-0.3,0.1-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.2-0.9,0.1c-0.3,0-0.6,0-0.8-0.1c-0.3,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.3
                            c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6
                            c-0.2-0.2-0.3-0.4-0.5-0.6l-0.4-0.7c-0.1-0.3-0.1-0.5-0.2-0.8l1.3-0.2l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8l-0.1,0.4l-0.2,0.4
                            c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.1-0.5,0.3-0.7,0.3
                            c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.5,0-0.8,0c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.3-0.5-0.6
                            c-0.1-0.2-0.3-0.4-0.3-0.7c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.2-0.1-0.5,0-0.7L134.2,41z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M174.7,40.1c0.1,0.6,0.3,1.2,0.5,1.7c0.1,0.3,0.3,0.5,0.4,0.7c0.2,0.2,0.3,0.4,0.5,0.6
                            c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.4,0.3,0.6,0.3l0.3,0.1l0.3,0l0.3,0c0.1,0,0.2-0.1,0.3-0.1l-0.1,0
                            c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.3-0.7,0.4-1.2c0.2-0.9,0.3-1.8,0.5-2.9c0.1-0.5,0.6-0.9,1.1-0.8
                            c0.4,0.1,0.7,0.3,0.8,0.7l0,0.1c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.2,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5
                            c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.3
                            c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.4-0.2,0.4-0.4
                            c0.1-0.2,0.3-0.3,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7l0.1-0.7l0.2,0.8c0.1,0.3,0,0.5,0,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.3,0.5-0.5,0.8c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.3-0.9,0.3c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1
                            c-0.3-0.1-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.5-0.3-0.7-0.5
                            c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.3-0.5-0.4-0.8l-0.2-0.9l2,0c-0.1,1-0.3,2.1-0.6,3.1
                            c-0.2,0.5-0.4,1.1-0.8,1.6c-0.2,0.3-0.4,0.5-0.7,0.8c-0.3,0.2-0.6,0.4-0.9,0.5l-0.1,0l0,0l-0.6,0.1c-0.2,0-0.4,0-0.6,0
                            c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.2c-0.6-0.4-1.1-0.9-1.4-1.5c-0.2-0.3-0.3-0.6-0.4-0.9
                            c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3-0.1-0.6-0.1-0.9C174.6,40.7,174.7,40.4,174.7,40.1z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M89.9,41.5l0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.4
                            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2
                            c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.3-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.5
                            c0.1-0.2,0.1-0.3,0.2-0.5l0.1-0.5c0.1-0.3,0.4-0.5,0.7-0.5c0.2,0,0.3,0.1,0.4,0.3l0,0l0.4,0.6l0.5,0.5
                            c0.3,0.3,0.7,0.6,1.1,0.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1
                            c0.2,0.1,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4
                            c0.1-0.2,0.1-0.3,0.2-0.5c0-0.2,0.1-0.4,0.1-0.6l0-0.6c0-0.5,0.4-1,0.9-1c0.4,0,0.7,0.2,0.9,0.5l0,0l0.3,0.5
                            c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.3
                            c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.3-0.1,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.5l0.1-0.6l0,0
                            c0.1-0.5,0.6-0.9,1.1-0.9c0.4,0.1,0.7,0.4,0.8,0.7l0.2,0.7c0,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.6,0.2
                            c0.2,0.1,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2
                            c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.7l0.1-0.7l0,0c0-0.4,0.4-0.7,0.8-0.6
                            c0.3,0,0.5,0.2,0.6,0.5l0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.6
                            c0.2,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.7,0.3l0.4,0.1l0.4,0.1
                            c0.2,0.1,0.5,0,0.7,0c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.5l0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3l0.2-0.4l0.1-0.4
                            c0.1-0.3,0.1-0.5,0.1-0.8l0-0.8l0.1,0.8c0.1,0.3,0,0.6,0,0.8l0,0.4l-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4l-0.2,0.4
                            c-0.1,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1l-0.5,0l-0.4-0.1c-0.3,0-0.6-0.2-0.9-0.3
                            c-0.3-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7
                            c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.3-0.5-0.4-0.8l-0.3-0.9l1.4-0.2l0,0.9c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.1,0.6-0.3,0.9
                            c-0.1,0.3-0.2,0.6-0.5,0.9c-0.1,0.1-0.2,0.3-0.3,0.4l-0.4,0.3c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.1-0.7,0.2-1,0.2
                            c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.1-0.9-0.3c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.3-0.8-0.6c-0.2-0.2-0.5-0.4-0.7-0.7
                            c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.3-0.6-0.3-0.9l-0.2-0.9l1.9-0.1l-0.1,0.8
                            c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.3-0.5,0.4-0.7,0.6
                            c-0.3,0.2-0.6,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0.1-0.9,0c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.8-0.3
                            c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.5-0.6
                            c-0.2-0.2-0.3-0.4-0.5-0.7l-0.4-0.7l1.8-0.5l0,0.9c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.3,0.6-0.6,0.8
                            c-0.2,0.3-0.5,0.4-0.8,0.6c-0.3,0.1-0.6,0.3-0.9,0.3c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.1-0.8-0.2
                            c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.9-0.7-1.2-1.1l-0.5-0.7l-0.4-0.7
                            l1.1-0.2l-0.2,0.7c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.5,0.4
                            c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2,0.1-0.5,0.1-0.7,0.1
                            c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.1-0.2-0.3-0.3-0.4-0.6C90.1,43.2,90,43,90,42.7
                            c0-0.2-0.1-0.4-0.1-0.6V41.5z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M139,48.7c0,0,0.1,0.2,0.2,0.4c0,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.5,0,0.8c-0.1,0.3-0.1,0.6-0.3,0.9
                            c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.1-1.2,0.1c-0.2,0-0.4-0.1-0.6-0.1
                            c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.4-0.4
                            c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.3-0.6-0.4-0.8c-0.1-0.3-0.1-0.5-0.1-0.7c0-0.2,0-0.4,0.1-0.6c0.1-0.3,0.1-0.5,0.1-0.5
                            s0.1,0.2,0.3,0.4c0.1,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.2,0.1,0.3,0.2
                            c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.7,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
                            c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5
                            C138.9,48.8,139,48.7,139,48.7z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M100.5,34.7l0.2,0.5c0,0.2,0.1,0.3,0.2,0.5l0.1,0.2l0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.3-0.1,0.6-0.2,1-0.3c0.6-0.3,1.2-0.7,1.7-1.1c0.5-0.4,1.1-0.9,1.5-1.4
                            c0.2-0.2,0.4-0.2,0.6,0c0,0,0.1,0.1,0.1,0.1l0,0.1l0.3,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.4
                            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.1l0.6,0l0.6-0.1c0.4-0.1,0.8-0.2,1.1-0.4
                            c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.5
                            l0.1-0.6l0,0c0.1-0.4,0.5-0.7,1-0.6c0.2,0,0.4,0.2,0.5,0.3c0.5,0.7,1.2,1.3,1.9,1.9c0.4,0.3,0.7,0.5,1.1,0.7
                            c0.4,0.2,0.8,0.4,1.2,0.5c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0,0.5,0.1c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5l0.2-0.6c0.2-0.5,0.7-0.8,1.2-0.6
                            c0.3,0.1,0.6,0.4,0.6,0.7l0.1,0.6c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5l0.1,0.2l0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            l0.1-0.6l0,0c0.1-0.5,0.6-0.9,1.2-0.7c0.4,0.1,0.7,0.4,0.8,0.9l0.1,0.6c0,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.3,0.2,0.5
                            c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0
                            c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4
                            c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5l0.2-0.5l0,0c0.1-0.5,0.6-0.7,1.1-0.6c0.3,0.1,0.5,0.4,0.6,0.7l0.1,0.5
                            c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0,0.4,0.1c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.1,0.4-0.2l0.2-0.1l0.2-0.2
                            c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.3,0.1-0.7,0.2-1.1l0-0.1
                            c0-0.3,0.3-0.5,0.7-0.5c0.2,0,0.4,0.2,0.5,0.4c0.3,0.8,0.9,1.5,1.6,2.1c0.7,0.6,1.6,1.1,2.5,1.5c0.4,0.2,0.9,0.3,1.4,0.3
                            c0.5,0,0.9,0,1.3-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.5,0.1-0.7c0-0.2,0-0.5,0-0.8
                            c0,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.5,0,0.8c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.5,0.3-1,0.4-1.5,0.5
                            c-0.5,0-1.1-0.1-1.6-0.2c-1-0.3-2-0.7-2.8-1.4c-0.9-0.6-1.7-1.4-2.2-2.5l1.1-0.2c0,0.4,0,0.9-0.1,1.4c0,0.2-0.1,0.5-0.2,0.7
                            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.6l-0.3,0.3l-0.3,0.2c-0.2,0.2-0.5,0.2-0.7,0.3c-0.3,0.1-0.5,0.1-0.8,0.1
                            c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.5-0.3-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
                            c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.2-0.4-0.2-0.7l-0.2-0.7l1.7,0l-0.2,0.7
                            c0,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.6
                            c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.2-0.5,0.3-0.7,0.4c-0.3,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.6,0-0.9,0
                            c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.3-0.5-0.4-0.7
                            c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8l-0.1-0.8l1.9,0.1l-0.2,0.8c0,0.3-0.2,0.5-0.3,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.7,0.5
                            c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0-0.6,0-0.9,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4
                            c-0.3-0.2-0.5-0.4-0.7-0.6l-0.3-0.3l-0.2-0.4c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.2-0.5-0.3-0.8l-0.2-0.8l1.9,0.1l-0.3,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.6,0.7c-0.2,0.3-0.5,0.4-0.8,0.6c-0.3,0.2-0.6,0.3-0.9,0.4
                            c-0.3,0.1-0.6,0.1-1,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.3-0.1-0.6-0.1-0.8-0.2c-0.5-0.2-1-0.4-1.4-0.7c-0.4-0.3-0.9-0.6-1.3-0.9
                            c-0.8-0.7-1.5-1.4-2.1-2.3l1.5-0.3l-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6
                            c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.5,0-0.8,0
                            c-0.3,0-0.5-0.1-0.8-0.1c-0.3-0.1-0.5-0.1-0.8-0.3c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.3-0.6-0.5
                            c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.3-0.4-0.4-0.7l-0.3-0.7l0.7,0.2c-0.6,0.5-1.1,0.9-1.7,1.3c-0.6,0.4-1.3,0.8-2,1
                            c-0.4,0.1-0.7,0.2-1.1,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4l-0.2-0.2l-0.1-0.3
                            c-0.1-0.2-0.1-0.3-0.2-0.5L100.5,34.7z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M167.3,33.6l0.4,0.4l0.4,0.3c0.2,0.1,0.3,0.2,0.4,0.3l0.5,0.2c0.3,0.1,0.6,0.3,0.9,0.3
                            c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.1
                            c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.3c0-0.1,0.1-0.2,0-0.3c0-0.1,0-0.3,0-0.4l0-0.1
                            c0-0.5,0.5-1,1-1c0.4,0,0.7,0.2,0.9,0.5c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.2,0.3,0.4,0.5,0.7c0.2,0.2,0.4,0.4,0.6,0.6
                            c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0l0.2,0c0,0,0.1-0.1,0.2-0.1
                            c0.2-0.1,0.4-0.3,0.6-0.6c0.2-0.2,0.3-0.5,0.5-0.8l0,0.5c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
                            c-0.1,0.1-0.1,0.2-0.2,0.2l-0.3,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0.1-0.9,0-1.3-0.1c-0.2-0.1-0.4-0.1-0.6-0.2
                            c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.2-0.5-0.5-0.8-0.8c-0.2-0.3-0.4-0.6-0.6-1l1.9-0.6l0,0.7
                            c0,0.2-0.1,0.5-0.1,0.8c0,0.2-0.2,0.5-0.3,0.8c-0.1,0.3-0.4,0.5-0.6,0.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.5,0.2-0.8,0.1
                            c-0.5,0-1,0-1.4-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.6-0.7-1
                            c-0.1-0.2-0.2-0.4-0.2-0.6L167.3,33.6z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M132,26.8l0.1,0.6c0,0.2,0.1,0.4,0.1,0.5c0,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.4
                            c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4-0.1
                            c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.5-0.3,0.6-0.5c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.3,0.1-0.4
                            l0-0.4c0-0.5,0.4-0.9,0.9-0.9c0.3,0,0.6,0.2,0.7,0.4l0.1,0.1l0.2,0.3l0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3l0.4,0.3
                            c0.3,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.3,0.9,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0
                            c0-0.1,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4l0-0.5l0-0.1
                            c0-0.5,0.3-0.9,0.8-1c0.4,0,0.8,0.2,0.9,0.6l0.2,0.4l0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4l0.3,0.4c0.2,0.3,0.5,0.5,0.7,0.7
                            c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.6,0.3,0.9,0.3c0.2,0.1,0.3,0,0.5,0c0.1,0,0.3,0,0.4-0.1
                            c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5l0.2-0.6l0,0.6c0,0.2,0,0.4-0.1,0.6
                            c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.9-0.1-1.3-0.2
                            c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.5-1-0.7c-0.3-0.3-0.6-0.6-0.9-0.9l-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7l1.7-0.5
                            l0.1,0.7c0,0.2,0,0.5,0,0.7c0,0.2,0,0.5-0.1,0.8c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.5,0.4-0.8,0.6
                            c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5-0.1-0.7-0.1c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.7-0.5-1.1-0.7
                            c-0.3-0.3-0.7-0.6-0.9-0.9c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.6l1.7-0.4l-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7
                            c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.3,0.3-0.8,0.6-1.2,0.8c-0.2,0.1-0.5,0.1-0.7,0.2
                            c-0.2,0.1-0.5,0-0.7,0c-0.5,0-0.9-0.1-1.3-0.3c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.2-0.3-0.4-0.3-0.6
                            c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6L132,26.8z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M171.8,140.9l-0.1,0.7c-0.1,0.2,0,0.4,0,0.7c0,0.2,0,0.4,0.1,0.7c0,0.2,0.1,0.4,0.2,0.6l0.1,0.3
                            l0.2,0.2c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.5,0.2c0.2,0,0.4,0.1,0.6,0.1l0.3,0l0.3-0.1c0.2,0,0.4-0.1,0.6-0.2
                            c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.6
                            l0.2-0.6c0.1-0.3,0.4-0.4,0.7-0.3c0.2,0.1,0.3,0.2,0.4,0.4l0,0l0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5
                            c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0,0.3,0,0.5,0.1
                            c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.2l0.2-0.1l0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4
                            c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5l0.1-0.6c0.1-0.5,0.6-0.8,1-0.6c0.4,0.1,0.6,0.4,0.7,0.8l0,0l0,0.6
                            c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.1,0.4-0.3
                            c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.6l0.1-0.6
                            c0.1-0.5,0.6-0.9,1.2-0.8c0.4,0.1,0.7,0.4,0.8,0.7l0,0l0.2,0.6c0,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5
                            c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0,0.5,0.1
                            c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.2l0.2-0.1l0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5
                            c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.6l0.1-0.7l0,0c0.1-0.5,0.6-0.8,1.1-0.7c0.4,0.1,0.7,0.4,0.7,0.8l0.1,0.7
                            c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.5,0.3,0.7l0.2,0.3l0.2,0.3l0.2,0.3l0.3,0.2c0.2,0.2,0.4,0.3,0.6,0.4
                            c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.6-0.4
                            c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.2,0.2-0.5,0.3-0.7l0.2-0.8l0,0
                            c0.1-0.3,0.4-0.5,0.7-0.4c0.2,0.1,0.4,0.2,0.4,0.4l0.2,0.7c0,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.2
                            c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4-0.1,0.7-0.1c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.3
                            l0.1-0.3c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.2,0.1-0.5,0.1-0.7l0-0.7l0.1,0.7c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5-0.1,0.8l-0.1,0.4
                            c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.5,0.3-0.7,0.5c-0.3,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.6,0-0.8,0
                            c-0.3,0-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.4-0.3-0.6-0.5
                            c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.3-0.5-0.3-0.7l-0.3-0.8l1.2,0l-0.2,0.9
                            c0,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.3-0.4,0.5-0.6,0.8c-0.2,0.3-0.5,0.5-0.7,0.7
                            c-0.2,0.2-0.6,0.4-0.8,0.5c-0.3,0.2-0.6,0.2-1,0.4c-0.3,0.1-0.7,0.1-1,0.2c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.7-0.1-1-0.3
                            c-0.3-0.1-0.6-0.3-0.9-0.5l-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.4l-0.3-0.4l-0.3-0.4c-0.2-0.3-0.3-0.6-0.4-0.9
                            c-0.1-0.3-0.3-0.6-0.3-0.9l-0.2-1l1.9,0l-0.1,0.9c0,0.3-0.2,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.3-0.3,0.5-0.5,0.8
                            c-0.2,0.3-0.4,0.5-0.7,0.7c-0.1,0.1-0.2,0.2-0.4,0.3l-0.4,0.2c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.7,0.1-1,0.2
                            c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.7-0.1-0.9-0.3c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.2-0.4-0.5-0.6-0.8
                            c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.6-0.3-0.8l-0.2-0.9l2,0l-0.2,0.8c0,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8
                            c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.2-0.6,0.2-0.9,0.3
                            c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.4-0.7-0.6
                            c-0.2-0.2-0.4-0.5-0.5-0.7c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.8l0-0.8l1.7,0.2l-0.2,0.7
                            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.6l-0.3,0.2l-0.4,0.2
                            c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0-0.8,0.1c-0.3,0-0.6-0.1-0.8-0.1c-0.3-0.1-0.5-0.2-0.8-0.3
                            c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7
                            c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.7l1.1,0.1l-0.3,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.2-0.3,0.4-0.5,0.6
                            c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.3-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.2l-0.4,0.1l-0.4,0
                            c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.3-0.5-0.6l-0.2-0.3l-0.1-0.3c-0.1-0.2-0.1-0.5-0.1-0.7
                            c0-0.2,0-0.5,0-0.7c0-0.2,0.1-0.5,0.1-0.7L171.8,140.9z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M161.7,149.7l0.1,0.7c0,0.3,0.1,0.5,0.2,0.7l0.1,0.3l0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6l0.2,0.3
                            c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4,0,0.6-0.1
                            c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.4l0.2-0.2l0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6
                            c0-0.2,0.1-0.4,0.1-0.6l0.1-0.6c0.1-0.5,0.5-0.9,1-0.8c0.4,0.1,0.7,0.4,0.8,0.8l0,0.1l0.1,0.5c0,0.2,0.1,0.3,0.1,0.5
                            c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3l0.1,0.1l0.2,0.1c0.1,0.1,0.2,0.1,0.4,0.1
                            c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3
                            c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5l0.2-0.5l0-0.1c0.2-0.5,0.7-0.7,1.2-0.6
                            c0.4,0.1,0.6,0.4,0.6,0.8l0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.4,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2
                            c0.2,0.1,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.4l0.2-0.2l0.1-0.3l0.2-0.3l0.1-0.3
                            c0.1-0.2,0.1-0.5,0.1-0.7l0.1-0.7l0.1,0.7c0.1,0.3,0.1,0.5,0,0.8l0,0.4c0,0.1-0.1,0.3-0.1,0.4l-0.1,0.4l-0.2,0.4
                            c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.6-0.2-0.8-0.3
                            c-0.3-0.1-0.5-0.3-0.8-0.4c-0.3-0.1-0.4-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.3-0.5-0.4-0.7
                            c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.5-0.2-0.8l-0.1-0.8l1.8,0.2l-0.2,0.7c-0.1,0.2-0.2,0.5-0.3,0.7
                            c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.2-0.5,0.3-0.7,0.4
                            c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.1-0.8-0.3l-0.4-0.2l-0.3-0.3
                            c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.5-0.1-1-0.2-1.5l1.8,0l-0.2,0.8
                            c0,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.5,0.7l-0.3,0.3l-0.4,0.3c-0.2,0.2-0.5,0.3-0.8,0.5
                            c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0-0.9,0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4
                            c-0.2-0.2-0.5-0.3-0.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2-0.4c-0.1-0.2-0.2-0.5-0.3-0.8l-0.1-0.4l0-0.4c0-0.3-0.1-0.5,0-0.8
                            L161.7,149.7z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M187.6,151l0,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5
                            c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0,0.5,0.1
                            c0.2,0,0.3,0,0.5,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3l0.2-0.2l0.1-0.2l0.1-0.2l0.1-0.2
                            c0.1-0.2,0.1-0.3,0.2-0.5l0.1-0.5c0.1-0.4,0.5-0.7,0.9-0.6c0.3,0.1,0.6,0.4,0.6,0.7l0,0.1l0,0.6c0,0.2,0.1,0.4,0.1,0.6
                            c0,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0
                            c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.6-0.2l0.3-0.1l0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.2l0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.4
                            l0.2-0.2l0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6l0-0.6v0c0-0.5,0.4-1,1-1c0.5,0,0.9,0.3,1,0.8l0.1,0.6
                            c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.4,0.2-0.6l0.1-0.6l0-0.1
                            c0.1-0.4,0.4-0.6,0.8-0.5c0.3,0.1,0.5,0.4,0.5,0.7l0,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.3,0.2,0.4
                            c0.1,0.2,0.3,0.5,0.5,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0
                            c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.3,0.2-0.6,0.2-1
                            c0,0.3,0.1,0.7,0,1c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.1,0.3-0.3,0.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3
                            c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.5-0.9-0.8
                            c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6l0-0.6l1.3,0.1l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.1,0.5-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.5-0.6,0.7c-0.2,0.2-0.5,0.4-0.8,0.5
                            c-0.3,0.2-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0-0.9,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4
                            c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.2-0.3-0.5-0.4-0.7
                            c-0.1-0.3-0.2-0.5-0.3-0.8l-0.2-0.8l2-0.3l0,0.9c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.2,0.6-0.3,0.9l-0.2,0.4
                            c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.4,0.5-0.6,0.7l-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3l-0.4,0.3l-0.4,0.2
                            c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.1-1,0.1c-0.3,0-0.6-0.1-1-0.1c-0.3,0-0.6-0.2-0.9-0.3c-0.3-0.2-0.6-0.4-0.8-0.6
                            c-0.2-0.3-0.5-0.5-0.6-0.8c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.3-0.1-0.6-0.1-0.9l0.1-0.9l1.5,0.2l-0.2,0.7
                            c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.2-0.2,0.4-0.4,0.6l-0.2,0.3l-0.3,0.2c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.1-0.8,0.2
                            c-0.3,0-0.5,0-0.8,0c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.4-0.3-0.5-0.5c-0.2-0.2-0.3-0.3-0.4-0.6
                            c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.4,0-0.6L187.6,151z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M175,160.7c0,0.4,0,0.8,0.1,1.1c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5
                            c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.3,0.1,0.5,0.1c0.3,0,0.6,0,0.9-0.2
                            c0.3-0.1,0.6-0.3,0.8-0.5c0.1-0.1,0.2-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.6,0.5-0.9c0.2-0.3,0.5-0.4,0.8-0.2
                            c0.2,0.1,0.3,0.3,0.3,0.4l0,0.1l0.1,0.8c0,0.3,0.1,0.5,0.1,0.7c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0,0.5-0.1l0.3-0.1l0.3-0.1
                            c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.4,0.4-0.5c0.2-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6
                            c0.1-0.2,0.2-0.4,0.2-0.7l0.2-0.7c0.1-0.5,0.7-0.8,1.2-0.7c0.4,0.1,0.7,0.5,0.7,1l0,0l0,0.6c0,0.2,0,0.4,0,0.6
                            c0,0.2,0,0.4,0.1,0.5c0,0.2,0.1,0.3,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2
                            c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.3
                            c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5l0.2-0.6l0,0
                            c0.1-0.5,0.7-0.8,1.2-0.7c0.4,0.1,0.7,0.5,0.7,0.9l0,0.7c0,0.2,0,0.4,0.1,0.7l0.1,0.3l0.1,0.3l0.1,0.3l0.2,0.2
                            c0.1,0.1,0.1,0.2,0.2,0.2l0.2,0.2c0.1,0.2,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6-0.1
                            c0.2-0.1,0.4-0.1,0.6-0.3l0.3-0.2l0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.2l0.2-0.3l0.2-0.3l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6
                            c0.1-0.2,0.2-0.4,0.2-0.7l0.1-0.7l0-0.1c0-0.4,0.4-0.6,0.8-0.6c0.3,0,0.5,0.2,0.6,0.4l0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.3,0.3,0.5,0.4
                            c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.4-0.5
                            c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.3-0.9,0.4-1.4c-0.1,0.5,0,1-0.2,1.4c-0.1,0.2-0.1,0.5-0.2,0.7
                            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.2-0.5,0.2-0.8,0.3c-0.3,0-0.6,0-0.8,0
                            c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.6-0.5
                            c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.3-0.4-0.4-0.7l-0.3-0.7l1.3-0.2l0,0.9c0,0.3-0.1,0.6-0.2,0.9
                            c-0.1,0.3-0.2,0.6-0.3,0.9l-0.2,0.4l-0.3,0.4l-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3l-0.3,0.3l-0.4,0.3c-0.3,0.2-0.6,0.3-0.9,0.5
                            c-0.3,0.2-0.6,0.2-1,0.3c-0.3,0.1-0.7,0-1,0c-0.3-0.1-0.7-0.1-1-0.2c-0.3-0.2-0.7-0.3-0.9-0.5l-0.4-0.3
                            c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.4l-0.2-0.5l-0.2-0.5l-0.1-0.5c-0.1-0.3-0.1-0.6-0.1-1l0-0.9l1.9,0.3
                            l-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6
                            c-0.2,0.2-0.5,0.4-0.7,0.5c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0.1-0.6,0-1,0c-0.3,0-0.7-0.1-1-0.3
                            c-0.3-0.2-0.6-0.3-0.9-0.6c-0.1-0.1-0.3-0.2-0.4-0.4l-0.3-0.4c-0.2-0.3-0.3-0.6-0.3-0.9c-0.1-0.3-0.1-0.6-0.1-0.9
                            c0-0.3,0-0.6,0-0.8l0.1-0.8l1.9,0.4l-0.3,0.8c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.3-0.3,0.5-0.5,0.8
                            c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.2-0.4,0.4-0.7,0.6c-0.3,0.2-0.5,0.4-0.8,0.5l-0.5,0.2l-0.5,0.1c-0.3,0.1-0.7,0-1,0
                            c-0.3-0.1-0.7-0.2-1-0.4l-0.4-0.3c-0.1-0.1-0.2-0.2-0.4-0.4c-0.3-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.2-0.6-0.3-0.9
                            c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.3-0.1-0.6-0.1-0.9l0-0.9l1.1,0.3c-0.2,0.3-0.5,0.7-0.7,1c-0.1,0.2-0.3,0.3-0.4,0.4
                            c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.9,0.2-1.3,0.1c-0.2,0-0.4-0.1-0.6-0.2
                            c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.2-0.6
                            c0-0.2-0.1-0.4-0.1-0.6C174.9,161.5,174.9,161.1,175,160.7z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M105.4,160.5l0,0.7c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5l0.2,0.2
                            c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.3-0.1,0.5-0.1
                            c0.2-0.1,0.3-0.1,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.1-0.5
                            c0-0.2,0.1-0.3,0.1-0.5l0-0.6c0-0.5,0.4-0.8,0.9-0.8c0.4,0,0.7,0.3,0.8,0.7l0,0l0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.2
                            c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.2-0.1l0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4
                            c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.1-0.8,0.2-1.3l0-0.1c0.1-0.5,0.5-0.9,1.1-0.8
                            c0.4,0.1,0.7,0.3,0.8,0.7c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5
                            c0.1,0.2,0.3,0.3,0.5,0.5l0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0,0.4,0,0.6,0l0.3-0.1
                            c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.1,0.3-0.4,0.5-0.5c0.2-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.5,0.3-0.7
                            c0.2-0.5,0.3-1,0.4-1.5l0,0.8c0,0.3,0,0.5-0.1,0.8c0,0.3,0,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.3,0.8c-0.1,0.2-0.2,0.5-0.4,0.8
                            c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1,0.1-0.3,0.2-0.4,0.3l-0.5,0.2c-0.3,0.1-0.7,0.1-1,0.2c-0.3,0-0.7,0-1-0.2
                            c-0.2-0.1-0.3-0.1-0.5-0.2l-0.4-0.2c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.3-0.5-0.5-0.8
                            c-0.2-0.3-0.3-0.5-0.4-0.8c-0.3-0.5-0.4-1.1-0.5-1.7l1.8-0.2l0,0.8c0,0.3-0.1,0.6-0.1,0.8c-0.1,0.3-0.1,0.6-0.2,0.8
                            c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.3-0.3,0.5-0.6,0.8c-0.1,0.1-0.2,0.2-0.4,0.4l-0.4,0.3c-0.3,0.2-0.6,0.3-1,0.4
                            c-0.3,0-0.7,0.1-1,0c-0.3,0-0.7-0.1-1-0.3c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.5-0.7
                            c-0.2-0.2-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.5-0.3-0.8s-0.2-0.5-0.2-0.8l-0.1-0.8l1.7-0.1l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.3,0.4-0.6,0.6c-0.2,0.2-0.4,0.3-0.7,0.4
                            c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.2-0.1-0.5-0.2-0.7-0.3
                            c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.2-0.2-0.2-0.3l-0.2-0.3c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.2-0.1-0.5,0-0.7
                            C105.2,161.3,105.4,160.9,105.4,160.5z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M129.7,160.5c0.1,1.1,0.3,2.2,0.8,3.2c0.2,0.5,0.6,0.9,1,1.1c0.4,0.2,0.8,0.2,1.3,0
                            c0.8-0.4,1.5-1.3,1.9-2.3c0.4-0.9,0.6-2,0.7-3l1.4,0.2l0,0.7c0,0.2,0,0.5,0.1,0.7c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5l0.2,0.2l0.2,0.2c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.1
                            c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.5-0.2l0.3-0.1l0.2-0.2c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5
                            c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6l0.1-0.7l1.9,0.1l0,0.7c0,0.2,0,0.5,0,0.7c0,0.2,0,0.5,0.1,0.7
                            c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
                            c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5-0.1l0.3-0.1l0.2-0.2c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.5
                            c0.2-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.7l0.1-0.7l0,0
                            c0.1-0.5,0.5-0.9,1.1-0.8c0.5,0.1,0.8,0.4,0.8,0.9l0.1,0.7c0,0.3,0.1,0.5,0.1,0.7c0.1,0.2,0.1,0.5,0.2,0.7
                            c0.1,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.4,0.4l0.2,0.2c0.1,0,0.2,0.1,0.2,0.1
                            c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1l0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4
                            c0.2-0.1,0.3-0.4,0.4-0.5c0.2-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.5,0.3-1,0.4-1.5l0-0.1
                            c0.1-0.4,0.5-0.6,0.8-0.5c0.3,0.1,0.5,0.3,0.5,0.6l0.1,0.7c0,0.2,0.1,0.4,0.1,0.7c0.1,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.1,0.6,0.2
                            c0.2,0.1,0.4,0,0.7,0c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.3,0.5-0.5l0.2-0.3l0.1-0.3
                            c0.1-0.2,0.2-0.4,0.2-0.7c0-0.2,0.1-0.5,0.1-0.7l0-0.8l0.1,0.8c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5-0.1,0.8l-0.1,0.4l-0.2,0.4
                            c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0-0.6,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1
                            c-0.3-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.3-0.5-0.5-0.7
                            c-0.2-0.2-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.5-0.2-0.8l-0.2-0.8l1.3,0l-0.1,0.9c0,0.3-0.1,0.6-0.2,0.9
                            c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.3-0.3,0.5-0.5,0.8c-0.2,0.2-0.4,0.5-0.7,0.7l-0.4,0.3
                            c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0.2-0.7,0.2-1.1,0.3c-0.4,0-0.8,0-1.1-0.1l-0.5-0.2l-0.5-0.3c-0.3-0.2-0.5-0.5-0.8-0.7
                            c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.6-0.2-1.2-0.3-1.8l1.9,0l-0.1,0.9
                            c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.1,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.3-0.3,0.6-0.5,0.8
                            c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.4-0.8,0.6l-0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c-0.3,0.1-0.7,0.2-1.1,0.2
                            c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.4-0.4c-0.3-0.3-0.4-0.6-0.6-0.9
                            c-0.2-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.1-0.6-0.1-0.9l0-0.9l0,0c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1
                            c0,0,0,0.1,0,0.1l-0.1,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.3-0.3,0.6-0.5,0.8
                            c-0.2,0.3-0.4,0.5-0.7,0.7l-0.4,0.3l-0.5,0.2c-0.3,0.2-0.6,0.2-1,0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.3-0.1-0.7-0.1-1-0.2
                            c-0.3-0.1-0.6-0.2-0.9-0.5l-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.3-0.3-0.6-0.3-0.9
                            c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3-0.1-0.6,0-0.9l0.1-0.9c0-0.4,0.4-0.7,0.7-0.7c0.4,0,0.7,0.4,0.7,0.7l0,0.1l0,0
                            c-0.2,1.2-0.5,2.3-1.1,3.4c-0.6,1-1.3,2.1-2.6,2.5c-0.3,0.1-0.7,0.2-1,0.1c-0.3,0-0.7-0.2-0.9-0.4c-0.5-0.4-0.8-1-1-1.5
                            C129.7,162.8,129.7,161.6,129.7,160.5z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M172.9,170.6c0.2,1.1,0.2,2.2,0.4,3.2c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6
                            c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2l0,0c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4-0.1,0.5-0.1c0.2-0.1,0.3-0.2,0.5-0.4
                            c0.1-0.2,0.3-0.3,0.4-0.5c0.5-0.8,0.9-1.8,1.2-2.8c0.2-0.5,0.7-0.7,1.1-0.6c0.3,0.1,0.6,0.4,0.6,0.8l0,0.1l0.1,0.7
                            c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3l0.2,0.2c0.1,0.2,0.3,0.3,0.4,0.4
                            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0,0.6,0.1c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.6-0.2
                            c0.2-0.1,0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0.2-0.4,0.1-0.6
                            l0.1-0.7l1.8,0l0,0.7c0,0.2,0,0.5,0.1,0.7c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5
                            c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0,0.1,0.1,0.2,0.2l0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1l0.3-0.1l0.3-0.2
                            c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.1,0.3-0.4,0.5-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.2-0.5,0.2-0.7
                            l0.2-0.8l0,0.8c0,0.3,0,0.5-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.1,0.5-0.3,0.8c-0.1,0.2-0.2,0.5-0.4,0.7
                            c-0.2,0.2-0.3,0.5-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3l-0.4,0.2c-0.3,0.2-0.6,0.2-0.9,0.3c-0.3,0-0.7,0.1-1,0l-0.5-0.1
                            c-0.2-0.1-0.3-0.1-0.4-0.2c-0.3-0.1-0.5-0.4-0.8-0.6c-0.2-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.3-0.5-0.4-0.8
                            c-0.1-0.3-0.2-0.6-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.8l-0.1-0.8c0-0.5,0.3-0.9,0.8-1c0.5,0,0.9,0.3,1,0.8l0,0.1v0l0,0.9
                            c0,0.3-0.1,0.6-0.2,0.9c0,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.3,0.6-0.6,0.9c-0.2,0.3-0.5,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5
                            c-0.3,0.2-0.7,0.2-1,0.3c-0.3,0-0.7,0.1-1,0.1c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.7-0.2-0.9-0.4c-0.3-0.2-0.6-0.3-0.8-0.6
                            c-0.3-0.2-0.5-0.5-0.7-0.7l-0.3-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.3-0.6-0.3-0.9c-0.1-0.3-0.2-0.6-0.2-0.9l0-0.9
                            l1.8,0.4c-0.4,1-0.8,2.1-1.6,3.1c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.4-0.9,0.5c-0.4,0.1-0.7,0.2-1.1,0.2
                            c-0.4,0-0.7-0.1-1-0.2l0,0c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.5-0.7-0.7c-0.2-0.3-0.3-0.6-0.3-0.9
                            c-0.1-0.3-0.1-0.6-0.1-0.9C172.6,172.7,172.9,171.7,172.9,170.6z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M197,171.5c0.3,1.1,0.3,2.3,0.6,3.3c0.1,0.5,0.3,0.9,0.6,1.2c0.1,0.1,0.1,0.1,0.2,0.1l0.2,0.1
                            c0.1,0,0.2,0,0.2,0.1l0.3,0l0,0l0,0c0,0-0.1,0-0.1,0h0l0,0l0.1,0l0.1,0l0.2,0c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.3
                            c0.3-0.2,0.5-0.6,0.7-1c0.4-0.9,0.7-1.9,1-2.9c0.1-0.5,0.7-0.8,1.2-0.6c0.4,0.1,0.7,0.6,0.7,1l0,0c0,0.3-0.1,0.6-0.1,0.8
                            c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2
                            c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3
                            c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.1-0.6,0.2-1c0.1,0.3,0.2,0.7,0.2,1c0,0.2,0,0.4,0,0.5
                            c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.1-0.7,0.1
                            c-0.3,0-0.5,0-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.3-0.6
                            c-0.1-0.4-0.2-0.8-0.2-1.2c-0.1-0.4,0-0.8,0-1.2l1.8,0.4c-0.2,1.1-0.5,2.3-1.1,3.4c-0.3,0.6-0.7,1.1-1.3,1.6
                            c-0.3,0.2-0.7,0.4-1.1,0.5c-0.2,0-0.5,0.1-0.7,0.1l-0.2,0l-0.1,0l-0.1,0l-0.1,0l0,0l0,0l-0.5-0.1c-0.2-0.1-0.4-0.1-0.5-0.2
                            c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.4-0.4c-0.4-0.6-0.5-1.3-0.5-1.9C196.4,173.7,196.7,172.6,197,171.5z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M170.5,179.8c-0.2,0.9-0.3,1.9-0.4,2.8c0,0.5,0,0.9,0,1.4c0,0.2,0.1,0.4,0.2,0.5c0,0,0.3,0.4,0.4,0.4
                            c0.3,0.2,0.7,0.3,1,0.3c0.2,0,0.4-0.1,0.5-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.5
                            c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.4,0.5-0.6,0.8-0.5c0.3,0.1,0.5,0.4,0.5,0.7l0,0.1l0,0.6
                            c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.5c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1
                            c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.5-0.1c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.5,0.8-0.7c0.1-0.1,0.3-0.3,0.4-0.4
                            c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.3-0.7,0.5-1c0.2-0.5,0.8-0.7,1.3-0.5c0.3,0.1,0.5,0.4,0.6,0.8l0,0l0.1,0.6
                            c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5l0.1,0.3l0.2,0.2l0.2,0.2l0.2,0.2c0.1,0.1,0.3,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2-0.1,0.3-0.1,0.5-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5l0.1-0.6l1.9,0.2
                            l-0.1,0.6c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.5c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.3,0.2,0.3c0,0,0.1,0.1,0.1,0.1
                            c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3-0.1,0.4-0.2
                            c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            l0.2-0.5l0,0c0.1-0.4,0.5-0.6,0.9-0.5c0.3,0.1,0.5,0.4,0.5,0.8l-0.1,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.6
                            c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5l0.2,0.2l0.2,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1
                            c0.2,0,0.4,0,0.6,0c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2,0.3-0.3,0.4-0.5
                            c0.1-0.2,0.3-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7l0.1-0.7l0,0.7c0,0.3-0.1,0.5-0.1,0.7c0,0.3-0.2,0.5-0.3,0.7
                            c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.4,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.8,0.3
                            c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.6-0.1-0.8-0.3c-0.1-0.1-0.3-0.1-0.4-0.2L194,187
                            c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.2-0.2-0.5-0.4-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.8l0-0.8l1.4,0.2l-0.2,0.7
                            c0,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.3,0.4-0.5,0.6
                            c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.7,0.4c-0.3,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.6,0-0.9,0
                            c-0.3-0.1-0.6-0.1-0.9-0.3l-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.5-0.5-0.8c-0.2-0.3-0.2-0.5-0.3-0.8
                            c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0-0.8l0.1-0.7c0.1-0.5,0.5-0.9,1-0.9c0.5,0.1,0.9,0.5,0.9,1l0,0.1l-0.1,0.8
                            c0,0.3-0.2,0.6-0.2,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.6
                            c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0-0.6,0-0.9,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4
                            c-0.3-0.2-0.5-0.3-0.7-0.5l-0.3-0.3l-0.3-0.4l-0.3-0.4l-0.2-0.4c-0.1-0.3-0.3-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.8l-0.1-0.8
                            l1.9,0.3c-0.2,0.5-0.4,0.9-0.7,1.3c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.4-0.5,0.6c-0.3,0.4-0.7,0.7-1.2,0.9
                            c-0.4,0.3-0.9,0.5-1.4,0.7c-0.3,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.9-0.4
                            c-0.3-0.2-0.5-0.4-0.6-0.7c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.5,0-0.7l0-0.7l1.4,0.2
                            c-0.3,1.1-1.1,2-2,2.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.6,0-1.2-0.2-1.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
                            l-0.1-0.1l-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.2-0.3-0.2-0.6-0.2-0.8c0-0.5,0.1-1,0.2-1.5
                            C169.8,181.6,170.1,180.7,170.5,179.8z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M166.9,190.2c-0.3,0.9-0.6,1.9-0.7,2.8c0,0.4,0.1,0.9,0.2,1.1c0.2,0.2,0.4,0.2,0.8,0.1
                            c0.7-0.2,1.5-0.7,2.1-1.4c0.6-0.7,1.1-1.4,1.5-2.2c0.2-0.4,0.8-0.6,1.2-0.4c0.3,0.1,0.4,0.4,0.5,0.7l0,0.1l0.1,0.6
                            c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3
                            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3
                            c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.6l0.1-0.6l0,0
                            c0.1-0.5,0.5-0.8,1-0.7c0.4,0.1,0.7,0.5,0.7,0.9c0,0.4-0.1,0.8-0.1,1.2c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.1,0.5
                            c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3,0.1,0.5,0c0.2,0,0.3-0.1,0.5-0.1
                            c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.3-0.3,0.6-0.6,0.8-1c0.1-0.2,0.3-0.3,0.4-0.5
                            l0.3-0.6l-0.1,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.4-0.4,0.6
                            c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.6,0.1-0.8,0.1
                            c-0.3-0.1-0.6-0.1-0.9-0.3c-0.3-0.1-0.5-0.4-0.7-0.6c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.2-0.2-0.5-0.2-0.7
                            c-0.1-0.3-0.1-0.5-0.1-0.7c-0.1-0.5,0-1-0.1-1.4l1.7,0.1l-0.1,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.1,0.5-0.3,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.5-0.7,0.6c-0.1,0.1-0.3,0.2-0.4,0.3l-0.5,0.2
                            c-0.3,0.1-0.7,0.1-1,0.2c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.7-0.2-0.9-0.4c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.5-0.8
                            c-0.2-0.3-0.2-0.5-0.4-0.8c-0.1-0.3-0.2-0.5-0.2-0.8c-0.2-0.6-0.1-1.1-0.2-1.6l1.7,0.4c-0.5,0.9-1.2,1.8-2,2.5
                            c-0.8,0.7-1.7,1.3-3,1.4c-0.3,0-0.6,0-1-0.1c-0.3-0.1-0.6-0.4-0.8-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0-0.3,0-0.6,0.1-0.8
                            C165.7,191.8,166.3,191,166.9,190.2z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M188,191.1c0,0.5,0,1,0,1.5l0,0.7l0.1,0.7c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.3,0.5
                            c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0,0.3,0c0.1,0,0.2,0,0.4-0.1c0.1,0,0.3-0.2,0.4-0.2c0.3-0.2,0.6-0.5,0.8-0.8
                            c0.1-0.1,0.2-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.4,0.4-0.8,0.6-1.2l0,0c0.2-0.5,0.9-0.7,1.4-0.5
                            c0.3,0.2,0.5,0.5,0.6,0.9c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.1,0.3,0.1,0.4
                            c0.1,0.1,0.1,0.3,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0,0.4,0.1c0.1,0,0.3,0,0.4,0
                            c0.1,0,0.3-0.1,0.4-0.1l0.2-0.1l0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.3,0.2-0.5
                            c0.1-0.4,0.2-0.8,0.2-1.2l0.2,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.7c0,0.2-0.1,0.5-0.2,0.7
                            c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.6,0-0.8,0
                            c-0.3-0.1-0.6-0.1-0.8-0.3c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.4-0.5-0.6c-0.2-0.2-0.2-0.5-0.4-0.7
                            c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.2-0.1-0.5-0.1-0.7l0-0.7l1.9,0.4c-0.2,0.5-0.4,1-0.7,1.4c-0.1,0.2-0.3,0.5-0.4,0.7
                            c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.5,0.3-0.9,0.4
                            c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5,0c-0.4-0.1-0.7-0.2-1-0.4c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.3-0.2-0.6-0.2-0.9
                            c0-0.3-0.1-0.6,0-0.8c0-0.3,0.1-0.5,0.1-0.8l0.2-0.7C187.6,192,187.8,191.5,188,191.1z"
                  />
                </g>
                <g>
                  <path
                    className="st15"
                    d="M163.7,199.2l-0.4,0.6c-0.1,0.2-0.2,0.5-0.3,0.7l-0.1,0.3l0,0.4l-0.1,0.3l0,0.3c0,0.2,0.1,0.4,0.2,0.7
                            c0,0.2,0.2,0.4,0.3,0.6c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4-0.1,0.6-0.1
                            c0.2-0.1,0.4-0.2,0.6-0.3l0.5-0.4c0.2-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.4,0.3-0.6l0.2-0.3l0.1-0.3l0.2-0.6
                            c0.1-0.3,0.5-0.5,0.8-0.4c0.2,0.1,0.4,0.3,0.4,0.5l0,0.1l0,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6
                            c0.1,0.2,0.1,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.4,0.4l0.2,0.2l0.2,0.1c0.1,0.1,0.3,0.1,0.5,0.2
                            c0.2,0.1,0.3,0,0.5,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.2-0.1l0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.4
                            c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.1-0.6l0.1-0.6l1.8,0.2l0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.5
                            c0.1,0.2,0.1,0.4,0.2,0.4c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3,0,0.4-0.1l0.2-0.1
                            l0.2-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5
                            c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.4,0.1-0.6l0-0.6l2,0.1l0,0.6c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.5
                            c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.1,0.1,0.3,0.2,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.1,0.4,0.1
                            c0.1,0,0.3,0,0.4,0c0.2-0.1,0.3,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.4
                            c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0.1-0.4,0.1-0.5l0.1-0.6h1.8c0,1.2-0.1,2.3,0,3.3
                            c0,0.2,0.1,0.5,0.2,0.7l0.1,0.3l0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2,0,0.3,0.1l0.3,0c0.2,0,0.5-0.1,0.7-0.1l0.4-0.1
                            l0.2-0.1l0.1-0.1c0.2-0.1,0.3-0.2,0.5-0.4l0.3-0.2l0.2-0.3c0.2-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.2-0.5,0.2-0.7
                            c0.1-0.5,0.2-1.1,0.2-1.6l0.9,0.1c0,0.3-0.1,0.6,0,0.9c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.1,0.3,0.2,0.4
                            c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.2
                            c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0.1-0.3,0-0.7,0-1
                            c0,0.3,0.1,0.7,0.1,1c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.3-0.4,0.4
                            c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2
                            c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.4-0.1-0.5
                            c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.4l0,0.1c0,0.6,0,1.2-0.1,1.8c-0.1,0.3-0.1,0.6-0.2,0.9
                            c-0.1,0.3-0.2,0.6-0.4,0.9l-0.2,0.4l-0.3,0.4c-0.2,0.3-0.5,0.5-0.9,0.7l-0.2,0.2l-0.2,0.1l-0.4,0.1c-0.4,0.1-0.7,0.2-1,0.2
                            l-0.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.4-0.1-0.8-0.3-1.1-0.6c-0.1-0.1-0.3-0.3-0.4-0.5l-0.3-0.5c-0.1-0.3-0.2-0.7-0.3-1
                            c-0.3-1.3-0.2-2.5-0.2-3.6l0,0c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9l0,0.1l-0.1,0.8c0,0.3-0.1,0.6-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.4,0.7c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.2-0.4,0.4-0.7,0.6
                            c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0-0.6,0.1-1,0c-0.3-0.1-0.7-0.1-1-0.3c-0.2-0.1-0.3-0.2-0.5-0.2
                            c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.6,0-0.8
                            l0.1-0.8c0-0.5,0.5-1,1.1-0.9c0.5,0,0.9,0.5,0.9,1l0,0.8c0,0.3-0.1,0.6-0.1,0.8c0,0.3-0.1,0.6-0.2,0.8
                            c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.5-0.6,0.6c-0.2,0.2-0.5,0.4-0.7,0.5l-0.4,0.2l-0.5,0.2
                            c-0.3,0.1-0.6,0.1-1,0.2c-0.3,0-0.7,0-1-0.2c-0.3-0.1-0.7-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.8c-0.2-0.3-0.2-0.6-0.4-0.9
                            c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3-0.1-0.6,0-0.8l0-0.8l0,0c0-0.5,0.5-0.9,1-0.8c0.5,0,0.9,0.5,0.8,1c0,0,0,0.1,0,0.1l-0.1,0.8
                            c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.3,0.5-0.5,0.7l-0.3,0.3l-0.4,0.3c-0.2,0.2-0.6,0.3-0.9,0.4
                            c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4l-0.4-0.2l-0.3-0.3c-0.2-0.2-0.4-0.4-0.6-0.6
                            c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3-0.1-0.5-0.1-0.8l0-0.8l1.2,0.2l-0.3,0.7
                            l-0.2,0.4l-0.2,0.3c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.3-0.7,0.5c-0.3,0.1-0.5,0.2-0.8,0.3
                            c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.4-0.4-0.6-0.6
                            c-0.1-0.3-0.3-0.5-0.3-0.7c-0.1-0.3-0.1-0.5-0.1-0.8l0-0.4l0.1-0.4l0.1-0.4l0.2-0.3c0.1-0.2,0.2-0.5,0.4-0.6L163.7,199.2z"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      className="st7"
                      d="M157.3,42.3c7.7,0,14.2-6.4,14.2-14.2c0-7.7-6.4-14.2-14.2-14.2c-7.7,0-14.2,6.4-14.2,14.2
                             C143.1,35.9,149.6,42.3,157.3,42.3L157.3,42.3z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st9"
                      d="M163,32c3.9,0,3.9-6.1,0-6.1C159.1,25.9,159.1,32,163,32L163,32z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      className="st7"
                      d="M119.8,39.9c7.7,0,14.2-6.4,14.2-14.2c0-7.7-6.4-14.2-14.2-14.2c-7.7,0-14.2,6.4-14.2,14.2
                             C105.6,33.4,112,39.9,119.8,39.9L119.8,39.9z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      className="st9"
                      d="M125.4,29.6c3.9,0,3.9-6.1,0-6.1C121.5,23.5,121.5,29.6,125.4,29.6L125.4,29.6z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <image
                style={{ overflow: "visible", opacity: "0.28" }}
                width={320}
                height={211}
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAADTCAYAAADwFcrzAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
                                                                                     GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACI9JREFUeNrs3ely28YSgNEBCS2J
                                                                                     c9//RWNLlkjimimg0mrPYCEpySmfU4UCtViG/eOrHixUKQAA/F66X+RnAFxi+Oh4dWII/KLR2xTE
                                                                                     7orv7RZ+jhgC7xm9Yebrq0K4NlLdyv3czxRE4Noprxa9pf3FAcxh69Lr2ufWBhFgSwhj2PLrofH1
                                                                                     2RCuOZ/Xit6u8bpbsWQG2DIB1oJ3arwe1k6DSwGMMdtV9nnrGtOhCALXxG+oxC9vQ2VfmwgXg5Sn
                                                                                     vlrs9mGbi6H4AdcufVvRO4atFcXmFNhtjF+MXj9u+7DfVyIofsA1ERwa0TuE/WEmhs0IdjNL3xjA
                                                                                     HL67sPVh34sg8M7xm4L3Gvav4eMcwtq5wWqUatNfnvjux+Cd9w/p4z5stfOCAGsDmJe+h7C9jME7
                                                                                     77+nj/NE2JwCWwHcpQD2IXLn7XGM37R/CDHs05K4E0HgwvgNacl7CNGbtuewfwkxPKQAntYEsDb9
                                                                                     xYnvvP1R2R5DIO/SFLgrrggDy0ve0lj6HsKE9zLG7qmyfU8TYWsp/I++cTC1EE5T4DT5naP35cf2
                                                                                     Z9hiBFtTIMDaCTBOfzF+d+Xf6w05mPkc4NSen64C9zMTWes84H2YAs/R+2vc/gyT4EM4wH34OSY/
                                                                                     YM0keEoBfA3L3Puwwizl7cWR1/L2jpTW/chDawKMfyAuYePV3xzA/43T4JcQwDgFui8QWLsEjhc/
                                                                                     4vT3PU1/MX7T8viu/HtFeGrXsTUF9jMHlO8DzBPgY1j6fgkRjEvhfFuM84DAXPxqy9lDWvpOk1+c
                                                                                     +uJFkdycZmf6mSC1zgNOEZyuBv8Rpr+/whSYL4Z4PA5YE784AR7SdBeXvdPnn8rPd6C0mvNmCpy7
                                                                                     CDI3BeYIPoZJcDoX+FB+vhrsQgiwFMR879804eX4Pc7EL/emWzMBtiKYzwXG+wIfwpJ4mginiyEC
                                                                                     CNwigDl+z+Xtgxj5AYxVT6H1M+GrLYNrk2C8QTqeH4wXQgQQuDSAOX73lfDVHsGdXf4uTYBzEdyX
                                                                                     +mNy8RnheIB7AQQ2BvAYenEqb99/IL8By75c8PjtmiXwXAz3pf4OMXkTQGBrALvwub4Svtr9fpt+
                                                                                     P1G/4cBqF0bm3i9wN3OQAHMBjPE7lvn3HF11wWNLAOd++VHrrfF3CzH01ljAUvziebqh0ZBdqf8q
                                                                                     jq6s+8VtmybApTdNbYWxW3FwAK3ubGnJRb+at7/Rga456Nb3Aya/kqa/rS25SP+B/0gTIDA3ROVz
                                                                                     f+9ud8PJD+Cjo3lVf3bvdEC3+D7g943ahzRk90n/AIBPb8zO/yfwuxJAQAABBBBAAAEEEEAAAQQQ
                                                                                     QAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEBBA
                                                                                     AAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAAB
                                                                                     BBBAAAEEEEAAAQQQQEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEE
                                                                                     EEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEBBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQ
                                                                                     QAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEBAAAEEEEAAAQQQQAABBBBA
                                                                                     AAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAABBBBAAAEEEEAAAQQQQAAB
                                                                                     AQQQwKsN/kuBd3TTxuw+4cBEEvglGrK70cGKGvBZsby4P/0nHHCXXgNiFl9/2EDVv9PB17YufE9n
                                                                                     cgQubMnNVp79xoPKnxtWHOzw0VUH/tMB3NKTYUWnNgdw2BC6U2Wfty4seTsxBGba0+rIqdGcNWEc
                                                                                     rl0CD5UDXDrYY2Xp67wfsCaAx3Gbi2Fu0aalcX/B0rd2gNN2SFuM304AgY0BPFS23J1TmT/VNlwS
                                                                                     wFb0TuEvzeF7Ddsu/PmjAAIXLIHPXXlJbckhPKVJcfV1h36hlkNjmZuj9xK27yF+p/Hv2AkgcGEA
                                                                                     z415HtsSW5NjeFqI4HDJEjjHL4fv+7idD/Auxe9OAIEbBPDcmKexM8+hOzmEOYIXLYGHxsHEyW+q
                                                                                     8HMjfq8pgF0jgIIIv3fwysyqMw5b5858/bF9C92ZOlSbBBcvjPSVv7xrHEgrfveN+N2PP3+fJkDx
                                                                                     A5YimIeuqTffxgh+DRNhK4KLy+ClJXDrQOKSd4pcjN9zJYB5+hM/oBam2uAVu/M1TILfytvzg3NT
                                                                                     4KolcPwDp8paPK7H92HyO45fe/yxPYxx3I9bF75P+IClEE7xOoa2TAF8GsP397h/Gr8WrxLHdpVW
                                                                                     CPvG8rdW4VjiPsUvXq5+StPfvrTP/wG0hrCh0p64FP6WAhgnwOPMBDgsLYFb5/+m+/vi+bxTKPQU
                                                                                     v3z11/k/YO0SuJT63SevKYJPYXsOU+DSecBmhKaPd2HZuh9jdjfG7T4sc6f9tE2TX2v6Ez1gywSY
                                                                                     p8B4PjDegjft8z2CMYSnHNtWALsUwH0I232I4UP6uA9bvvdP/IBLIpjvQY5Ph7yUt/cD5qvBtaXw
                                                                                     MLcMzRNbjGCcBu/CUvcuTX61pa8AAluXxEtPoeVHcPOzwrV3jClLAcxT4K4Rwj683osf8EERPIbY
                                                                                     HRrhW7whuhWmuQjWYlj7uvv+gGvjV8r82+61orfqaZC5KHUphLvKvhU9j70Bt4hfKe03Yl7zZqml
                                                                                     bLgK3JoCS6mfG6y9bi17xQ+4JoKl1N+NvvXu0KUsPAu8FKVuZiLsGp8rlr7AOy2FSyVwQ+NzZS5+
                                                                                     W+LUrdzP/UwhBC6dAJeCWNZOfZdGqVuYDgUP+MggDiuWzDcL4Jo/I3rAZ02Hq8N3y2CJHvArxhAA
                                                                                     AJL/CzAAVzO9MLNesgEAAAAASUVORK5CYII="
                transform="matrix(1 0 0 1 -2.1111 200.7407)"
              ></image>
              <g>
                <rect
                  x="5.9"
                  y="208.8"
                  className="st9"
                  width={290}
                  height={180}
                />
              </g>
            </g>
            <rect
              x="10.9"
              y="213.8"
              className="st16"
              width={280}
              height={170}
            />
          </g>
          <g className="st0">
            <g className="st4">
              <g>
                <path
                  className="st17"
                  d="M237.4,264c0,5.8-4.7,10.4-10.4,10.4H74.4c-5.8,0-10.4-4.7-10.4-10.4v-20.9c0-5.8,4.7-10.4,10.4-10.4
                          H227c5.8,0,10.4,4.7,10.4,10.4V264z"
                />
                <path
                  className="st18"
                  d="M233.7,262.4c0,4.9-4.5,8.9-10,8.9h-146c-5.5,0-10-4-10-8.9v-17.8c0-4.9,4.5-8.9,10-8.9h146
                          c5.5,0,10,4,10,8.9V262.4z"
                />
              </g>
              <rect
                x="70.6"
                y="242.6"
                className="st19"
                width="160.1"
                height={27}
              />
              <text
                transform="matrix(1 0 0 1 85.4612 263.0171)"
                className="st9 st20 st21"
              >
                404 error
              </text>
            </g>
            <rect
              x="44.7"
              y="322.3"
              className="st22"
              width="212.1"
              height="34.4"
            />
            <text transform="matrix(1 0 0 1 47.2058 329.1734)" className="st4">
              <tspan x={0} y={0} className="st23 st24 st25">
                Lorem ipsum dolor sit amet, consectetur adipiscing{" "}
              </tspan>
              <tspan x="0.6" y="11.7" className="st23 st24 st25">
                elit, sed do eiusmod tempor incididunt ut labore et{" "}
              </tspan>
              <tspan x="6.2" y="23.3" className="st23 st24 st25">
                dolore magna aliqua. Ut enim ad minim veniam.
              </tspan>
            </text>
            <g className="st4">
              <path
                className="st10"
                d="M277,301.4c0,4.3-3.5,7.8-7.8,7.8H32.1c-4.3,0-7.8-3.5-7.8-7.8v-6.5c0-4.3,3.5-7.8,7.8-7.8h237.1
                        c4.3,0,7.8,3.5,7.8,7.8V301.4z"
              />
              <rect
                x="34.4"
                y={292}
                className="st19"
                width="232.6"
                height="18.4"
              />
              <text
                transform="matrix(1 0 0 1 45.1999 303.7364)"
                className="st9 st26 st27"
              >
                sorry, the page not found
              </text>
            </g>
            <rect
              x={93}
              y="363.5"
              className="st22"
              width="115.4"
              height="12.3"
            />
            <text
              transform="matrix(1 0 0 1 102.8845 371.104)"
              className="st28 st29 st30"
              style={{ textDecoration: "underline" }}
            >
              click to homepage
            </text>
          </g>
        </g>
        <g>
          <g>
            <path
              className="st12"
              d="M66.2,216.2c-2,2.1-3.9,4.2-5.8,6.2c-0.6,0.6-1.2,1.2-1.8,1.8c-1.7,1.5-3.5,1.2-4.4-0.9
                      c-1.4-3.1-2.6-6.3-3.9-9.4c-0.1-0.4-0.3-0.7-0.5-1.3c-1.1,0.6-2.1,1.2-3.1,1.8c-2.3,1.3-4.6,2.6-6.9,3.8
                      c-2.5,1.2-4.1,0.1-4.1-2.7c0-6.1,1.5-11.8,5.4-16.6c3.5-4.3,8.2-6.5,13.7-6.7c4.6-0.2,9.2,0.2,13.6,1.9
                      c7.4,2.8,11.6,9.3,11.2,17.2c-0.2,4-1.4,7.7-3.1,11.2c-0.8,1.6-2.7,2-4.1,0.6c-1.8-1.8-3.4-3.8-5.1-5.7
                      C66.8,217,66.5,216.7,66.2,216.2z"
            />
            <path
              className="st13"
              d="M68.3,194c-2.9-1.1-5.8-1.6-8.8-1.8c10.3,5.8,14.3,19.8,16,31.3c0.3-0.2,0.6-0.6,0.9-1
                      c1.7-3.5,2.9-7.2,3.1-11.2C79.8,203.3,75.6,196.8,68.3,194z"
            />
            <g>
              <g>
                <path
                  className="st7"
                  d="M37.6,213.7c0,0,0.1,0.1,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.9c0.1,0.4,0.1,0.8,0.1,1.2c0,0.4-0.1,0.8-0.3,1.2
                         c-0.1,0.4-0.3,0.7-0.5,1c-0.2,0.3-0.4,0.6-0.7,0.8c-0.2,0.2-0.5,0.5-0.7,0.6c-0.2,0.1-0.4,0.2-0.4,0.2s-0.1-0.1-0.2-0.3
                         c-0.1-0.2-0.3-0.5-0.4-0.9c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0.1-0.9,0.2-1.3c0.3-0.8,0.8-1.5,1.4-1.9c0.3-0.2,0.6-0.4,0.8-0.5
                         C37.4,213.8,37.6,213.7,37.6,213.7z"
                />
              </g>
              <g>
                <path
                  className="st7"
                  d="M56.6,220.4c0,0,0.1,0.1,0.2,0.3c0.1,0.2,0.3,0.5,0.4,0.8c0.1,0.3,0.2,0.7,0.2,1.1c0,0.4,0,0.7,0,1.1
                         c-0.1,0.4-0.1,0.7-0.3,1.1c-0.2,0.3-0.3,0.7-0.5,0.9c-0.2,0.3-0.4,0.5-0.6,0.7c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.3
                         c-0.2-0.2-0.4-0.4-0.5-0.8c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.9,0-1.3c0.1-0.4,0.2-0.8,0.4-1.2c0.2-0.4,0.4-0.7,0.7-0.9
                         c0.3-0.2,0.5-0.4,0.7-0.6C56.4,220.5,56.6,220.4,56.6,220.4z"
                />
              </g>
              <g>
                <path
                  className="st7"
                  d="M73.6,219.7c0,0,0.2,0.1,0.4,0.2c0.2,0.1,0.5,0.3,0.9,0.5c0.2,0.1,0.3,0.3,0.5,0.4
                         c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.5,0.1,1,0.1,1.5c0,0.5-0.2,0.9-0.3,1.3
                         c-0.2,0.4-0.4,0.7-0.5,0.9c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.3c-0.2-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.4-0.7-0.6-1
                         c-0.2-0.4-0.3-0.8-0.4-1.2c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.4,0.1-0.7,0.2-1
                         C73.5,219.9,73.6,219.7,73.6,219.7z"
                />
              </g>
            </g>
            <g className="st14">
              <g>
                <path
                  className="st15"
                  d="M48.1,197c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4
                          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.4,0c0.5,0,1-0.2,1.5-0.6
                          c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.3,0.6-0.4,0.8-0.2c0.2,0.1,0.3,0.3,0.3,0.5
                          l0,0.1l0,0.6c0,0.2,0,0.4,0.1,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.4l0.2,0.2
                          c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0L54,201l0.2-0.1c0.1,0,0.3-0.2,0.4-0.3
                          c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.6
                          l0-0.6l1.9,0l0,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.3,0.4,0.5
                          c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.1,0.3,0.3,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.5,0.1l0.3,0l0.3,0
                          c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0.1-0.3,0.1-0.6l0-0.6
                          l0-0.1c0-0.5,0.4-0.8,0.8-0.9c0.3,0,0.5,0.1,0.7,0.3c0.7,0.9,1.2,1.8,1.9,2.5c0.2,0.2,0.3,0.4,0.5,0.6
                          c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.7-0.1
                          c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.2,0.2-0.4,0.3-0.6c0-0.2,0.1-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.7
                          c-0.1-0.2-0.3-0.4-0.4-0.7c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9
                          c-0.1,0.3-0.3,0.5-0.6,0.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.3,0-0.4,0l-0.2,0l0,0
                          c-0.1,0-0.1,0-0.1,0l-0.1,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.5-0.3-0.7-0.5
                          c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.4-0.8-0.8-1.1-1.2l-1-1.2l1.5-0.7l0.1,0.9c0,0.3-0.1,0.6-0.1,0.9c0,0.3-0.2,0.6-0.3,0.9
                          c-0.1,0.3-0.4,0.6-0.6,0.9c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.1-0.7,0.2-1,0.2l-0.5,0l-0.5-0.1c-0.3,0-0.6-0.1-0.9-0.2
                          c-0.3-0.1-0.6-0.2-0.8-0.4c-0.3-0.1-0.5-0.3-0.8-0.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.6-0.7
                          c-0.2-0.2-0.3-0.5-0.4-0.8c-0.1-0.3-0.3-0.6-0.3-0.9c-0.1-0.3-0.2-0.6-0.2-0.9l0-0.9c0-0.5,0.4-1,0.9-1c0.5,0,1,0.4,1,0.9
                          c0,0,0,0.1,0,0.1l0,0l-0.1,0.8c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.3,0.8
                          c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.5,0.4-0.7,0.5l-0.4,0.2c-0.2,0-0.3,0.1-0.5,0.1
                          c-0.3,0.1-0.7,0-1,0c-0.3-0.1-0.7-0.2-0.9-0.4l-0.4-0.3l-0.3-0.3c-0.2-0.2-0.3-0.5-0.5-0.8c-0.2-0.3-0.2-0.5-0.3-0.8
                          c-0.1-0.3-0.1-0.5-0.1-0.8c-0.1-0.5,0-1,0.1-1.5l1.1,0.4c-0.2,0.3-0.4,0.6-0.7,0.8c-0.1,0.1-0.3,0.2-0.4,0.3
                          c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.2-0.6,0.3-1,0.4c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.1
                          c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.2,0-0.4,0-0.5c0.1-0.3,0.3-0.6,0.5-0.9
                          C47.7,197.4,47.9,197.2,48.1,197z"
                />
              </g>
              <g>
                <path
                  className="st15"
                  d="M55.1,206.7c0,0.4,0,0.8,0,1.1c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5
                          c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.3,0.1,0.4,0c0.1,0,0.3,0,0.4-0.1
                          c0.3-0.1,0.5-0.2,0.8-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.4,0.6-0.5,1-0.3
                          c0.2,0.1,0.4,0.4,0.4,0.6l0,0.1l0,0.6c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.5l0.1,0.2l0.2,0.2
                          c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.2
                          c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.5
                          c0.1-0.2,0.2-0.4,0.2-0.6l0,0c0.2-0.5,0.9-0.7,1.4-0.5c0.2,0.1,0.4,0.3,0.5,0.5c0.2,0.4,0.3,0.8,0.5,1.1
                          c0.1,0.2,0.2,0.4,0.3,0.5l0.3,0.5l0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.1
                          c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.4l0.2-0.5c0.1-0.4,0.2-0.7,0.2-1.1c0.1-0.4,0-0.8,0-1.2
                          l0-0.1c0-0.4,0.4-0.7,0.8-0.7c0.3,0,0.6,0.3,0.7,0.6c0.1,0.3,0.1,0.7,0.2,1c0.1,0.2,0.1,0.3,0.1,0.5l0.2,0.5
                          c0.2,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.4,0.5,0.7,0.6c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                          c0.1,0,0.1-0.1,0.2-0.1l0.1-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.4,0-0.8,0-1.1
                          c0.1,0.4,0.2,0.7,0.3,1.1c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4,0,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.1,0.2-0.2,0.3
                          c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
                          c-0.2-0.1-0.4-0.3-0.5-0.4c-0.3-0.3-0.6-0.6-0.8-1l-0.3-0.6c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.4-0.2-0.8-0.4-1.2l1.5-0.2
                          c0,0.5,0.1,0.9,0.1,1.4c0,0.5,0,1-0.1,1.5c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.2,0.2-0.4,0.5-0.7,0.7
                          c-0.3,0.2-0.7,0.3-1,0.3c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.5-0.3-0.8-0.4c-0.2-0.2-0.4-0.4-0.6-0.5
                          l-0.5-0.6c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.4-0.4-0.9-0.6-1.3l1.9,0l-0.3,0.7
                          c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.3,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.6,0.5
                          c-0.2,0.2-0.5,0.3-0.7,0.4c-0.3,0.1-0.5,0.3-0.8,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.9-0.4
                          c-0.3-0.2-0.6-0.4-0.7-0.6l-0.3-0.4l-0.2-0.4c-0.2-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.3-0.1-0.5,0-0.8l0.1-0.8
                          l1.4,0.4c-0.2,0.3-0.4,0.7-0.7,1c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.3,0.3-0.5,0.4c-0.4,0.2-0.8,0.4-1.2,0.5
                          c-0.2,0.1-0.5,0-0.7,0c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.4-0.3-0.6
                          c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2,0-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6C54.9,207.5,55.1,207.1,55.1,206.7z"
                />
              </g>
              <g>
                <path
                  className="st15"
                  d="M41.9,204c-0.3,0.9-0.7,1.8-0.8,2.6c-0.1,0.4,0,0.8,0.1,1c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0
                          c0.3,0,0.6-0.1,1-0.3c0.3-0.2,0.7-0.4,1-0.7c0.6-0.5,1.2-1.2,1.7-1.9c0.3-0.5,0.9-0.6,1.4-0.2c0.2,0.2,0.4,0.4,0.4,0.6l0,0.1
                          c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4
                          c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0.1,0.3,0,0.4,0
                          c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2l0.2-0.2l0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.3,0.2-0.7,0.3-1.2l0.1,0.6
                          c0.1,0.2,0,0.4,0,0.6l0,0.3l-0.1,0.3l-0.1,0.3l-0.2,0.3c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.5,0.3-0.7,0.4
                          c-0.3,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.5,0-0.8-0.1c-0.5-0.1-1-0.3-1.4-0.6c-0.2-0.1-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.5-0.5
                          c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.7L47,206c-0.6,0.9-1.3,1.7-2.2,2.3
                          c-0.4,0.3-0.9,0.6-1.4,0.8c-0.5,0.2-1.1,0.4-1.8,0.3c-0.3,0-0.7-0.1-1-0.4c-0.3-0.2-0.6-0.6-0.7-0.9c-0.1-0.4-0.1-0.7,0-1
                          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.5,0.5-0.9,0.8-1.3C41.2,204.7,41.5,204.3,41.9,204z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st12"
              d="M259.4,217.4c-1.8,1.9-3.5,3.8-5.3,5.6c-0.6,0.7-1.3,1.3-2,1.9c-1.4,1.3-3,1.2-4.1-0.4
                      c-1.4-2-2.6-4-3.9-6.1c-0.7-1.2-1.4-2.5-2.1-3.8c-1.8,1.1-3.5,2.2-5.3,3.1c-1.8,0.9-3.7,1.7-5.6,2.4c-2.3,0.8-3.5-0.1-3.8-2.4
                      c-1.2-9.5,2.9-18.3,10.5-22.5c3.3-1.8,6.8-2.5,10.5-2.4c4.8,0.1,9.4,0.9,13.7,2.9c7.4,3.5,11.3,10.2,10.8,18.4
                      c-0.2,3.2-1,6.2-2.2,9.2c-0.9,2.2-2.7,2.7-4.5,1.2c-2-1.8-3.8-3.8-5.7-5.8C260.1,218.3,259.8,217.9,259.4,217.4z"
            />
            <path
              className="st13"
              d="M262.1,195.8c-4-1.9-8.3-2.6-12.6-2.9c5.8,2.2,9,8.1,11.9,13.9c2.2,4.6,4.8,9,6.4,13.8
                      c0.5,1.5,0.9,3,1.2,4.5c0.7-0.3,1.3-0.9,1.7-1.9c1.2-2.9,2-6,2.2-9.2C273.4,205.9,269.5,199.3,262.1,195.8z"
            />
            <g>
              <g>
                <path
                  className="st7"
                  d="M230.6,215.7c0,0,0.1,0.2,0.1,0.4c0.1,0.2,0.2,0.6,0.2,1c0,0.4,0,0.8,0,1.2c0,0.4-0.1,0.8-0.2,1.2
                         c-0.3,0.8-0.8,1.6-1.3,2.1c-0.3,0.3-0.6,0.5-0.8,0.6c-0.2,0.1-0.4,0.2-0.4,0.2s-0.1-0.1-0.2-0.4c-0.1-0.2-0.2-0.6-0.3-0.9
                         c-0.2-0.7-0.1-1.7,0.1-2.6c0.2-0.4,0.4-0.9,0.6-1.3c0.2-0.4,0.6-0.7,0.9-0.9c0.3-0.2,0.6-0.4,0.8-0.5
                         C230.4,215.8,230.6,215.7,230.6,215.7z"
                />
              </g>
              <g>
                <path
                  className="st7"
                  d="M249.9,221.4c0,0,0.1,0.1,0.3,0.3c0.2,0.2,0.4,0.5,0.5,0.8c0.4,0.7,0.6,1.6,0.5,2.4
                         c0,0.9-0.3,1.7-0.7,2.4c-0.2,0.3-0.4,0.6-0.6,0.8c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.3c-0.2-0.2-0.4-0.5-0.5-0.8
                         c-0.4-0.7-0.6-1.6-0.5-2.5c0-0.9,0.3-1.7,0.7-2.4c0.2-0.3,0.4-0.6,0.6-0.8C249.8,221.5,249.9,221.4,249.9,221.4z"
                />
              </g>
              <g>
                <path
                  className="st7"
                  d="M267.9,220.7c0,0,0.1,0.1,0.3,0.3c0.2,0.2,0.4,0.5,0.6,0.8c0.4,0.7,0.8,1.6,0.8,2.6c0,1-0.2,2-0.6,2.7
                         c-0.2,0.4-0.4,0.7-0.6,0.9c-0.2,0.2-0.3,0.3-0.3,0.3s-0.1-0.1-0.3-0.3c-0.2-0.2-0.4-0.5-0.6-0.8c-0.4-0.7-0.6-1.6-0.7-2.5
                         c0-0.2,0-0.5,0-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.3-0.7,0.5-0.9
                         C267.8,220.9,267.9,220.7,267.9,220.7z"
                />
              </g>
            </g>
            <g className="st14">
              <g>
                <path
                  className="st15"
                  d="M242.9,195.7c-0.2,0.3-0.4,0.7-0.6,1l-0.3,0.5l-0.2,0.5c-0.1,0.2-0.1,0.4-0.1,0.5
                          c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.3,0,0.5c0.1,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0
                          c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3l0.4-0.3l0.4-0.4
                          c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.3,0.7-0.4,1-0.1c0.2,0.1,0.3,0.3,0.3,0.5l0,0.1l0,0.6c0,0.2,0,0.4,0.1,0.6
                          c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.2
                          c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.3-0.3l0.2-0.2l0.1-0.2
                          c0.1-0.1,0.2-0.3,0.2-0.4l0.1-0.2l0-0.3c0-0.2,0.1-0.4,0.1-0.6l0-0.6c0-0.6,0.5-1,1-1c0.4,0,0.8,0.3,0.9,0.7l0,0
                          c0.1,0.2,0.1,0.4,0.2,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.3,0.3,0.4
                          c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.1,0
                          c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0-0.5
                          c0-0.4,0-0.8,0-1.2l0-0.2c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.7,0.2,0.8,0.5c0.2,0.7,0.6,1.4,1.1,2c0.5,0.6,1.2,1,1.9,1.3
                          c0.7,0.3,1.6,0.3,2-0.2c0.2-0.3,0.1-0.7,0-1.1c-0.1-0.4-0.3-0.8-0.5-1.2c0.3,0.3,0.6,0.7,0.8,1.1c0.2,0.4,0.4,0.9,0.2,1.4
                          c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.2-0.7,0.3c-0.5,0.1-1,0.1-1.5,0c-1-0.2-1.8-0.7-2.6-1.3c-0.7-0.6-1.3-1.5-1.7-2.4
                          l1.5-0.4c0.1,0.5,0.2,0.9,0.2,1.4c0,0.3,0,0.5,0,0.8c-0.1,0.3-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.6-0.4,0.8
                          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.7,0.3-1,0.3c-0.3,0-0.7,0-0.9-0.1c-0.3-0.1-0.6-0.2-0.8-0.3
                          c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.6
                          c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.3-0.7l-0.2-0.7l2-0.3l0,0.8c0,0.3-0.1,0.5-0.1,0.8l-0.1,0.4l-0.2,0.4
                          c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.1-0.2,0.3-0.3,0.4l-0.3,0.3c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.2-0.6,0.2-0.9,0.3
                          c-0.3,0-0.7,0.1-1,0c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.4-0.4-0.6-0.7c-0.2-0.2-0.3-0.5-0.4-0.8
                          c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0-0.8l0-0.8l1.3,0.5c-0.3,0.3-0.5,0.6-0.9,0.9l-0.5,0.4
                          l-0.5,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0-0.5,0-0.8-0.1c-0.3-0.1-0.5-0.3-0.6-0.6
                          c-0.2-0.2-0.2-0.5-0.2-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.4,0.4-0.7,0.7-1
                          C242.3,196.3,242.6,196,242.9,195.7z"
                />
              </g>
              <g>
                <path
                  className="st15"
                  d="M234.9,203.2c-0.7,1-1.5,1.9-2.1,2.9c-0.1,0.2-0.3,0.5-0.3,0.8c-0.1,0.2-0.1,0.5-0.1,0.7
                          c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.2,0.2,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2,0
                          c0.2,0,0.4,0,0.6-0.1c0.4-0.1,0.8-0.4,1.2-0.8c0.8-0.7,1.5-1.7,2.2-2.6c0.3-0.4,0.9-0.5,1.3-0.2c0.2,0.2,0.3,0.4,0.4,0.7
                          l0,0.1l0,0.7c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5
                          c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                          c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.2,0.5-0.2l0.4-0.4l0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.5c0.3-0.3,0.5-0.7,0.7-1.1l0.1-0.1
                          c0.3-0.4,0.8-0.5,1.2-0.3c0.3,0.2,0.4,0.5,0.4,0.8c-0.1,0.9,0.1,1.8,0.5,2.6c0.4,0.8,1.1,1.5,1.9,2c0.8,0.5,1.8,0.7,2.4,0.1
                          c0.3-0.3,0.6-0.7,0.7-1.2c0.1-0.5,0.1-1,0.1-1.6c0.1,0.5,0.3,1,0.2,1.6c0,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.2,0.6-0.4,0.8
                          c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.1-0.6,0.2-0.9,0.2c-0.6,0-1.2-0.1-1.8-0.3c-0.5-0.2-1.1-0.5-1.5-0.9
                          c-0.4-0.4-0.8-0.8-1.2-1.4c-0.3-0.5-0.6-1.1-0.7-1.6c-0.2-0.6-0.2-1.2-0.2-1.8l1.7,0.4c-0.3,0.5-0.4,0.9-0.8,1.4
                          c-0.2,0.2-0.3,0.5-0.5,0.7l-0.6,0.6l-0.7,0.6c-0.3,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.2-1,0.2c-0.2,0-0.3,0-0.5,0
                          c-0.2,0-0.4,0-0.5-0.1c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.2-0.6-0.4-0.7-0.6c-0.2-0.2-0.4-0.5-0.5-0.7c-0.2-0.3-0.3-0.5-0.4-0.8
                          c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.5-0.1-0.8l0-0.8l1.7,0.6c-0.8,0.9-1.5,1.9-2.6,2.7
                          c-0.5,0.4-1.1,0.8-1.9,1c-0.4,0.1-0.8,0.1-1.2,0c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.4-0.3-0.6-0.5-0.8-0.9
                          c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4,0-0.6c0-0.4,0.2-0.7,0.3-1c0.2-0.3,0.3-0.6,0.5-0.8C233,204.7,233.9,204,234.9,203.2
                          z"
                />
              </g>
            </g>
          </g>
          <text
            opacity="0.6"
            x={15}
            y={257}
            fill="#000"
            fontFamily="Viga"
            fontSize={25}
          >
            There is no way Immma
            <tspan x={35} y={292}>
              let you pass through!
            </tspan>
            <tspan x={100} y={357} fill="#000" fontSize={65}>
              404
            </tspan>
            <tspan x={93} y="381.2" fill="#000" fontSize={15}>
              Access Forbidden.
            </tspan>
          </text>
          <text x={15} y={255} fill="#6BA184" fontFamily="Viga" fontSize={25}>
            There is no way Immma
            <tspan x={35} y={290}>
              let you pass through!
            </tspan>
            <tspan x={100} y={355} fill="#BF3E73" fontSize={65}>
              404
            </tspan>
            <tspan x={93} y={380} fill="#6BA184" fontSize={15}>
              Access Forbidden.
            </tspan>
          </text>
        </g>
      </svg>
    </StatusPage404>
  );
};

export default PageNotFound;

const StatusPage404 = styled.section`
  height: 100%;
  background-color: #f4f3f5;
  min-height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%236ba184' fill-opacity='0.21'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  svg {
    transform: scale(0.3, 0.3);
    margin-top: -45%;
  }
  .st0 {
    display: none;
  }
  .st1 {
    display: inline;
    fill: #e2e4fa;
  }
  .st2 {
    display: none;
    opacity: 0.5;
  }
  .st3 {
    display: inline;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #a8aaba;
  }
  .st4 {
    display: inline;
  }
  .st5 {
    fill: #a8aaba;
  }
  .st6 {
    opacity: 0.1;
  }
  .st7 {
    fill: #2d1c2d;
  }
  .st8 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #2d1c2d;
  }
  .st9 {
    fill: #ffffff;
  }
  .st10 {
    fill: #bf3e73;
  }
  .st11 {
    fill: #8f3157;
  }

  @keyframes TongueAnim {
    0% {
      transform: skewY(0deg);
    }
    50% {
      transform: skewY(10deg);
    }
    100% {
      transform: skewY(-10deg);
    }
  }

  .tongue {
    animation: TongueAnim 0.2s ease infinite;
    transform-origin: top right;
    transform-box: fill-box;
  }

  .st12 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #7ebe9b;
  }
  .st13 {
    opacity: 0.3;
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #5f8d75;
  }
  .st14 {
    opacity: 0.6;
  }
  .st15 {
    fill: #5f8d75;
  }
  .st16 {
    opacity: 0.6;
    fill: none;
    stroke: #7ebe9b;
    stroke-miterlimit: 10;
  }
  .st17 {
    fill: #e7b84d;
  }
  .st18 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 1.098;
    stroke-miterlimit: 10;
  }
  .st19 {
    fill: none;
  }
  .st20 {
    font-family: "Asap-Medium";
  }
  .st21 {
    font-size: 28.6973px;
  }
  .st22 {
    display: inline;
    fill: none;
  }
  .st23 {
    fill: #7091a4;
  }
  .st24 {
    font-family: "BloggerSans";
  }
  .st25 {
    font-size: 9.72px;
  }
  .st26 {
    font-family: "Asap-Regular";
  }
  .st27 {
    font-size: 16.524px;
  }
  .st28 {
    display: inline;
    fill: #7ebe9b;
  }
  .st29 {
    font-family: "Asap-Bold";
  }
  .st30 {
    font-size: 10.692px;
  }
  .st31 {
    fill: #9799a2;
  }
`;
