import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useStateContext } from "../Context/ContextProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
  },
});

/* 
email - superadmin.faep@airchains.technology 
password - 12345678
*/
const LoginPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { loginUserDetails, setLoginUserDetails } = useStateContext();

  const adminUser = async (e) => {
    let loginBtn = document.getElementById("signInBtn");
    // disableBUTTONS
    loginBtn.disabled = true;
    loginBtn.innerHTML = "Please Wait...";
    // loginBtn.style="background-color:#858585";
    loginBtn.style.backgroundColor = "#858585";

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          // ipaddress:ipaddress_value,
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );

    const data = await res.json();
    setLoginUserDetails(data);
    //enable BUTTONS
    loginBtn.disabled = false;
    loginBtn.innerHTML = "Sign In";
    // loginBtn.style="background-color:#003049";
    loginBtn.style.backgroundColor = "#003049";
    if (data.success) {
      if (data.userType != "OO") {
        localStorage.setItem("userDetails", JSON.stringify(data));
        localStorage.setItem("token", data.userHash);
        navigate("/");
      } else {
        swal(
          "Invalid Login, Use APP",
          "account seems to be officer's, please login through APP.",
          "warning"
        );
      }
    } else {
      swal(data.message, data.description, "warning");
    }
    // e.preventDefault();
  };

  useEffect(() => {}, []);

  return (
    <Section id="LoginPage">
      <main className="h-screen w-full">
        <div className="up-container h-full w-full flex flex-row items-center justify-center text-[#fef8ecff]">
          <div className="up-form text-black h-full w-5/12 flex flex-col justify-center items-center">
            <div className="logo w-6/12 mb-6">
              <img
                className="h-20"
                src="/assets/images/logos/Upplogo.png"
                alt=""
              />
            </div>
            <div className="up-form w-6/12">
              <div className="up-content text-4xl font-extrabold">Sign in</div>
              <ThemeProvider theme={theme}>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { marginTop: "1rem", width: "100%" },
                    marginTop: "3rem",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Email *"
                    variant="outlined"
                    size="medium"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <TextField
                    id="outlined-password-input"
                    label="Password *"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <div className="flex items-start mb-6">
                    <div className="flex items-center h-5">
                      <Checkbox defaultChecked id="remember" />
                    </div>
                    <label
                      htmlFor="remember"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                    >
                      Remember me
                    </label>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "7px",
                      backgroundColor: "#003049ff",
                    }}
                    id="signInBtn"
                    onClick={adminUser}
                  >
                    Sign in
                  </Button>
                </Box>
              </ThemeProvider>
            </div>
          </div>
          <div className="up-image-content relative h-full w-7/12 flex flex-col items-center justify-center gap-4">
            <div className="up-background absolute h-full w-full bg-[#003049ff]"></div>
            <div className="up-background-image-1 absolute h-full w-full overflow-hidden">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#669bbc42"
                  d="M45.2,-12.2C53.7,11.3,52.2,40.5,40.7,46.6C29.2,52.7,7.8,35.7,-8.7,21.5C-25.2,7.3,-36.7,-4.2,-34.4,-19.2C-32.2,-34.1,-16.1,-52.6,1.2,-53C18.4,-53.3,36.8,-35.6,45.2,-12.2Z"
                  transform="translate(10 50)"
                />
              </svg>
            </div>
            <div className="up-background-image-2 absolute h-full w-full overflow-hidden">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#669bbc42"
                  d="M71.5,-22.6C79.8,2.1,64.6,35.1,40.1,52.2C15.6,69.3,-18.3,70.6,-37.9,55.5C-57.6,40.5,-63,9.1,-54.3,-16.3C-45.6,-41.7,-22.8,-61.1,4.4,-62.6C31.6,-64,63.3,-47.4,71.5,-22.6Z"
                  transform="translate(180 130)"
                />
              </svg>
            </div>
            <div className="title w-8/12 text-5xl font-bold">
              <span className="block">Firozabad</span>
              <span className="block">Anti Encrochment Project</span>
            </div>
            <p className=" w-8/12">Firozabad Anti Encrochment Project is a project for controlling Encroachment and Grievances happening in firozabad city.  </p>
          </div>
        </div>
      </main>
    </Section>
  );
};

export default LoginPage;

const Section = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  main {
    .up-container {
      .up-form {
      }
      .up-image-content {
        .up-background {
          z-index: -10;
        }
        .up-background-image-1 {
          z-index: -9;
        }
        .up-background-image-2 {
          z-index: -8;
        }
        .title {
          font-family: "Josefin Sans", sans-serif;
        }
      }
    }
  }
`;
