import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GoVerified, GoUnverified } from "react-icons/go";
import { GiPirateFlag } from "react-icons/gi";
import { FaFlag } from "react-icons/fa";
import { styled as muiStyled, alpha } from "@mui/material/styles";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Header from "../Components/Header";
import { Card } from "../Components/Common/ReusableComponent";
import styled from "styled-components";
import { useStateContext } from "../Context/ContextProvider";
import { Link } from "react-router-dom";

const StyledMenu = muiStyled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const EncroachmentList = () => {
  /* Mui Menu Items codes and useStates -> Start */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchChoice, setSearchChoice] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  /* Mui Menu Items codes and useStates -> End*/

  const { encrListLoading, setEncrListLoading } = useStateContext();
  const [apiDataLoading, setApiDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getUsersListAPI = async () => {
    setEncrListLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}encr/admin/get`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );
    let encrData = await res.json();
    setEncrListLoading(false);
    setApiDataLoading(false);
    setData(encrData.encr);
  };

  useEffect(() => {
    getUsersListAPI();
  }, []);

  const userListDataFromAPI = data;

  const FilterDateFunction = (thisDate) => {
    const apiDate = new Date(thisDate);
    const MyDate = `${apiDate.getDate()}/${
      apiDate.getMonth() + 1
    }/${apiDate.getFullYear()}`;

    return MyDate;
  };

  const FilterSearchFunction = () => {
    if (apiDataLoading === false) {
      let userSectionSearchBar, filteredText, table, tr, td, i, txtValue;
      userSectionSearchBar = document.getElementById(
        "search-bar-of-users-section"
      );
      filteredText = searchText.toUpperCase();
      table = document.getElementById("user-list-table");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        switch (searchChoice) {
          case "name":
            td = tr[i].getElementsByTagName("td")[0];
            break;
          case "mobile number":
            td = tr[i].getElementsByTagName("td")[1];
            break;
          case "village":
            td = tr[i].getElementsByTagName("td")[2];
            break;
          case "date":
            td = tr[i].getElementsByTagName("td")[3];
            break;

          default:
            td = tr[i].getElementsByTagName("td")[0];
            break;
        }
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filteredText) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }
  };

  return (
    <EncroachmentListSection>
      <div className="up-title mt-5 w-11/12 mx-auto">
        <Header category="Dashboard" title="Encroachment List" />
      </div>
      <div className="up-cards w-full bg-[rgba(102,155,188,0.3)] py-10">
        <div className="up-card w-11/12 mx-auto">
          <div className="search-bar">
            <div className="relative flex flex-row items-center justify-between bg-gray-50 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search-bar-of-users-section"
                className="block p-4 pl-10 text-sm flex-1 text-gray-900 bg-transparent rounded-lg dark:placeholder-gray-400 dark:text-white outline-none"
                placeholder="Search Here..."
                onChange={(thisComponent) => {
                  setSearchText(thisComponent.target.value);
                }}
                // required
              />
              <div className="buttons-area flex flex-row justify-center items-center">
                <div className="search-col-selector">
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {`Search By ${
                      searchChoice == ""
                        ? setSearchChoice("name")
                        : searchChoice
                    }`}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("name");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Name
                    </MenuItem>
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("mobile number");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Mobile Number
                    </MenuItem>
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("village");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Village
                    </MenuItem>
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("date");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Date
                    </MenuItem>
                  </StyledMenu>
                </div>
                <div className="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">
                  <Button
                    variant="contained"
                    onClick={FilterSearchFunction()}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-section mt-4">
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table
                className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                id="user-list-table"
              >
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="py-3 px-4">
                      Name
                    </th>
                    <th scope="col" className="py-3 pr-4">
                      Mobile Number
                    </th>
                    <th scope="col" className="py-3 pr-4">
                      Village
                    </th>
                    <th scope="col" className="py-3 pr-4">
                      Date
                    </th>
                    <th scope="col" className="py-3 text-center pr-4">
                      Status
                    </th>
                    <th scope="col" className="py-3 text-center pr-4">
                      {/* last icon colum */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userListDataFromAPI.map((item) => (
                    <tr
                      key={item._id}
                      className="bg-white border-b w-full dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td
                        scope="row"
                        className="py-2 px-4 font-medium break-words w-1/12 text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.encroacher_name}
                      </td>
                      <td className="py-2 pr-4 text-12">
                        {item.encroacher_mobile}
                      </td>
                      <td className="py-2 pr-4 text-12">
                        {item.encroacher_village}
                      </td>
                      <td className="py-2 pr-4 text-12">
                        {
                          FilterDateFunction(item.create_date)
                          // ()=>{
                          //   let date = new Date(item.create_date);
                          //   return date.format("DD/MM/YYYY")
                          // }
                        }
                      </td>
                      <td className="py-2 pr-4 text-12">
                        {item.encr_flag ? (
                          <span className="text-green-600 flex justify-center">
                            <FaFlag />
                          </span>
                        ) : (
                          <span className="text-red-600 flex justify-center">
                            <FaFlag />
                          </span>
                        )}
                      </td>
                      <td className="flex items-center justify-center gap-2 py-2 pr-4 text-12 ">
                        <Link to={`/Encroachment-Detail/${item._id}`}>
                          <div className="my-1 font-medium text-blue-600 dark:text-blue-500 hover:underline">
                            <IconButton>
                              <span className="text-blue-600">
                                <FaInfoCircle />
                              </span>
                            </IconButton>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </EncroachmentListSection>
  );
};

export default EncroachmentList;

const EncroachmentListSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  /* overflow-x: hidden; */
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    .up-card {
      ${Card}
      background-color: #fff;
    }
  }
`;
