import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  createTheme,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FaHourglassEnd } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import Header from "../Components/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    upperLayerButton: {
      main: "#757780",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const MyProfile = () => {
  let navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const UserProfile = {
    id: 1,
    ProfileName: userDetails.userDisplayName,
    accessLevel: () => {
      if (userDetails.userType === "S") {
        return "Super-Admin";
      } else if (userDetails.userType === "OO") {
        return "Officer";
      } else if (userDetails.userType === "A") {
        return "Admin";
      } else if (userDetails.userType === "CO") {
        return "Circle Officer";
      } else if (userDetails.userType === "SP") {
        return "Superintendent of Police";
      } 
    },
    profilePicture: userDetails.userProfile,
  };

  /* changePassword Modal useStates : start*/
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const handleOpenChangePasswordModal = () => setChangePasswordModal(true);
  const handleCloseChangePasswordModal = () => setChangePasswordModal(false);

  const [newPassword, setNewPassword] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangeNewPassword = (prop) => (event) => {
    setNewPassword({ ...newPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setNewPassword({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [confirmPassword, setConfirmPassword] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangeConfirmNewPassword = (prop) => (event) => {
    setConfirmPassword({ ...confirmPassword, [prop]: event.target.value });
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPassword({
      ...confirmPassword,
      showPassword: !confirmPassword.showPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const [passwordLoading, setPasswordLoading] = useState(false);
  /* changePassword Modal useStates : end*/

  const changePassword = async () => {
    setPasswordLoading(true);
    if (newPassword.password != confirmPassword.password) {
      swal(
        "Validation Error",
        "New password and Confirm password is doesn't match",
        "warning"
      );
      setPasswordLoading(false);
    } else {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}admin/update/self/password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "192.168.1.2",
            auth: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            password: newPassword.password,
          }),
        }
      );

      const data = await res.json();
      setPasswordLoading(false);
      if (data.status === 200) {
        swal(data.message, data.description, "success");
        localStorage.clear();
        navigate("/LoginPage");
      } else {
        swal(data.message, data.description, "warning");
      }
    }
  };

  /* edit Details functions and useStates */

  const [editDetailsToggle, setEditDetailsToggle] = useState(true);
  const handleEDToggleEditable = () => {
    setEditDetailsToggle(false);
  };
  const handleEDToggleNonEditable = () => {
    setEditDetailsToggle(true);
  };

  const [saveDetailsAPILoading, setSaveDetailsAPILoading] = useState(false);

  const [userDetailsUS, setUserDetailsUS] = useState({
    firstName: UserProfile.ProfileName.split(/ (.*)/s)[0],
    lastName: UserProfile.ProfileName.split(/ (.*)/s)[1],
    email: userDetails.userEmail,
  });

  const handleUserDetailsUSChange = (change) => (event) => {
    setUserDetailsUS({
      ...userDetailsUS,
      [change]: event.target.value,
    });
  };

  const saveDetailsAPIFunction = async () => {
    setSaveDetailsAPILoading(true);
    const url = process.env.REACT_APP_API_URL;
    const detailsUpdateRes = await fetch(`${url}admin/update/self/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ipaddress: "192.168.1.2",
        auth: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        admin_name: `${userDetailsUS.firstName} ${userDetailsUS.lastName}`,
        email_address: userDetailsUS.email,
      }),
    });

    const updateDetailsResData = await detailsUpdateRes.json();
    setSaveDetailsAPILoading(false);
    handleEDToggleNonEditable();
    if (updateDetailsResData.status === 200) {
      swal(
        updateDetailsResData.message,
        updateDetailsResData.description,
        "success"
      );
      localStorage.clear();
      navigate("/LoginPage");
    } else {
      swal(
        updateDetailsResData.message,
        updateDetailsResData.description,
        "warning"
      );
    }
  };

  // useEffect(() => {
  //   console.log(userDetailsUS);
  // }, [userDetailsUS]);

  return (
    <MyProfileSection>
      <div className="up-title mt-5 w-11/12 mx-auto">
        <Header category="Dashboard" title="My Profile" />
      </div>
      <section className=" w-full">
        <div className="w-11/12 mx-auto py-4">
          <div className="row">
            <h1 className="font-semibold text-lg">Profile Settings</h1>
            <div className="profile mt-2 flex items-start justify-between">
              <div className="details flex items-start justify-start gap-6">
                <Avatar
                  alt={`${UserProfile.ProfileName}`}
                  src={`${UserProfile.profilePicture}`}
                  sx={{
                    height: 80,
                    width: 80,
                    fontSize: 40,
                  }}
                />
                <div className="profile-name flex flex-col items-start justify-start">
                  <h2 className="text-sm ">Profile Name</h2>
                  <div className="text-xl font-semibold">
                    {UserProfile.ProfileName}
                  </div>
                </div>
              </div>
              <div className="edit self-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleOpenChangePasswordModal}
                >
                  Change Password
                </Button>
              </div>
            </div>
          </div>
          <div className="row">
            <h1 className="font-semibold text-lg mt-4">
              Additional Information
            </h1>
            <div className="profile w-full mt-2 flex flex-col items-start justify-start">
              <div className="row flex w-8/12 items-center justify-start gap-6 mt-4">
                <TextField
                  label="First Name"
                  defaultValue={UserProfile.ProfileName.split(/ (.*)/s)[0]}
                  inputProps={{ readOnly: editDetailsToggle }}
                  onChange={handleUserDetailsUSChange("firstName")}
                />
                <TextField
                  label="Last Name"
                  defaultValue={UserProfile.ProfileName.split(/ (.*)/s)[1]}
                  inputProps={{ readOnly: editDetailsToggle }}
                  onChange={handleUserDetailsUSChange("lastName")}
                />
              </div>
              <div className="row flex w-8/12 items-center justify-between mt-4">
                <TextField
                  label="Email ID"
                  defaultValue={userDetails.userEmail}
                  sx={{
                    width: "100%",
                  }}
                  inputProps={{ readOnly: editDetailsToggle }}
                  onChange={handleUserDetailsUSChange("email")}
                />
              </div>
              <div className="row flex w-8/12 items-center justify-between mt-4">
                <TextField
                  label="Designation"
                  defaultValue={userDetails.userDesignation}
                  inputProps={{ readOnly: true }}
                  disabled={!editDetailsToggle}
                />
              </div>
              <div className="row flex w-4/12 items-center justify-between mt-4">
                <TextField
                  fullWidth
                  label="Access Level"
                  defaultValue={UserProfile.accessLevel()}
                  inputProps={{ readOnly: true }}
                  disabled={!editDetailsToggle}
                />
              </div>
            </div>
          </div>
          <div className="row mt-4 flex justify-end">
            {editDetailsToggle ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleEDToggleEditable}
              >
                Edit Details
              </Button>
            ) : (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   size="small"
              //   //   onClick={handleOpenChangePasswordModal}
              // >
              //   Save Details
              // </Button>
              <LoadingButton
                size="small"
                color="primary"
                onClick={saveDetailsAPIFunction}
                loading={saveDetailsAPILoading}
                loadingIndicator={<FaHourglassEnd size={20} />}
                variant="contained"
              >
                Save Details
              </LoadingButton>
            )}
          </div>
        </div>
      </section>
      <div className="modals">
        <ThemeProvider theme={theme}>
          <Modal
            open={changePasswordModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                <div className="row w-full mx-auto flex flex-col items-center justify-center gap-4">
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel htmlFor="password">New Password</InputLabel>
                    <Input
                      id="password"
                      variant="outlined"
                      type={newPassword.showPassword ? "text" : "password"}
                      value={newPassword.password}
                      onChange={handleChangeNewPassword("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {newPassword.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel htmlFor="confirmPassword">
                      Confirm Password
                    </InputLabel>
                    <Input
                      id="confirmPassword"
                      variant="outlined"
                      type={confirmPassword.showPassword ? "text" : "password"}
                      value={confirmPassword.password}
                      onChange={handleChangeConfirmNewPassword("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {confirmPassword.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>

                <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                  <Button
                    id="AP-closeModalBtn"
                    color="secondary"
                    variant="contained"
                    sx={{ borderRadius: "6px" }}
                    onClick={handleCloseChangePasswordModal}
                  >
                    Close Modal
                  </Button>
                  <LoadingButton
                    // size="small"
                    id="saveChangesEditProfileButton"
                    color="primary"
                    onClick={changePassword}
                    loading={passwordLoading}
                    loadingIndicator={<FaHourglassEnd size={20} />}
                    variant="contained"
                    sx={{
                      background: "#003049",
                      borderRadius: "6px",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </div>
              </div>
            </Box>
          </Modal>
        </ThemeProvider>
      </div>
    </MyProfileSection>
  );
};

export default MyProfile;

const MyProfileSection = styled.section`
  width: calc(100vw - 288px);
  min-height: calc(100vh - 4rem);
`;
