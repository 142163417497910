import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { Card } from "../Components/Common/ReusableComponent";
import "react-data-table-component-extensions/dist/index.css";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, IconButton, InputLabel, Tooltip } from "@mui/material";
import swal from "sweetalert";
import { BsCheckCircleFill, BsStopCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import Avatar from "@mui/material/Avatar";
import { IoInformationCircle } from "react-icons/io5";
import { MdDisabledVisible } from "react-icons/md";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const AdminProfile = () => {
  // const dataCon = useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // _______________________________________________________
  // station data get useStates -> start
  const [loading, setLoading] = useState(false);
  const [dataColumn, setDataColumn] = useState([]);
  // station data get useStates -> end
  // _______________________________________________________
  // admin data post useStates -> start
  const [admin_name, setAdmin_name] = useState();
  const [email_address, setEmail_address] = useState();
  const [admin_designation, setAdmin_designation] = useState();
  const [station_id, setStation_id] = useState("");
  // admin data post useStates -> end
  // _______________________________________________________
  // admin data get useStates -> start
  const [adminGetData, setAdminGetData] = useState([]);
  // admin data get useStates -> end
  // _______________________________________________________

  const [accessType, setAccessType] = React.useState("");

  const handleAccessChange = (event) => {
    setAccessType(event.target.value);
  };
  const [stationName, setStationName] = React.useState("");

  const handleStationChange = (event) => {
    setStationName(event.target.value);
    setStation_id(stationName);
  };
  // _______________________________________________________
  const getAdminData = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/admins`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );
    const allAdminJson = await res.json();
    setAdminGetData(allAdminJson.admins);
  };
  // _______________________________________________________
  const postAdminData = async (e) => {
    let addStationButton = document.getElementById("AP-addAdminBtn");
    let closeModalButton = document.getElementById("AP-closeModalBtn");

    addStationButton.disabled = true;
    addStationButton.innerHTML = "Please Wait...";
    addStationButton.style.backgroundColor = "#858585";
    closeModalButton.disabled = true;

    var sendData = {
      admin_name: admin_name,
      access_type: accessType,
      email_address: email_address,
      admin_designation: admin_designation,
      station_id: stationName,
      co_id : coName
    };
    const res = await fetch(`${process.env.REACT_APP_API_URL}admin/add/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ipaddress: "192.168.1.2",
        auth: localStorage.getItem("token"),
      },
      body: JSON.stringify(sendData),
    });

    const data = await res.json();
    addStationButton.disabled = false;
    addStationButton.innerHTML = "Add Admin";
    addStationButton.style.backgroundColor = "#003049";
    closeModalButton.disabled = false;

    if (data.success) {
      swal(data.message, data.description, "success");
      window.location.reload(1);
    } else {
      swal(data.message, data.description, "error");
    }
  };
  // _______________________________________________________
  const getStationData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_URL}station/get/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ipaddress: "192.168.1.2",
        auth: localStorage.getItem("token"),
      },
    });
    const allStationsJson = await res.json();
    setLoading(false);
    let fiData = [];
    let myStationElement = allStationsJson.stations;

    for (let i = 0; i < myStationElement.length; i++) {
      let upd = {
        station_name: myStationElement[i].station_name,
        // station_address: myStationElement[i].station_address,
        // station_flag: myStationElement[i].station_flag,
        _id: myStationElement[i]._id,
      };
      fiData.push(upd);
    }
    setDataColumn(fiData);
  };
  // _______________________________________________________

  const [coListData, setCoListData] = useState([]);
  const [coListLoading, setCoListLoading] = useState(false);
  const [coName, setCoName] = useState("");
  const getCOList = async () => {
    setCoListLoading(true);
    const coListAPI = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/coList`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );
    const coListAPIData = await coListAPI.json();
    setCoListLoading(false);

    let coLists = coListAPIData.data;
    setCoListData(coLists);
  };
  // _______________________________________________________

  const getDesignationNameFunc = (userType) => {
    switch (userType) {
      case "OO":
        return "Officer";
      case "A":
        return "Admin";
      case "CO":
        return "Circle Officer";
      case "SP":
        return "Superintendent Police";
      case "S":
        return "SuperAdmin";

      default:
        return "Officer";
    }
  };
  // _______________________________________________________
  /* get Designation Color Function */
  const getDesignationColorFunc = (userType) => {
    switch (userType) {
      case "OO":
        return "bg-[#454545]";
      case "A":
        return "bg-[#2196f3]";
      case "CO":
        return "bg-[#ff8800]";
      case "SP":
        return "bg-[#fb6f92]";
      case "S":
        return "bg-[#dc2f02]";
      default:
        return "bg-[#93C5FD]";
    }
  };

  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const userDetailsCheck = localStorage.getItem("userDetails");
    setUserDetails(JSON.parse(userDetailsCheck));
    getStationData();
    getAdminData();
    getCOList();
  }, []);

  return (
    <AdminProfileSection>
      <div className="up-title mt-5 w-11/12 mx-auto flex flex-row justify-between items-center ">
        <Header category="Dashboard" title="Admin Profile" />
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleOpen}
            color="primary"
            variant="contained"
            sx={{ background: "#003049", borderRadius: "6px" }}
          >
            Add Admin
          </Button>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                  <TextField
                    id="outlined-basic"
                    label="Admin Name"
                    variant="outlined"
                    sx={{
                      width: "50%",
                    }}
                    onChange={(e) => {
                      setAdmin_name(e.target.value);
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Admin Email"
                    type="email"
                    variant="outlined"
                    className="modal-numType-textfield"
                    sx={{
                      width: "50%",
                    }}
                    onChange={(e) => {
                      setEmail_address(e.target.value);
                    }}
                  />
                </div>
                <div className="row w-full mx-auto flex flex-row gap-4">
                  <FormControl fullWidth>
                    <InputLabel id="access-type-label">Access Type</InputLabel>
                    <Select
                      labelId="access-type-label"
                      id="simple-select"
                      value={accessType}
                      label="Access Type"
                      onChange={handleAccessChange}
                    >
                      <MenuItem value="A">Admin</MenuItem>
                      <MenuItem value="OO">Other Officer</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="station-name-label">
                      Station Name
                    </InputLabel>
                    <Select
                      labelId="station-name-label"
                      id="simple-select"
                      value={stationName}
                      label="Station Name"
                      onChange={handleStationChange}
                    >
                      {/* <MenuItem value={10}>Ten</MenuItem> */}
                      {dataColumn.map((item) => (
                        <MenuItem value={item._id} key={item._id}>
                          {item.station_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="row w-full mx-auto flex flex-row">
                  <TextField
                    id="outlined-multiline-static"
                    label="Admin Designation"
                    // multiline
                    // rows={4}
                    sx={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setAdmin_designation(e.target.value);
                    }}
                  />
                </div>
                <div
                  className={`row w-full mx-auto flex flex-row ${
                    userDetails.userType === "S" ? "block" : "hidden"
                  } `}
                >
                  <FormControl fullWidth>
                    <InputLabel id="station-name-label">
                      Circle Officer
                    </InputLabel>
                    <Select
                      labelId="station-name-label"
                      id="simple-select"
                      label="Circle Officer"
                      sx={{
                        width: "50%",
                      }}
                      // we want to add co list here
                      value={coName}
                      onChange={(event) => {
                        setCoName(event.target.value);
                      }}
                    >
                      {/* <MenuItem value={10}>Ten</MenuItem> */}
                      {coListData.map((item) => (
                        <MenuItem value={item.coID} key={item.coID}>
                          {item.coName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                  <Button
                    id="AP-closeModalBtn"
                    color="secondary"
                    variant="contained"
                    sx={{ borderRadius: "6px" }}
                    onClick={handleClose}
                  >
                    Close Modal
                  </Button>
                  <Button
                    id="AP-addAdminBtn"
                    color="primary"
                    variant="contained"
                    sx={{ background: "#003049", borderRadius: "6px" }}
                    onClick={postAdminData}
                  >
                    Add Admin
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </ThemeProvider>
      </div>
      <div className="up-cards w-full bg-[#f4f7fa] py-10">
        <div className="up-card w-11/12 mx-auto">
          <ThemeProvider theme={theme}>
            <div className="relative overflow-x-auto sm:rounded-sm">
              {/* table start */}

              <div className="table-body min-h-[500px] relative overflow-x-auto shadow-md sm:rounded-sm">
                <div className="row mb-4">
                  <h1 className="font-semibold">List of Admins</h1>
                  <p className="text-sm w-11/12 mt-2 text-gray-500">
                    These are the list of admins.
                  </p>
                </div>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-100 uppercase dark:bg-gray-50 bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Designation
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Station name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminGetData.map((e) => (
                      <tr
                        key={e.admin_id}
                        className="border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 "
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap flex flex-row items-center gap-4"
                        >
                          <span>
                            <Avatar
                              alt={`${e.admin_name}`}
                              src={`${e.profile_picture}`}
                            />
                          </span>
                          <span className="flex flex-col">
                            <span>{e.admin_name}</span>
                            <span
                              className={`badge ${getDesignationColorFunc(
                                e.access_type
                              )}  w-fit text-white rounded-md px-1 text-12`}
                            >
                              {getDesignationNameFunc(e.access_type)}
                            </span>
                          </span>
                        </th>
                        <td className="px-6 py-4">{e.admin_designation}</td>
                        <td className="px-6 py-4">{e.station_name}</td>

                        <td className="px-6 py-4">
                          {e.admin_flag ? (
                            <BsCheckCircleFill style={{ color: "green" }} />
                          ) : (
                            <BsStopCircleFill style={{ color: "red" }} />
                          )}
                        </td>
                        <td className="px-6 py-4 text-right" disabled>
                          {e.access_type === "SP" || e.access_type === "CO" ? (
                            <Link to={`#`}>
                              <Tooltip title="Disabled" placement="bottom">
                                <IconButton
                                  aria-label="fingerprint"
                                  color="primary"
                                >
                                  <MdDisabledVisible />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          ) : (
                            <Link to={`/admin/${e.admin_id}`}>
                              <Tooltip title="View" placement="bottom">
                                <IconButton
                                  aria-label="fingerprint"
                                  color="primary"
                                >
                                  <IoInformationCircle />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}

                          {/* <Link to={`/admin/${e.admin_id}`}>
                            <Tooltip title="View" placement="bottom">
                              <IconButton
                                aria-label="fingerprint"
                                color="primary"
                              >
                                <IoInformationCircle />
                              </IconButton>
                            </Tooltip>
                          </Link> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* table end */}
            </div>
            <BlockUI blocking={loading} />
          </ThemeProvider>
        </div>
      </div>
    </AdminProfileSection>
  );
};

export default AdminProfile;

const AdminProfileSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  /* overflow-x: hidden; */
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    .up-card {
      ${Card}
      background-color: #fff;
      border-radius: 5px;
      padding: 15px;
    }
  }
`;
