import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/system";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { RiFileExcel2Line } from "react-icons/ri";

const theme = createTheme({
  components: {
    // Name of the component
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#ccc",
        "&$checked": {
          // Controls checked color for the thumb
          color: "#f2ff00",
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#fff",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    custom: {
      main: "#818cf8",
    },
    greenCustom: {
      main: "#00d100",
    },
    redCustom: {
      main: "#d10000",
      background: "#d10000",
    },
  },
});

const initialState = {
  success: false,
  status: 500,
  message: "Warning",
  station_id: "Station Id doesn't match",
  station_name: "Unknown",
  station_address: "Unknown",
  station_contact: "Unknown",
  station_flag: false,
  station_admin: {},
};

const avatarsBackgroundColors = [
  "#180605",
  "#45818e",
  "#6aa84f",
  "#2986cc",
  "#e0a366",
  "#db3c30",
  "#674ea7",
  "#e066a3",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const grievanceColumns = [
  { field: "grievanceID", headerName: "ID", type: "number", width: 70 },
  { field: "grievanceName", headerName: "Name", width: 130 },
  { field: "grievanceTitle", headerName: "Grievance Title", width: 130 },
  { field: "grievanceMobile", headerName: "Mobile", width: 130 },
  {
    field: "grievanceVillage",
    headerName: "Village",
    width: 90,
  },
  {
    field: "View",
    renderCell: (cellValues) => {
      return (
        <Link to={`/Grievance-Officer-Allotment/${cellValues.row.grievanceID}`}>
          <ThemeProvider theme={theme}>
            <Button size="small" variant="contained" color="primary">
              View
            </Button>
          </ThemeProvider>
        </Link>
      );
    },
  },
];
const encroachmentColumns = [
  { field: "encroacherName", headerName: "Name", width: 200 },
  { field: "encroacherVillage", headerName: "Village", width: 130 },
  { field: "encroacherMobile", headerName: "Mobile", width: 130 },
  {
    field: "allotedOfficerName",
    headerName: "Alloted Officer",
    width: 200,
  },
  { field: "encrFlag", headerName: "Status", width: 60 },
  {
    field: "View",
    renderCell: (cellValues) => {
      return (
        <Link to={`/Encroachment-Detail/${cellValues.row._id}`}>
          <ThemeProvider theme={theme}>
            <Button size="small" variant="contained" color="primary">
              View
            </Button>
          </ThemeProvider>
        </Link>
      );
    },
  },
  // {`/Encroachment-Detail/${item._id}`}
];

const StationProfileOne = () => {
  const tableRef = useRef(null);
  const { station_id } = useParams();
  const [loading, setLoading] = useState();
  const [demoData, setDemoData] = useState(initialState);
  // _______________________________________________________
  const [pageSize, setPageSize] = useState(10);

  const [gandEDetailsLoading, setGandEDetailsLoading] = useState(false);
  const [grievanceRowData, setGrievanceRowData] = useState({});
  const [encroachmentRowData, setEncroachmentRowData] = useState({});
  // _______________________________________________________
  /* Toggle use states : start */
  const [checked, setChecked] = useState();
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const handleToggleFunction = async () => {
    if (demoData.station_flag) {
      // flag is true then make it false
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}station/update/block/${station_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "192.168.1.2",
            auth: localStorage.getItem("token"),
          },
        }
      );

      const data = await res.json();
      if (data.success) {
        swal(data.message, data.description, "success");
        window.location.reload();
      } else {
        swal(data.message, data.description, "error");
      }
    } else {
      // flag is false then make it true
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}station/update/unblock/${station_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "192.168.1.2",
            auth: localStorage.getItem("token"),
          },
        }
      );

      const data = await res.json();
      if (data.success) {
        swal(data.message, data.description, "success");
        window.location.reload();
      } else {
        swal(data.message, data.description, "error");
      }
    }
  };
  /* Toggle use states : end */
  // _______________________________________________________
  /* Officers get data use states : start */
  const [officersData, setOfficersData] = useState([]);
  /* Officers get data use states : end */
  // _______________________________________________________
  /* Modal useStates Start */
  const [openModal, setOpenModal] = React.useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  const [station_name, setStationName] = useState();
  const [station_contact, setStationContact] = useState();
  const [station_address, setStationAddress] = useState();
  const [stationSummaryLoading, setStationSummaryLoading] = useState(false);
  const [stationSummary, setStationSummary] = useState([]);
  const [stationOfficerData, setStationOfficerData] = useState([]);
  /* Modal useStates End */
  // _______________________________________________________
  /* Station Edit Post Function : Start */
  const postStationEditData = async (e) => {
    let editStationButton = document.getElementById("editStationButton");
    let closeModalButton = document.getElementById("closeModalButton");

    editStationButton.disabled = true;
    editStationButton.innerHTML = "Please Wait...";
    editStationButton.style.backgroundColor = "#858585";
    closeModalButton.disabled = true;

    var sendData = {
      station_name: station_name,
      station_contact: station_contact,
      station_address: station_address,
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}station/update/details/${station_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify(sendData),
      }
    );

    const data = await res.json();

    editStationButton.disabled = false;
    editStationButton.innerHTML = "Edit Station";
    editStationButton.style.backgroundColor = "#003049";
    closeModalButton.disabled = false;

    if (data.success) {
      swal(data.message, data.description, "success");
      window.location.reload(1);
    } else {
      swal(data.message, data.description, "error");
    }
  };
  /* Station Edit Post Function : End */
  // _______________________________________________________
  const adminUser = async (e) => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}station/get/station/${station_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    setLoading(false);
    setDemoData(data);
    if (data.success) {
    } else {
      swal(data.message, "station ID doesn't match ", "warning");
    }
  };

  const getOfficersList = async (e) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/admin/station/${station_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    const aAndOList = data.admins;
    setOfficersData(aAndOList);
  };

  const gandEDetails = async (e) => {
    setGandEDetailsLoading(true);

    const stationDetails = await fetch(
      `${process.env.REACT_APP_API_URL}station/get/stationDetails/${station_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const adminAPIData = await stationDetails.json();

    setGrievanceRowData(adminAPIData.grievanceDetails);
    setEncroachmentRowData(adminAPIData.encroachmentDetails);
    console.log(adminAPIData.encroachmentDetails);
    setGandEDetailsLoading(false);
  };

  const summaryData = async () => {
    setStationSummaryLoading(true);
    const statusRes = await fetch(
      `${process.env.REACT_APP_API_URL}status/stationGrievanceSummary`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          stationID: station_id,
        }),
      }
    );

    const data = await statusRes.json();
    setStationSummary(data.data);

    const officerRes = await fetch(
      `${process.env.REACT_APP_API_URL}status/stationOfficerSummary`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          stationID: station_id,
        }),
      }
    );

    const officerListData = await officerRes.json();
    console.log(officerListData.data);
    setStationOfficerData(officerListData.data);
    setStationSummaryLoading(false);
  };

  useEffect(() => {
    gandEDetails();
    adminUser();
    getOfficersList();
    summaryData();
  }, []);

  return (
    <StationProfileOneSection>
      <BlockUI blocking={loading} />
      <div className="">
        {demoData.success ? (
          <>
            <main className="p-8 w-full flex flex-col gap-8">
              <div className="row w-full gap-5 bg-[#111827] text-white p-4 py-8 rounded-md flex flex-row justify-between">
                <div className="station-details flex flex-row items-center justify-center gap-5">
                  <Avatar
                    alt={`${demoData.station_name}}`}
                    src={`#`}
                    sx={{
                      fontSize: "40px",
                      height: "90px",
                      width: "90px",
                      border: "4px solid white",
                    }}
                  />
                  <div className="details flex flex-col gap-4">
                    <div className="ut flex flex-col">
                      <span>{demoData.station_name}</span>
                      <span className="text-sm text-12 text-gray-300">
                        Station Name
                      </span>
                    </div>
                    <div className="bt flex flex-row gap-8">
                      <div className="t1 flex flex-col">
                        <span>+91 {demoData.station_contact}</span>
                        <span className="text-sm text-12 text-gray-300">
                          Station Contact
                        </span>
                      </div>
                      <div className="t2 flex flex-col">
                        <span>
                          {Object.keys(demoData.station_admin).length === 0
                            ? `---`
                            : demoData.station_admin.admin_name}
                        </span>
                        <span className="text-sm text-12 text-gray-300">
                          Station Admin
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edit-station flex flex-col items-start justify-between">
                  <div className="btns h-full flex flex-col items-start justify-between">
                    <ThemeProvider theme={theme}>
                      <div className="toggle-btn self-end">
                        <Switch
                          // checked={demoData.station_flag ? checked : ""}
                          checked={demoData.station_flag}
                          onChange={handleToggle}
                          color="greenCustom"
                          sx={
                            demoData.station_flag
                              ? {}
                              : {
                                  ".MuiSwitch-thumb ": {
                                    backgroundColor: "red",
                                  },
                                  ".MuiSwitch-track": {
                                    backgroundColor: "red",
                                  },
                                }
                          }
                          // color={checked ? "greenCustom" : "redCustom"}
                          inputProps={{ "aria-label": "controlled" }}
                          onClick={handleToggleFunction}
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="custom"
                        onClick={handleModalOpen}
                      >
                        Edit Station
                      </Button>
                      <Modal
                        open={openModal}
                        // onClose={handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                            <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                              <TextField
                                id="outlined-basic"
                                label="Station Name"
                                variant="outlined"
                                sx={{
                                  width: "50%",
                                }}
                                onChange={(e) => {
                                  setStationName(e.target.value);
                                }}
                                defaultValue={demoData.station_name}
                              />
                              <TextField
                                id="outlined-basic"
                                label="Station Contact"
                                type="number"
                                variant="outlined"
                                className="modal-numType-textfield"
                                sx={{
                                  width: "50%",
                                }}
                                onChange={(e) => {
                                  setStationContact(e.target.value);
                                }}
                                defaultValue={demoData.station_contact}
                              />
                            </div>
                            <div className="row w-full mx-auto flex flex-row">
                              <TextField
                                id="outlined-multiline-static"
                                label="Station Address"
                                multiline
                                rows={4}
                                sx={{
                                  width: "100%",
                                }}
                                onChange={(e) => {
                                  setStationAddress(e.target.value);
                                }}
                                defaultValue={demoData.station_address}
                                // defaultValue="Default Value"
                              />
                            </div>
                            <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                              <Button
                                id="closeModalButton"
                                color="secondary"
                                variant="contained"
                                sx={{ borderRadius: "6px" }}
                                onClick={handleModalClose}
                              >
                                Close Modal
                              </Button>
                              <Button
                                id="editStationButton"
                                color="primary"
                                variant="contained"
                                sx={{
                                  background: "#003049",
                                  borderRadius: "6px",
                                }}
                                onClick={postStationEditData}
                              >
                                Edit Station
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </Modal>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
              <div className="row w-full flex flex-row items-start justify-between gap-5 bg-white p-4">
                <div className="w-full flex items-center justify-between gap-4 ">
                  <div className="card bg-[#F4F7FA] w-44 rounded-md p-4">
                    <div className="heading">Total</div>
                    <div className="status mt-2 text-left text-4xl font-bold">
                      {stationSummary.totalApplication
                        ? stationSummary.totalApplication
                        : "0"}
                    </div>
                  </div>
                  <div className="card bg-[#F4F7FA] w-44 rounded-md p-4">
                    <div className="heading">Assigned</div>
                    <div className="status mt-2 text-left text-4xl font-bold">
                      {stationSummary.assignedApplication
                        ? stationSummary.assignedApplication
                        : "0"}
                    </div>
                  </div>
                  <div className="card bg-[#F4F7FA] w-44 rounded-md p-4">
                    <div className="heading">Non-Assigned </div>
                    <div className="status mt-2 text-left text-4xl font-bold">
                      {stationSummary.nonAssignedApplications
                        ? stationSummary.nonAssignedApplications
                        : "0"}
                    </div>
                  </div>
                  <div className="card bg-[#F4F7FA] w-44 rounded-md p-4">
                    <div className="heading">Resolved</div>
                    <div className="status mt-2 text-left text-4xl font-bold">
                      {stationSummary.resolvedApplications
                        ? stationSummary.resolvedApplications
                        : "0"}
                    </div>
                  </div>
                  <div className="card bg-[#F4F7FA] w-44 rounded-md p-4">
                    <div className="heading">Rejected</div>
                    <div className="status mt-2 text-left text-4xl font-bold">
                      {stationSummary.rejectedApplications
                        ? stationSummary.rejectedApplications
                        : "0"}
                    </div>
                  </div>
                  <div className="card bg-[#F4F7FA] w-44 rounded-md p-4">
                    <div className="heading">Pending</div>
                    <div className="status mt-2 text-left text-4xl font-bold">
                      {stationSummary.pendingApplications
                        ? stationSummary.pendingApplications
                        : "0"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row w-full flex flex-row items-start justify-between gap-5 bg-white p-4">
                <BlockUI blocking={stationSummaryLoading} />
                <div className="up-bar-chart w-full mx-auto flex flex-col">
                  <div className="l1 px-2 flex flex-row items-center justify-between">
                    <p>Officers</p>
                    <DownloadTableExcel
                        filename="OfficerSummaryExcel"
                        sheet="officers status"
                        currentTableRef={tableRef.current}
                      >
                        <ThemeProvider theme={theme}>
                          <Button aria-label="export excel sheet label">
                            <div className="flex flex-row items-center gap-2">
                              <RiFileExcel2Line color="green" size={20} />
                              <span className="text-[#246c22]">
                                Export to excel sheet
                              </span>
                            </div>
                          </Button>
                        </ThemeProvider>
                      </DownloadTableExcel>
                  </div>
                  <div className="l2 flex flex-row items-center justify-evenly">
                    <div className="w-full">
                      <div className="m-2 ">
                        <table className="w-full mt-8" ref={tableRef}>
                          <thead className="w-full rounded-t-md">
                            <tr className="">
                              <th className="bg-[#EFF2F7] text-[#000000] p-3 rounded-tl-lg text-left">
                                Officer
                              </th>
                              <th className="bg-emerald-600 text-[#000000] p-3">
                                Assigned
                              </th>
                              <th className="bg-green-300 text-[#000000] p-3">
                                Resolved
                              </th>
                              <th className="bg-red-300 text-[#000000] p-3">
                                Rejected
                              </th>
                              <th className="bg-yellow-300 text-[#000000] p-3 rounded-tr-lg">
                                Pending
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-full ">
                            {stationOfficerData.map((officer) => (
                              <tr className="w-full">
                                <td className="border-b p-4 flex flex-row items-center gap-2">
                                  <Link
                                    className="flex flex-row items-center gap-2"
                                    to={`/admin/${officer.officerID}`}
                                  >
                                    <Avatar
                                      alt={officer.officerName.toUpperCase()}
                                      src={`#`}
                                      sx={{
                                        backgroundColor: `${
                                          avatarsBackgroundColors[
                                            Math.floor(Math.random() * 8)
                                          ]
                                        }`,
                                      }}
                                    />

                                    <div className="textContent">
                                      <div className="name">
                                        {officer.officerName}
                                      </div>
                                      <div className="email-id text-10 w-44 overflow-auto ">
                                        {officer.officerDesignation}
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td className="border-b p-4 text-center bg-emerald-500">
                                  {officer.assignedApplication}
                                </td>
                                <td className="border-b p-4 text-center bg-green-200">
                                  {officer.resolvedApplications}
                                </td>
                                <td className="border-b p-4 text-center bg-red-200">
                                  {officer.rejectedApplications}
                                </td>
                                <td className="border-b p-4 text-center bg-yellow-200">
                                  {officer.pendingApplications}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row w-full flex flex-row items-start justify-between gap-5">
                <div className="admin-col w-full rounded-sm pb-4 flex flex-col gap-8">
                  <div
                    className="row bg-white p-6 data-table-grievances flex flex-col"
                    style={{ height: 600, width: "100%" }}
                  >
                    <p className="font-semibold pb-4">Grievance Details :</p>
                    <DataGrid
                      getRowId={(row) => row._id}
                      rows={grievanceRowData}
                      columns={grievanceColumns}
                      // pageSize={5}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 7, 10, 12, 15]}
                    />
                  </div>
                  <div
                    className="row bg-white p-6 data-table-encroachment flex flex-col"
                    style={{ height: 400, width: "100%" }}
                  >
                    {/* encroachmentRowData */}
                    <p className="font-semibold pb-4">Encroachment Details :</p>
                    <DataGrid
                      getRowId={(row) => row.transactionHash}
                      rows={encroachmentRowData}
                      columns={encroachmentColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 7, 10, 12, 15]}
                    />
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : (
          ""
        )}
      </div>
    </StationProfileOneSection>
  );
};

export default StationProfileOne;

const StationProfileOneSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  &::-webkit-scrollbar {
    display: none;
  }
  .email-id::-webkit-scrollbar {
    display: none;
  }
  .toggle-btn {
    input[type="checkbox"] {
      // Unchecked Styles
      background-color: green;
      color: green;
    }
    input[type="checkbox"]:checked {
      background-color: red;
      color: red;
      // Checked Styles
    }
  }
`;
