import React, { useEffect, useState } from "react";
import {
  Avatar,
  createTheme,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styled from "styled-components";
import RightTimeline from "../Components/RightTimeline";
import { IoLocation } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  AiFillCloseCircle,
  AiFillExclamationCircle,
  AiFillCheckCircle,
} from "react-icons/ai";
import { useStateContext } from "../Context/ContextProvider";
import GrievanceRightTimeline from "../Components/GrievanceRightTimeline";
import { Box, ThemeProvider } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { FaHourglassEnd } from "react-icons/fa";
import swal from "sweetalert";
import { Warning } from "postcss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    green: {
      main: "#1f7f12",
      contrastText: "#fff",
    },
    upperLayerButton: {
      main: "#757780",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const styleThree = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const initialValueOfOfficer = {
  admin_name: "unknown",
  email_address: "unknown",
  admin_designation: "unknown",
  access_type: "--",
  profile_picture: "none",
};
const initialValueOfStation = {
  station_name: "unknown",
  station_address: "unknown",
  station_contact: "unknown",
  station_flag: false,
};

const OfficerAllotmentPage = () => {
  const { gID } = useParams();
  /* getting encroachmentID from params */

  /* officerAllotment Modal useStates : start*/
  const [openOfficerAllotmentModal, setOpenOfficerAllotmentModal] = useState();
  const handleOpenOfficerAllotmentModal = () =>
    setOpenOfficerAllotmentModal(true);
  const handleCloseOfficerAllotmentModal = () =>
    setOpenOfficerAllotmentModal(false);

  const [officerName, setOfficerName] = React.useState("");
  const handleOfficerChange = (event) => {
    setOfficerName(event.target.value);
  };
  /* officerAllotment Modal useStates : end*/

  /* rejection Modal useStates : start*/
  const [openRejectionModal, setOpenRejectionModal] = useState();
  const handleOpenRejectionModal = () => setOpenRejectionModal(true);
  const handleCloseRejectionModal = () => setOpenRejectionModal(false);
  const [rejectionRemark, setRejectionRemark] = useState("");
  /* rejection Modal useStates : start*/

  /* newOfficerAllotment Modal useStates : start*/
  const [openNewOfficerAllotmentModal, setOpenNewOfficerAllotmentModal] =
    useState();
  const handleOpenNewOfficerAllotmentModal = () =>
    setOpenNewOfficerAllotmentModal(true);
  const handleCloseNewOfficerAllotmentModal = () =>
    setOpenNewOfficerAllotmentModal(false);

  const [newOfficerName, setNewOfficerName] = useState("");
  const [newOfficerEmail, setNewOfficerEmail] = useState("");
  const [newOfficerDesignation, setNewOfficerDesignation] = useState("");
  /* newOfficerAllotment Modal useStates : end*/
  /* newOfficerAllotment useStates : start*/
  const [newAllotOfficerLoading, setNewAllotOfficerLoading] = useState(false);
  /* newOfficerAllotment useStates : end*/

  const [loading, setLoading] = useState();
  const [allotOfficerLoading, setAllotOfficerLoading] = useState(false);
  const [grievanceDetails, setGrievanceDetails] = useState({});
  const [officerDetails, setOfficerDetails] = useState(initialValueOfOfficer);
  const [officerDataCheck, setOfficerDataCheck] = useState(false);
  const [stationDetails, setStationDetails] = useState(initialValueOfStation);
  const { singleGrievanceActionList, setSingleGrievanceActionList } =
    useStateContext();
  const [officerList, setOfficerList] = useState([]);

  const allotOfficer = async () => {
    setAllotOfficerLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}grievance/allotOfficer/${gID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          officerID: officerName,
        }),
      }
    );

    const data = await res.json();
    setAllotOfficerLoading(false);
    if (data.status === 200) {
      window.location.reload();
    } else {
      swal(data.message, data.description, "warning");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const allotNewOfficer = async () => {
    setNewAllotOfficerLoading(true);
    const addNewOfficerRes = await fetch(
      `${process.env.REACT_APP_API_URL}admin/add/officer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          admin_name: newOfficerName,
          email_address: newOfficerEmail,
          admin_designation: newOfficerDesignation,
        }),
      }
    );
    const NewOfficerDetails = await addNewOfficerRes.json();
    if (NewOfficerDetails.status != 200) {
      swal(data.message, data.description, "warning");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return false;
    }
    let newOfficerID = NewOfficerDetails.user_id;
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}grievance/allotOfficer/${gID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          officerID: newOfficerID,
        }),
      }
    );

    const data = await res.json();
    setNewAllotOfficerLoading(false);
    if (data.status === 200) {
      window.location.reload();
    } else {
      swal(data.message, data.description, "warning");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  const getOfficersList = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/officersNew/${gID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    setOfficerList(data.admins);
    // console.log(officerList);
  };
  const getEncroachmentDetails = async (e) => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}grievance/get/${gID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    setLoading(false);
    setGrievanceDetails(data.data);
    // console.log(grievanceDetails);
    if (data.data.officer != null) {
      setOfficerDetails(data.data.officer);
      setOfficerDataCheck(true);
    } else {
      setOfficerDataCheck(false);
    }
    setStationDetails(data.data.station);
    setSingleGrievanceActionList(data.data.actions);
  };

  const [rejectLoading, setRejectLoading] = useState(false);

  const rejectGrievance = async (e) => {
    setRejectLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}grievance/rejectGrievance/${gID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          rejectionRemark: rejectionRemark,
        }),
      }
    );

    const data = await res.json();
    setRejectLoading(false);
    if (data.status === 200) {
      swal(data.message, data.description, "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      swal(data.message, data.description, "error");
    }
  };

  const getMyCustomDate = (date) => {
    const apiDate = new Date(date);
    const MyDate = `${apiDate.getDate()}/${
      apiDate.getMonth() + 1
    }/${apiDate.getFullYear()}`;
    return MyDate;
  };
  const getGrievanceFlagName = (flag) => {
    if (flag === "0") {
      return "Officer is not alloted";
    } else if (flag === "1") {
      return "Officer alloted but action pending";
    } else if (flag === "2") {
      return "Action Taken";
    } else if (flag === "3") {
      return "Grievance Rejected";
    } else {
      return "Off";
    }
  };
  const getAccessTypeName = (access_type) => {
    if (access_type == "OO") {
      return "Officer";
    } else if (access_type == "A") {
      return "Admin";
    } else if (access_type == "S") {
      return "SuperAdmin";
    } else {
      return "Access Type is invalid";
    }
  };
  const styleThisAside = () => {
    let detailSection = document.getElementById(
      "SingleEncroachmentDetailSection"
    );
    let actionSection = document.getElementById("RightTimelineID");
    actionSection.style.height = `${detailSection.offsetHeight}px`;
  };
  useEffect(() => {
    getEncroachmentDetails();
    getOfficersList();
    styleThisAside();
  }, []);

  return (
    <OfficerAllotmentPageSection>
      <main className="p-8 flex flex-row items-start justify-between">
        <aside
          id="SingleEncroachmentDetailSection"
          className="upp-detail-section w-8/12"
        >
          <div className="upper-card bg-white rounded-md shadow-sm">
            <section>
              <div className="row w-full gap-5 p-4 py-8 rounded-md flex flex-row justify-between">
                <div className="encr-details flex flex-row items-start justify-center gap-5">
                  <Avatar
                    alt={`${grievanceDetails.grievanceName}}`}
                    // src={`https://faep.airchains.technology/api/uploads/${grievanceDetails.grievance_image}`}
                    src={`#`}
                    sx={{
                      fontSize: "60px",
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#828282",
                      color: "#eeeeee",
                    }}
                  />
                  <div className="details flex flex-col gap-2">
                    <div className="upper-content flex flex-row items-start justify-between">
                      <div className="name-location-details flex flex-col w-full">
                        <div className="name text-3xl font-semibold">
                          {grievanceDetails.grievanceName}
                        </div>
                        <div className="location flex flex-row items-center gap-1 mt-1">
                          <span className="text-md font-bold text-gray-700">
                            <IoLocation />
                          </span>
                          <span className="text-sm font-semibold text-gray-700 ">
                            {grievanceDetails.grievanceVillage}
                          </span>
                          <span className="text-10 ml-2 font-semibold bg-[#bfdbf7] px-1 rounded-md text-gray-700 ">
                            {getMyCustomDate(
                              grievanceDetails.grievance_creation_date
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="btns"></div>
                    </div>

                    <div className="description text-md mt-2 font-bold text-gray-700 w-10/12">
                      {grievanceDetails.grievanceTitle}
                    </div>
                    <div className="description text-xs mt-2 text-gray-700 w-10/12">
                      {grievanceDetails.grievanceDescription}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr className="w-11/12 mx-auto" />
            <section className="w-11/12 mx-auto mt-4">
              <ul className="flex flex-col gap-2 pb-4">
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Grievance ID : </span>
                  <span className="text-12">
                    {grievanceDetails.grievanceID}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Grievance Title : </span>
                  <span className="text-12">
                    {grievanceDetails.grievanceTitle}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Transaction Hash : </span>
                  <span className="text-12">
                    {grievanceDetails.transactionHash}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Date of Birth :</span>
                  <span className="text-12">
                    {getMyCustomDate(grievanceDetails.grievanceDOB)}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">
                    Grievancer's Mobile :
                  </span>
                  <span className="text-12">
                    {grievanceDetails.grievanceMobile}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">
                    Grievancer Address :
                  </span>
                  <span className="text-12">
                    {grievanceDetails.grievanceAddress}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Creation Date : </span>
                  <span className="text-12">
                    {getMyCustomDate(grievanceDetails.grievance_creation_date)}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Grievance Status :</span>
                  <span className="text-12 flex flex-row items-center gap-2">
                    {getGrievanceFlagName(grievanceDetails.grievanceFlag)}
                    <span>
                      {grievanceDetails.grievanceFlag === "0" ? (
                        <span className="text-gray-600">
                          <AiFillCloseCircle />
                        </span>
                      ) : (
                        ""
                      )}
                      {grievanceDetails.grievanceFlag === "3" ? (
                        <span className="text-red-600">
                          <AiFillCloseCircle />
                        </span>
                      ) : (
                        ""
                      )}
                      {grievanceDetails.grievanceFlag === "1" ? (
                        <span className="text-yellow-400">
                          <AiFillExclamationCircle />
                        </span>
                      ) : (
                        ""
                      )}
                      {grievanceDetails.grievanceFlag === "2" ? (
                        <span className="text-green-600">
                          <AiFillCheckCircle />
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </li>
                <li className=" flex flex-row items-start justify-between gap-3 mt-4">
                  <ThemeProvider theme={theme}>
                    <div className="flex flex-col items-start gap-2">
                      {grievanceDetails.grievance_image === "none" ||
                      grievanceDetails.grievance_image === "None" ? (
                        ""
                      ) : (
                        <Button
                          size="small"
                          color="green"
                          onClick={() => {
                            window.open(
                              `https://faep.airchains.technology/api/uploads/${grievanceDetails.grievance_image}`,
                              "_blank"
                            );
                          }}
                          variant="contained"
                        >
                          View Attached Image File
                        </Button>
                      )}

                      {grievanceDetails.grievanceFile === "none" ||
                      grievanceDetails.grievanceFile === "None" ? (
                        ""
                      ) : (
                        <Button
                          size="small"
                          color="green"
                          onClick={() => {
                            window.open(
                              `https://faep.airchains.technology/api/uploads/${grievanceDetails.grievanceFile}`,
                              "_blank"
                            );
                          }}
                          variant="contained"
                        >
                          View Attached PDF File
                        </Button>
                      )}
                    </div>
                    {grievanceDetails.grievanceFlag === "0" ||
                    grievanceDetails.grievanceFlag === "1" ? (
                      <LoadingButton
                        size="small"
                        id="saveChangesEditProfileButton"
                        color="secondary"
                        onClick={handleOpenRejectionModal}
                        loading={false}
                        loadingIndicator={<FaHourglassEnd size={20} />}
                        variant="contained"
                      >
                        Reject this grievance
                      </LoadingButton>
                    ) : (
                      ""
                    )}

                    <Modal
                      open={openRejectionModal}
                      // onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleThree}>
                        <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                          <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                            <TextField
                              id="outlined-basic"
                              label="Rejection Remark"
                              variant="outlined"
                              sx={{
                                width: "100%",
                              }}
                              onChange={(e) => {
                                setRejectionRemark(e.target.value);
                              }}
                            />
                          </div>

                          <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                            <Button
                              id="AP-closeModalBtn"
                              color="secondary"
                              variant="contained"
                              sx={{ borderRadius: "6px" }}
                              onClick={handleCloseRejectionModal}
                            >
                              Close Modal
                            </Button>
                            <LoadingButton
                              // size="small"
                              id="saveChangesEditProfileButton"
                              color="primary"
                              onClick={rejectGrievance}
                              loading={rejectLoading}
                              loadingIndicator={<FaHourglassEnd size={20} />}
                              variant="contained"
                              sx={{
                                background: "#003049",
                                borderRadius: "6px",
                              }}
                            >
                              Submit
                            </LoadingButton>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </ThemeProvider>
                </li>
              </ul>
            </section>
          </div>
          {loading ? (
            ""
          ) : (
            <section className="lower-card flex flex-row items-start justify-between gap-4 mt-4">
              <div className="card-1 officer w-6/12 rounded-md p-2 min-h-[170px]">
                <caption className="flex text-xl font-bold">
                  Officer Details
                </caption>
                <div className="details h-full">
                  {officerDataCheck ? (
                    <>
                      <div className="officer w-full flex flex-row items-center mt-3">
                        <div className="name text-lg font-semibold">
                          {officerDetails.admin_name}
                          <span className="text-10 ml-2 font-semibold bg-[#bfdbf7] px-2 py-0.5 rounded-md text-gray-700 ">
                            Appointed Officer
                          </span>
                        </div>
                      </div>
                      <ul className="flex flex-col gap-1 pb-4 mt-1">
                        <li className=" flex flex-row items-center gap-3 ">
                          <span className="font-bold text-xs">Email :</span>
                          <span className="text-xs">
                            {officerDetails.email_address}
                          </span>
                        </li>
                        <li className=" flex flex-row items-center gap-3 ">
                          <span className="font-bold text-xs">
                            Designation :
                          </span>
                          <span className="text-xs">
                            {officerDetails.admin_designation}
                          </span>
                        </li>
                        <li className=" flex flex-row items-center gap-3 ">
                          <span className="font-bold text-xs">
                            Access Type :
                          </span>
                          <span className="text-xs">
                            {getAccessTypeName(officerDetails.access_type)}
                          </span>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <div className="officer w-full flex flex-row items-center mt-3">
                        <div className="text-10 font-semibold bg-[#bfdbf7] px-2 py-0.5 rounded-md text-gray-700 ">
                          No officer is alloted
                        </div>
                      </div>
                      <div className="buttons flex flex-col items-start gap-4 mt-4">
                        <ThemeProvider theme={theme}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleOpenOfficerAllotmentModal}
                          >
                            Allot Officer
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleOpenNewOfficerAllotmentModal}
                          >
                            Allot New Officer
                          </Button>
                          <Modal
                            open={openNewOfficerAllotmentModal}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={styleTwo}>
                              <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                                <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                                  <TextField
                                    id="outlined-basic"
                                    label="Officer Name"
                                    variant="outlined"
                                    sx={{
                                      width: "50%",
                                    }}
                                    onChange={(e) => {
                                      setNewOfficerName(e.target.value);
                                    }}
                                  />
                                  <TextField
                                    id="outlined-basic"
                                    label="Officer Email"
                                    type="email"
                                    variant="outlined"
                                    className="modal-numType-textfield"
                                    sx={{
                                      width: "50%",
                                    }}
                                    onChange={(e) => {
                                      setNewOfficerEmail(e.target.value);
                                    }}
                                  />
                                </div>
                                {/* <div className="row w-full mx-auto flex flex-row gap-4">
                                  <FormControl fullWidth>
                                    <InputLabel id="access-type-label">
                                      Access Type
                                    </InputLabel>
                                    <Select
                                      labelId="access-type-label"
                                      id="simple-select"
                                      defaultValue="OO"
                                      disabled
                                      // value={accessType}
                                      label="Access Type"
                                      // onChange={handleAccessChange}
                                    >
                                      <MenuItem value="A">Admin</MenuItem>
                                      <MenuItem value="OO">
                                        Other Officer
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div> */}
                                <div className="row w-full mx-auto flex flex-row">
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Officer Designation"
                                    sx={{
                                      width: "100%",
                                    }}
                                    onChange={(e) => {
                                      setNewOfficerDesignation(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                                  <Button
                                    id="AP-closeModalBtn"
                                    color="secondary"
                                    variant="contained"
                                    sx={{ borderRadius: "6px" }}
                                    onClick={
                                      handleCloseNewOfficerAllotmentModal
                                    }
                                  >
                                    Close Modal
                                  </Button>
                                  <LoadingButton
                                    // size="small"
                                    id="saveChangesEditProfileButton"
                                    color="primary"
                                    onClick={allotNewOfficer}
                                    loading={newAllotOfficerLoading}
                                    loadingIndicator={
                                      <FaHourglassEnd size={20} />
                                    }
                                    variant="contained"
                                    sx={{
                                      background: "#003049",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    Add and Allot New officer
                                  </LoadingButton>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </ThemeProvider>
                        <Modal
                          open={openOfficerAllotmentModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <div className="box py-8 mx-auto flex flex-col justify-center items-center gap-8">
                              <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                                <FormControl fullWidth>
                                  <InputLabel id="station-name-label">
                                    Officers
                                  </InputLabel>
                                  <Select
                                    labelId="station-name-label"
                                    id="simple-select"
                                    value={officerName}
                                    label="Officers"
                                    onChange={handleOfficerChange}
                                  >
                                    {/* <MenuItem value={10}>Ten</MenuItem> */}
                                    {officerList.map((item) => (
                                      <MenuItem
                                        value={item.admin_id}
                                        key={item.admin_id}
                                      >
                                        {item.admin_name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              {/* <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                                <TextField
                                  id="outlined-basic"
                                  label="Designation"
                                  // type="number"
                                  variant="outlined"
                                  className="modal-numType-textfield"
                                  sx={{
                                    width: "100%",
                                  }}
                                  defaultValue={
                                    singleAdminData.admin_designation
                                  }
                                  onChange={(e) => {
                                    setAdmin_designation(e.target.value);
                                  }}
                                />
                              </div> */}
                              <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                                <Button
                                  id="closeModalEditProfileButton"
                                  disabled={allotOfficerLoading}
                                  color="secondary"
                                  variant="contained"
                                  sx={{ borderRadius: "6px" }}
                                  onClick={handleCloseOfficerAllotmentModal}
                                >
                                  Close Modal
                                </Button>
                                {/* <Button
                                  id="saveChangesEditProfileButton"
                                  color="primary"
                                  variant="contained"
                                  sx={{
                                    background: "#003049",
                                    borderRadius: "6px",
                                  }}
                                  onClick={allotOfficer}
                                >
                                  Save changes
                                </Button> */}
                                <LoadingButton
                                  // size="small"
                                  id="saveChangesEditProfileButton"
                                  color="primary"
                                  onClick={allotOfficer}
                                  loading={allotOfficerLoading}
                                  loadingIndicator={
                                    <FaHourglassEnd size={20} />
                                  }
                                  variant="contained"
                                  sx={{
                                    background: "#003049",
                                    borderRadius: "6px",
                                  }}
                                >
                                  Submit
                                </LoadingButton>
                              </div>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="card-2 station w-6/12 rounded-md p-2">
                <caption className="flex text-xl font-bold">
                  Station Details
                </caption>
                <div className="station w-full flex flex-row items-center mt-3">
                  <div className="name text-xl font-semibold">
                    {stationDetails.station_name}
                    <span className="text-10 ml-2 font-semibold bg-[#bfdbf7] px-2 py-0.5 rounded-md text-gray-700 ">
                      Station
                    </span>
                  </div>
                </div>
                <ul className="flex flex-col gap-1 pb-4 mt-3">
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Address :</span>
                    <span className="text-xs">
                      {stationDetails.station_address}
                    </span>
                  </li>
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Contact :</span>
                    <span className="text-xs">
                      {stationDetails.station_contact}
                    </span>
                  </li>
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Station Status :</span>
                    <span className="text-xs">
                      {stationDetails.station_flag === true ? "On" : "Off"}
                    </span>
                  </li>
                </ul>
              </div>
            </section>
          )}
        </aside>
        <aside
          id="RightTimelineID"
          className="upp-action-section w-4/12 overflow-y-auto"
        >
          <GrievanceRightTimeline />
        </aside>
      </main>
    </OfficerAllotmentPageSection>
  );
};

export default OfficerAllotmentPage;

const OfficerAllotmentPageSection = styled.section`
  width: calc(100vw - 288px);
  &::-webkit-scrollbar {
    display: none;
  }
  .lower-card {
    .card-1,
    .card-2 {
      /* background-image: linear-gradient(to bottom right, #fff 30%, #a4b0e4); */
      background-color: white;
    }
    .card-2 {
    }
  }
  .upp-action-section {
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1e8bf1;
      border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #111827;
    }
  }
`;
