import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { Card } from "../Components/Common/ReusableComponent";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import swal from "sweetalert";
import { BsCheckCircleFill, BsStopCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import Avatar from "@mui/material/Avatar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
  },
});

const avatarsBackgroundColors = [
  "#180605",
  "#45818e",
  "#6aa84f",
  "#2986cc",
  "#e0a366",
  "#db3c30",
  "#674ea7",
  "#e066a3",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const CODetails = () => {
  const { coID } = useParams();
  const [dataColumn, setDataColumn] = useState([]);
  const [coInfo, setCoInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCOData = async () => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/CODetails/${coID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );
    const COJson = await res.json();
    setLoading(false);
    let fiData = [];
    setCoInfo(COJson.data.coDetials);
    let myStationElement = COJson.data.stations;

    for (let i = 0; i < myStationElement.length; i++) {
      let upd = {
        station_name: myStationElement[i].station_name,
        station_address: myStationElement[i].station_address,
        station_flag: myStationElement[i].station_flag,
        _id: myStationElement[i]._id,
      };
      fiData.push(upd);
    }
    setDataColumn(fiData);
  };

  useEffect(() => {
    getCOData();
  }, []);
  // dataCon.setSD(dataColumn)
  const data = dataColumn;

  return (
    <CODetailsSection>
      <div className="up-title mt-5 w-11/12 mx-auto flex flex-row justify-between items-center">
        <Header
          category="CO Profile"
          title={`Stations Under ${coInfo.admin_name} `}
        />
      </div>
      <div className="up-cards w-full bg-[#f4f7fa] py-10">
        <div className="up-card w-11/12 mx-auto">
          <div className="relative overflow-x-auto sm:rounded-sm">
            <div className="table-body min-h-[500px] relative overflow-x-auto sm:rounded-sm">
              <div className="row mb-4">
                <h1 className="font-semibold">List of Stations</h1>
                <p className="text-sm w-11/12 mt-2 text-gray-500">
                  This is the list of Stations .
                </p>
              </div>
              <div className="table w-full">
                {data.map((e) => (
                  <Link to={`/station/${e._id}`}>
                    <div
                      key={e._id}
                      className="upp-table-row bg-[#EFF2F7] hover:shadow-lg flex flex-row items-center justify-between my-6 px-6 py-4 rounded-md"
                    >
                      <div className="avatarDetails flex flex-row items-center gap-4">
                        <div className="avatar">
                          <Avatar
                            alt={`${e.station_name.toUpperCase()}`}
                            src={`${e.profile_picture}`}
                            sx={{
                              height: "60px",
                              width: "60px",
                              backgroundColor: `${
                                avatarsBackgroundColors[
                                  Math.floor(Math.random() * 8)
                                ]
                              }`,
                            }}
                          />
                        </div>
                        <div className="details flex flex-col items-start justify-start">
                          <div className="stationName font-semibold">
                            {e.station_name}
                          </div>
                          <div className="stationAddress text-12 text-gray-600 ">
                            {e.station_address}
                          </div>
                        </div>
                      </div>
                      <div className="status">
                        {e.station_flag ? (
                          <BsCheckCircleFill
                            style={{
                              color: "green",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        ) : (
                          <BsStopCircleFill
                            style={{
                              color: "red",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        )}
                      </div>
                      {/* <div
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap flex flex-row items-center gap-4"
                      >
                          <Avatar
                            alt={`${e.station_name}`}
                            src={`${e.profile_picture}`}
                          />
                        <span>{e.station_name}</span>
                      </div>
                      <div className="px-6 py-4">{e.station_address}</div>
                      <div className="px-6 py-4">
                        {e.station_flag ? (
                          <BsCheckCircleFill style={{ color: "green" }} />
                        ) : (
                          <BsStopCircleFill style={{ color: "red" }} />
                        )}
                      </div> */}
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            {/* table end */}
          </div>
          <BlockUI blocking={loading} />
        </div>
      </div>
    </CODetailsSection>
  );
};

export default CODetails;

const CODetailsSection = styled.section`
  width: calc(100vw - 288px);
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    .up-card {
      ${Card}
      border-radius: 10px;
      background-color: #fff;
    }
  }
  .modal-numType-textfield {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .upp-table-row {
    .avatarDetails {
      transition: all ease 0.3s;
    }
    .status {
      transition: all ease 0.3s;
    }
    &:hover {
      .avatarDetails {
        transform: scale(1.05);
      }
      .status {
        transform: scale(1.05);
      }
    }
  }
`;
