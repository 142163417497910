import React, { useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@material-ui/core/styles";
import { Button, createTheme } from "@mui/material";
import { useStateContext } from "../Context/ContextProvider";

const theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        display: "none",
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

const GrievanceRightTimeline = () => {
  const { singleGrievanceActionList } = useStateContext();

  const getMyCustomDate = (date) => {
    const apiDate = new Date(date);
    const MyDate = `${apiDate.getDate()}/${
      apiDate.getMonth() + 1
    }/${apiDate.getFullYear()}`;
    return MyDate;
  };

  useEffect(() => {
    console.log(singleGrievanceActionList);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {singleGrievanceActionList.map((item) => (
        <Timeline position="right" key={item._id}>
          <TimelineItem>
            <TimelineOppositeContent
              style={{
                maxWidth: "1px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            />
            {
              item.grievance_action_flag=="1" && item.grievance_action_image=="NONE" ? <>
                <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#1e8bf1" }}></TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#1e8bf1" }} />
            </TimelineSeparator>
              </>:<>
                {
                  item.grievance_action_flag=="1" ? <>
                  <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#f7c600" }}></TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#f7c600" }} />
            </TimelineSeparator>
                  </>:<>
                  {
                  item.grievance_action_flag=="2" ? <>
                  <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#05cf48" }}></TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#05cf48" }} />
            </TimelineSeparator>
                  </>:<>
                  
                  {
                  item.grievance_action_flag=="3" ? <>
                  <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#e30c2c" }}></TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#e30c2c" }} />
            </TimelineSeparator>
                  </>:""
                }
                
                </>
                }
                  </>
                }
              </>
            }
            
            <TimelineContent sx={{ py: "6px", px: 2 }}>
              <div className="flex flex-col gap-0">
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                  }}
                  component="span"
                >
                  {item.grievance_action_title}
                  {/* Eat */}
                </Typography>
                <Typography variant="subtitle2">
                  {getMyCustomDate(item.create_date)}
                  {/* Because you need strength */}
                </Typography>
                <Typography variant="caption">
                  {item.grievance_action_remarks}
                </Typography>
                <Typography variant="caption">
                  {item.grievance_action_image === "NONE" ? (
                    ""
                  ) : (
                    <Button
                      size="small"
                      onClick={() => {
                        window.open(
                          `https://faep.airchains.technology/api/uploads/${item.grievance_action_image}`,
                          "_blank"
                        );
                      }}
                      sx={{
                        marginTop : "16px"
                      }}
                      variant="contained"
                    >
                      View Attached Image File
                    </Button>
                  )}
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ))}
    </ThemeProvider>
  );
};

export default GrievanceRightTimeline;
