import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../Components/Header";
import { IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { GoVerified, GoUnverified } from "react-icons/go";
import { GiPirateFlag } from "react-icons/gi";
import { FaFlag } from "react-icons/fa";
import { styled as muiStyled, alpha } from "@mui/material/styles";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Card } from "../Components/Common/ReusableComponent";
import { useStateContext } from "../Context/ContextProvider";
import { Link, useParams } from "react-router-dom";

const StyledMenu = muiStyled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const getFlagStatus = (flag) => {
  switch (flag) {
    case "0":
      return (
        <span className="text-gray-600 flex justify-center">
          <FaFlag />
        </span>
      );
    case "1":
      return (
        <span className="text-yellow-400 flex justify-center">
          <FaFlag />
        </span>
      );
    case "2":
      return (
        <span className="text-green-600 flex justify-center">
          <FaFlag />
        </span>
      );
    case "3":
      return (
        <span className="text-red-600 flex justify-center">
          <FaFlag />
        </span>
      );

    default:
      return (
        <span className="text-gray-600 flex justify-center">
          <FaFlag />
        </span>
      );
  }
};

const AdminGrievanceListPage = () => {
  /* Mui Menu Items codes and useStates -> Start */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchChoice, setSearchChoice] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  /* Mui Menu Items codes and useStates -> End*/

  const { encrListLoading, setEncrListLoading } = useStateContext();
  const [filterDataAPILoading, setFilterDataAPILoading] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageNox, setPG] = useState();

  const getUsersListAPI = async (pageNo = 1) => {
    setEncrListLoading(true);

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}grievance/get/all`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          page: pageNo,
        }),
      }
    );
    let grievanceData = await res.json();
    setEncrListLoading(false);
    setApiDataLoading(false);
    setData(grievanceData.grievanceList);
    setDataPage(grievanceData.page);
  };

  // const callNextData = async(pageNo) => {
  //   setEncrListLoading(true);
  //   const res = await fetch(
  //     `${process.env.REACT_APP_API_URL}grievance/get/all`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         ipaddress: "192.168.1.2",
  //         auth: localStorage.getItem("token"),
  //       },
  //       body: JSON.stringify({
  //         page: pageNo,
  //       }),
  //     }
  //   );
  //   let grievanceData = await res.json();
  //   setEncrListLoading(false);
  //   setApiDataLoading(false);
  //   setData(grievanceData.grievanceList);
  //   setDataPage(grievanceData.page);
  // }

  useEffect(() => {
    getUsersListAPI();
  }, []);

  const userListDataFromAPI = data;

  const FilterDateFunction = (thisDate) => {
    const apiDate = new Date(thisDate);
    const MyDate = `${apiDate.getDate()}/${
      apiDate.getMonth() + 1
    }/${apiDate.getFullYear()}`;

    return MyDate;
  };

  const FilterSearchFunction = () => {
    if (apiDataLoading === false) {
      let userSectionSearchBar, filteredText, table, tr, td, i, txtValue;
      userSectionSearchBar = document.getElementById(
        "search-bar-of-users-section"
      );
      filteredText = searchText.toUpperCase();
      table = document.getElementById("user-list-table");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        switch (searchChoice) {
          case "id":
            td = tr[i].getElementsByTagName("td")[0];
            break;
          case "name":
            td = tr[i].getElementsByTagName("td")[1];
            break;
          case "title":
            td = tr[i].getElementsByTagName("td")[2];
            break;
          case "date":
            td = tr[i].getElementsByTagName("td")[3];
            break;

          default:
            td = tr[i].getElementsByTagName("td")[0];
            break;
        }
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filteredText) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }
  };
  const FilterUniversalSearchFunction = async () => {
    if(searchText === "" || searchText === undefined || searchText.length === 0){
      getUsersListAPI()
      return 
    }
    setFilterDataAPILoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}grievance/filteredData`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          searchCategory: searchChoice,
          searchData: searchText,
        }),
      }
    );

    let grievanceData = await res.json();
    setData(grievanceData.grievanceList);
    setDataPage(grievanceData.page);
    setFilterDataAPILoading(false);
  };
  return (
    <AdminGrievanceListPageSection>
      <div className="up-title mt-5 w-11/12 mx-auto">
        <Header category="Dashboard" title="Grievance List" />
      </div>
      <div className="up-cards w-full bg-[rgba(102,155,188,0.3)] py-10">
        <div className="up-card w-11/12 mx-auto">
          <div className="search-bar">
            <div className="relative flex flex-row items-center justify-between bg-gray-50 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="search-bar-of-users-section"
                className="block p-4 pl-10 text-sm flex-1 text-gray-900 bg-transparent rounded-lg dark:placeholder-gray-400 dark:text-white outline-none"
                placeholder="Search Here..."
                onChange={(thisComponent) => {
                  setSearchText(thisComponent.target.value);
                }}
                // required
              />
              <div className="buttons-area flex flex-row justify-center items-center">
                <div className="search-col-selector">
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {`Search By ${
                      searchChoice == "" ? setSearchChoice("id") : searchChoice
                    }`}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("id");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      ID
                    </MenuItem>
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("name");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Name
                    </MenuItem>
                    <MenuItem
                      onClick={function () {
                        setSearchChoice("title");
                        handleClose();
                      }}
                      disableRipple
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Title
                    </MenuItem>
                  </StyledMenu>
                </div>
                <div className="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 flex gap-4">
                  <Button
                    variant="contained"
                    onClick={FilterSearchFunction}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Search this page
                  </Button>
                  <Button
                    variant="contained"
                    onClick={FilterUniversalSearchFunction}
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Universal Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="table-section mt-4">
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table
                className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                id="user-list-table"
              >
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="py-3 px-4">
                      ID
                    </th>
                    <th scope="col" className="py-3 pr-4">
                      Name
                    </th>
                    <th scope="col" className="py-3 pr-4">
                      Grievance Title
                    </th>
                    <th scope="col" className="py-3 pr-4">
                      Date
                    </th>
                    <th scope="col" className="py-3 text-center pr-4">
                      Status
                    </th>
                    <th scope="col" className="py-3 text-center pr-4"></th>
                  </tr>
                </thead>
                {filterDataAPILoading ? (
                  <p>
                    Please wait data fetching from universal collection need
                    time
                  </p>
                ) : (
                  <tbody className="">
                    {userListDataFromAPI.map((item) => (
                      <tr
                        key={item._id}
                        className="bg-white border-b w-full dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td
                          scope="row"
                          className="py-2 px-4 font-medium break-words w-1/12 text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.grievanceID}
                        </td>
                        <td className="py-2 pr-4 text-12">
                          {item.grievanceName}
                        </td>
                        <td className="py-2 pr-4 text-12">
                          {item.grievanceTitle}
                        </td>
                        <td className="py-2 pr-4 text-12">
                          {FilterDateFunction(item.create_date)}
                        </td>
                        <td className="py-2 pr-4 text-12">
                          {getFlagStatus(item.g_flag)}
                        </td>
                        <td className="flex items-center justify-center gap-2 py-2 pr-4 text-12 ">
                          <Link
                            to={`/Grievance-Officer-Allotment/${item.grievanceID}`}
                          >
                            <div className="my-1 font-medium text-blue-600 dark:text-blue-500 hover:underline">
                              <IconButton>
                                <span className="text-blue-600">
                                  <FaInfoCircle />
                                </span>
                              </IconButton>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              <div className="pagination w-full p-4">
                <div className="w-full flex flex-row items-center justify-between">
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    <span>Showing</span>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {" " + dataPage.startCount + " "}
                    </span>
                    <span>to</span>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {" " + dataPage.endCount + " "}
                    </span>
                    <span>of</span>
                    <span className="font-semibold text-gray-900 dark:text-white">
                      {" " + dataPage.totalCount + " "}
                    </span>
                    <span>Entries</span>
                  </div>
                  <div className="inline-flex mt-2 xs:mt-0">
                    <button
                      onClick={() => {
                        getUsersListAPI(dataPage.prevPage);
                      }}
                      className="py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Prev
                    </button>
                    <button
                      onClick={() => {
                        getUsersListAPI(dataPage.nextPage);
                      }}
                      className="py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminGrievanceListPageSection>
  );
};

export default AdminGrievanceListPage;

const AdminGrievanceListPageSection = styled.section`
  width: calc(100vw - 288px);
  /* overflow-x: hidden; */
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    .up-card {
      ${Card}
      background-color: #fff;
    }
  }
`;
