import { Avatar, createTheme } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import RightTimeline from "../Components/RightTimeline";
import { IoLocation } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  AiFillCloseCircle,
  AiFillExclamationCircle,
  AiFillCheckCircle,
} from "react-icons/ai";
import { useStateContext } from "../Context/ContextProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    upperLayerButton: {
      main: "#757780",
    },
  },
});

const initialValueOfOfficer = {
  admin_name: "unknown",
  email_address: "unknown",
  admin_designation: "unknown",
  access_type: "--",
  profile_picture: "none",
};
const initialValueOfStation = {
  station_name: "unknown",
  station_address: "unknown",
  station_contact: "unknown",
  station_flag: false,
};

const SingleEncroachmentDetail = () => {
  const { encroachmentID } = useParams();
  /* getting encroachmentID from params */
  const [loading, setLoading] = useState();
  const [encroachmentDetails, setEncroachmentDetails] = useState({});
  const [officerDetails, setOfficerDetails] = useState(initialValueOfOfficer);
  const [stationDetails, setStationDetails] = useState(initialValueOfStation);
  const { singleEncrActionList, setSingleEncrActionList } = useStateContext();

  const getEncroachmentDetails = async (e) => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}encr/admin/encr/${encroachmentID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    setLoading(false);
    setEncroachmentDetails(data.data);
    setOfficerDetails(data.data.officer);
    setStationDetails(data.data.station);
    setSingleEncrActionList(data.data.actions);
  };

  const getMyCustomDate = (date) => {
    const apiDate = new Date(date);
    const MyDate = `${apiDate.getDate()}/${
      apiDate.getMonth() + 1
    }/${apiDate.getFullYear()}`;
    return MyDate;
  };
  const getEncroachmentFlagName = (flag) => {
    if (flag === "0") {
      return "Location and Image is not verified";
    } else if (flag === "1") {
      return "Action Pending";
    } else if (flag === "2") {
      return "Action Taken";
    } else {
      return "Off";
    }
  };
  const getAccessTypeName = (access_type) => {
    if (access_type == "OO") {
      return "Officer";
    } else if (access_type == "A") {
      return "Admin";
    } else if (access_type == "S") {
      return "SuperAdmin";
    } else {
      return "Access Type is invalid";
    }
  };
  const styleThisAside = () => {
    let detailSection = document.getElementById(
      "SingleEncroachmentDetailSection"
    );
    let actionSection = document.getElementById("RightTimelineID");
    actionSection.style.height = `${detailSection.offsetHeight}px`;
  };
  useEffect(() => {
    getEncroachmentDetails();
    styleThisAside();
  }, []);

  return (
    <SingleEncroachmentDetailSection>
      <main className="p-8 flex flex-row items-start justify-between">
        <aside
          id="SingleEncroachmentDetailSection"
          className="upp-detail-section w-8/12"
        >
          <div className="upper-card bg-white rounded-md shadow-sm">
            <section>
              <div className="row w-full gap-5 p-4 py-8 rounded-md flex flex-row justify-between">
                <div className="encr-details flex flex-row items-start justify-center gap-5">
                  <Avatar
                    alt={`${encroachmentDetails.encroacher_name}}`}
                    src={`${encroachmentDetails.encr_image}`}
                    sx={{
                      fontSize: "90px",
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#757780",
                      color: "#818cf8",
                    }}
                  />
                  <div className="details flex flex-col gap-2">
                    <div className="upper-content flex flex-row items-start justify-between">
                      <div className="name-location-details flex flex-col w-full">
                        <div className="name text-3xl font-semibold">
                          {/* John Foo */}
                          {encroachmentDetails.encroacher_name}
                        </div>
                        <div className="location flex flex-row items-center gap-1 mt-1">
                          <span className="text-md font-bold text-gray-700">
                            <IoLocation />
                          </span>
                          <span className="text-sm font-semibold text-gray-700 ">
                            {encroachmentDetails.encroacher_village}
                            {/* Charoda Basti */}
                          </span>
                          <span className="text-10 ml-2 font-semibold bg-[#bfdbf7] px-1 rounded-md text-gray-700 ">
                            {encroachmentDetails.encr_location}
                          </span>
                        </div>
                      </div>
                      <div className="btns"></div>
                    </div>

                    <div className="description text-xs mt-2 text-gray-700 w-10/12">
                      {encroachmentDetails.description}
                      
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr className="w-11/12 mx-auto" />
            <section className="w-11/12 mx-auto mt-4">
              <ul className="flex flex-col gap-2 pb-4">
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Encroachment ID : </span>
                  <span className="text-12"> {encroachmentDetails.id} </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Transaction Hash : </span>
                  <span className="text-12">
                    {encroachmentDetails.transactionHash}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Encroacher Mobile :</span>
                  <span className="text-12">
                    {encroachmentDetails.encroacher_mobile}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Creation Date : </span>
                  <span className="text-12">
                    {getMyCustomDate(encroachmentDetails.create_date)}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">
                    Encroachment Status :
                  </span>
                  <span className="text-12 flex flex-row items-center gap-2">
                    {getEncroachmentFlagName(encroachmentDetails.encr_flag)}{" "}
                    <span>
                      {encroachmentDetails.encr_flag === "0" ? (
                        <span className="text-red-600">
                          <AiFillCloseCircle />
                        </span>
                      ) : (
                        ""
                      )}
                      {encroachmentDetails.encr_flag === "1" ? (
                        <span className="text-yellow-400">
                          <AiFillExclamationCircle />
                        </span>
                      ) : (
                        ""
                      )}
                      {encroachmentDetails.encr_flag === "2" ? (
                        <span className="text-green-600">
                          <AiFillCheckCircle />
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </li>
              </ul>
            </section>
          </div>
          {loading ? (
            ""
          ) : (
            <section className="lower-card flex flex-row items-center justify-between gap-4 mt-4">
              <div className="card-1 officer w-6/12 rounded-md p-2">
                <caption className="flex text-xl font-bold">
                  Officer Details
                </caption>
                <div className="officer w-full flex flex-row items-center mt-3">
                  <div className="name text-lg font-semibold">
                    {officerDetails.admin_name}
                    <span className="text-10 ml-2 font-semibold bg-[#bfdbf7] px-2 py-0.5 rounded-md text-gray-700 ">
                      Appointed Officer
                    </span>
                  </div>
                </div>
                <ul className="flex flex-col gap-1 pb-4 mt-1">
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Email :</span>
                    <span className="text-xs">
                      {officerDetails.email_address}
                    </span>
                  </li>
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Designation :</span>
                    <span className="text-xs">
                      {officerDetails.admin_designation}
                    </span>
                  </li>
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Access Type :</span>
                    <span className="text-xs">
                      {getAccessTypeName(officerDetails.access_type)}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-2 station w-6/12 rounded-md p-2">
                <caption className="flex text-xl font-bold">
                  Station Details
                </caption>
                <div className="station w-full flex flex-row items-center mt-3">
                  <div className="name text-xl font-semibold">
                    {stationDetails.station_name}
                    <span className="text-10 ml-2 font-semibold bg-[#bfdbf7] px-2 py-0.5 rounded-md text-gray-700 ">
                      Station
                    </span>
                  </div>
                </div>
                <ul className="flex flex-col gap-1 pb-4 mt-3">
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Address :</span>
                    <span className="text-xs">
                      {stationDetails.station_address}
                    </span>
                  </li>
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Contact :</span>
                    <span className="text-xs">
                      {stationDetails.station_contact}
                    </span>
                  </li>
                  <li className=" flex flex-row items-center gap-3 ">
                    <span className="font-bold text-xs">Station Status :</span>
                    <span className="text-xs">
                      {stationDetails.station_flag === true ? "On" : "Off"}
                    </span>
                  </li>
                </ul>
              </div>
            </section>
          )}
        </aside>
        <aside
          id="RightTimelineID"
          className="upp-action-section w-4/12 overflow-y-auto"
        >
          <RightTimeline />
        </aside>
      </main>
    </SingleEncroachmentDetailSection>
  );
};

export default SingleEncroachmentDetail;

const SingleEncroachmentDetailSection = styled.section`
  width: calc(100vw - 288px);
  &::-webkit-scrollbar {
    display: none;
  }
  .lower-card {
    .card-1,
    .card-2 {
      /* background-image: linear-gradient(to bottom right, #fff 30%, #a4b0e4); */
      background-color: white;
    }
    .card-2 {
    }
  }
  .upp-action-section {
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1e8bf1;
      border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #111827;
    }
  }
`;
