import React, { useEffect } from "react";
import Header from "../Components/Header";
import { Card } from "../Components/Common/ReusableComponent";
import styled from "styled-components";
import { useStateContext } from "../Context/ContextProvider";

const Analytics = () => {
  const { loginUserDetails, setLoginUserDetails } = useStateContext();

  useEffect(() => {
  }, []);

  return (
    <AnalyticsSection>
      <div className="up-title mt-5 w-11/12 mx-auto">
        <Header category="Dashboard" title="Analytics" />
      </div>
      <div className="up-cards w-full h-full flex flex-col  bg-[rgba(102,155,188,0.3)] py-10">
        <div className="up-card w-11/12 h-full mx-auto">
          Charts are going to be place here
          <div className="text-6xl font-bold text-center mt-4">Coming soon</div>
        </div>
      </div>
    </AnalyticsSection>
  );
};

export default Analytics;

const AnalyticsSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  height: calc(100% - 112px);
  /* overflow-x: hidden; */
  &::-webkit-scrollbar {
    display: none;
  }
`;
