import { css } from "styled-components";

export const Card = css`
  box-shadow: 0 3px 32px 0 rgba(31, 38, 135, 0.06);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1.8rem;
  z-index: 10;
`;
