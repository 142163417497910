import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

// make initial objects

export const ContextProvider = ({ children }) => {
  // make states and functions

  const [encrListLoading, setEncrListLoading] = useState(true);
  const [singleEncrActionList, setSingleEncrActionList] = useState([]);
  const [singleGrievanceActionList, setSingleGrievanceActionList] = useState(
    []
  );
  const [loginUserDetails, setLoginUserDetails] = useState({});

  return (
    <StateContext.Provider
      value={{
        // call them here to to transfer them to any where
        encrListLoading,
        setEncrListLoading,
        singleEncrActionList,
        setSingleEncrActionList,
        loginUserDetails,
        setLoginUserDetails,
        singleGrievanceActionList,
        setSingleGrievanceActionList,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
