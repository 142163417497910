import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import { MdLocalHotel } from "react-icons/md";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@mui/material";
import { useStateContext } from "../Context/ContextProvider";

const theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        display: "none",
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

const RightTimeline = () => {
  const { singleEncrActionList } = useStateContext();

  const getMyCustomDate = (date) => {
    const apiDate = new Date(date);
    const MyDate = `${apiDate.getDate()}/${
      apiDate.getMonth() + 1
    }/${apiDate.getFullYear()}`;
    return MyDate;
  };
  
  return (
    <ThemeProvider theme={theme}>
      {singleEncrActionList.map((item) => (
        <Timeline position="right" key={item._id}> 
          <TimelineItem>
            <TimelineOppositeContent
              style={{
                maxWidth: "1px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            />
            <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#1e8bf1" }}></TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#1e8bf1" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "6px", px: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                }}
                component="span"
              >
                {item.action_title}
                {/* Eat */}
              </Typography>
              <Typography variant="subtitle2">
                {getMyCustomDate(item.create_date)} 
                {/* Because you need strength */}
              </Typography>
              <Typography variant="caption">
                {item.action_remarks}
               
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ))}
    </ThemeProvider>
  );
};

export default RightTimeline;
