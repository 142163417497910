import React, { useEffect, useState, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card } from "../Components/Common/ReusableComponent";
import ThreeRCard from "../Components/Common/ThreeRCard";
import Header from "../Components/Header";
import AlternateTimeline from "../Components/AlternateTimeline";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import { Link } from "react-router-dom";
import { RiFileExcel2Line } from "react-icons/ri";
import { Button, createTheme, IconButton, ThemeProvider } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [885, 123, 600, 389, 450, 802, 612],
      backgroundColor: "#003049ff",
    },
    {
      label: "Dataset 2",
      data: [521, 349, 142, 779, 410, 109, 840],
      backgroundColor: "#818cf8",
    },
  ],
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    green: {
      main: "#11ad0c",
    },
  },
});
const Home = () => {
  const tableRef = useRef(null);
  const url = process.env.REACT_APP_API_URL;
  const [adminDetails, setAdminDetails] = useState("");
  const [statusData, setStatusData] = useState("");
  const [loading, setLoading] = useState(true);
  const [summaryDataLoading, setSummaryDataLoading] = useState(false);
  const [summaryApiData, setSummaryApiData] = useState([]);
  const [stationOfficerData, setStationOfficerData] = useState([]);

  const callThisApiForStats = async () => {
    setLoading(true);
    const statusRes = await fetch(`${url}status/get/status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ipaddress: "192.168.1.2",
        auth: localStorage.getItem("token"),
      },
    });

    const data = await statusRes.json();
    setStatusData(data.status);
    setLoading(false);
  };

  const summaryData = async () => {
    setSummaryDataLoading(true);
    const statusRes = await fetch(`${url}status/homeSummaryDetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ipaddress: "192.168.1.2",
        auth: localStorage.getItem("token"),
      },
    });

    const data = await statusRes.json();
    setSummaryApiData(data.data);

    const officerRes = await fetch(
      `${process.env.REACT_APP_API_URL}status/stationOfficerSummary`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          // stationID: station_id,
        }),
      }
    );

    const officerListData = await officerRes.json();
    console.log(officerListData.data);
    setStationOfficerData(officerListData.data);

    setSummaryDataLoading(false);
  };
  useEffect(() => {
    callThisApiForStats();
    summaryData();
    let adminDetails = JSON.parse(localStorage.getItem("userDetails"));
    setAdminDetails(adminDetails);
  }, []);

  return (
    <HomeSection>
      <div className="up-title mt-5 w-11/12 mx-auto">
        <Header category="Dashboard" title="Home" />
      </div>
      <div className="up-cards w-full bg-[rgba(102,155,188,0.3)] py-10">
        <div className="cards w-11/12 mx-auto flex flex-row justify-between">
          <BlockUI blocking={loading || summaryDataLoading} />
          <div className="card h-56 w-56">
            <ThreeRCard
              num={`${
                statusData.allEncroachmentList === undefined
                  ? "0"
                  : statusData.allEncroachmentList
              }`}
              title="Encroachment"
              status={`Pending : ${
                statusData.pendingEncroachmentList === undefined
                  ? "0"
                  : statusData.pendingEncroachmentList
              }`}
              textColor="text-red-500"
              bgColor="white"
              numSize="text-6xl"
              titleSize="text-md"
            />
          </div>
          <div className="card h-56 w-56">
            <ThreeRCard
              num={`${
                statusData.successEncroachmentList === undefined
                  ? "0"
                  : statusData.successEncroachmentList
              }`}
              // num="42"
              title="Ongoing Encroachment"
              // status={`Action Taken : ${statusData.successEncroachmentList}`}
              // status="This are ongoing encroachments"
              status="&#32;"
              textColor="text-green-500"
              bgColor="white"
              numSize="text-6xl"
              titleSize="text-md"
            />
          </div>
          <div className="card h-56 w-56">
            <ThreeRCard
              num={`${
                statusData.allGrievanceList === undefined
                  ? "0"
                  : statusData.allGrievanceList
              }`}
              title="Total Grievances"
              status="&#32;"
              textColor="text-blue-500"
              bgColor="white"
              numSize="text-6xl"
              titleSize="text-md"
            />
          </div>
          <div className="card h-56 w-56">
            <ThreeRCard
              num={`${
                statusData.pendingGrievanceList === undefined
                  ? "0"
                  : statusData.pendingGrievanceList
              }`}
              title="Pending Grievances"
              status={`Rejected Grievances : ${
                statusData.rejectedGrievanceList === undefined
                  ? "0"
                  : statusData.rejectedGrievanceList
              }`}
              textColor="text-yellow-500"
              bgColor="white"
              numSize="text-6xl"
              titleSize="text-md"
            />
          </div>
        </div>

        {adminDetails.userType === "S" ||
        adminDetails.userType === "SP" ||
        adminDetails.userType === "CO" ? (
          <div className="up-bar-chart w-11/12 mx-auto mt-8 flex flex-col">
            <div className="l1 px-2 flex flex-row items-center justify-between">
              <p>Summary</p>
              <DownloadTableExcel
                filename="summaryExcel"
                sheet="station status"
                currentTableRef={tableRef.current}
              >
                <ThemeProvider theme={theme}>
                  <Button aria-label="export excel sheet label">
                    <div className="flex flex-row items-center gap-2">
                      <RiFileExcel2Line color="green" size={20} />
                      <span className="text-[#246c22]">
                        Export to excel sheet
                      </span>
                    </div>
                  </Button>
                </ThemeProvider>
              </DownloadTableExcel>
            </div>
            <div className="l2 flex flex-row items-center justify-evenly">
              <div className="w-full">
                <div className="m-2 ">
                  <table className="w-full mt-8" ref={tableRef}>
                    <thead className="w-full rounded-t-md">
                      <tr className="">
                        <th className="bg-[#EFF2F7] text-[#000000] p-3 rounded-tl-lg text-left">
                          Police Station
                        </th>
                        <th className="bg-blue-300 text-[#000000] p-3">
                          Total
                        </th>
                        <th className="bg-emerald-600 text-[#000000] p-3">
                          Assigned
                        </th>
                        <th className="bg-indigo-300 text-[#000000] p-3">
                          Non-Assigned
                        </th>
                        <th className="bg-green-300 text-[#000000] p-3">
                          Resolved
                        </th>
                        <th className="bg-red-300 text-[#000000] p-3">
                          Rejected
                        </th>
                        <th className="bg-yellow-300 text-[#000000] p-3 rounded-tr-lg">
                          Pending
                        </th>
                        {/* <th className="bg-[#EFF2F7] text-[#000000] p-3 rounded-r-lg break-keep">
                          <span>Defaulter</span>
                          <span>(time boundation)</span>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody className="w-full ">
                      {summaryApiData.map((station) => (
                        <tr className="w-full" key={station.stationId}>
                          <td className="border-b p-4">
                            <Link to={`/station/${station.stationId}`}>
                              {station.stationName}
                            </Link>
                          </td>
                          <td className="border-b p-4 text-center bg-blue-200">
                            {station.totalApplication}
                          </td>
                          <td className="border-b p-4 text-center bg-emerald-500">
                            {station.assignedApplication}
                          </td>
                          <td className="border-b p-4 text-center bg-indigo-200">
                            {station.nonAssignedApplications}
                          </td>
                          <td className="border-b p-4 text-center bg-green-200">
                            {station.resolvedApplications}
                          </td>
                          <td className="border-b p-4 text-center bg-red-200">
                            {station.rejectedApplications}
                          </td>
                          <td className="border-b p-4 text-center bg-yellow-200">
                            {station.pendingApplications}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {adminDetails.userType === "A" ? (
          <div className="up-bar-chart w-11/12 mx-auto mt-8 flex flex-col">
            <div className="l1 px-2 flex flex-row items-center justify-between">
              <p>Summary</p>
              {/* <DownloadTableExcel
                        filename="summaryExcel"
                        sheet="station status"
                        currentTableRef={tableRef.current}
                      >
                        <ThemeProvider theme={theme}>
                          <Button aria-label="export excel sheet label">
                            <div className="flex flex-row items-center gap-2">
                              <RiFileExcel2Line color="green" size={20} />
                              <span className="text-[#246c22]">
                                Export to excel sheet
                              </span>
                            </div>
                          </Button>
                        </ThemeProvider>
                      </DownloadTableExcel> */}
            </div>
            <div className="l2 flex flex-row items-center justify-evenly">
              <div className="w-full">
                <div className="m-2 ">
                  <table className="w-full mt-8">
                    <thead className="w-full rounded-t-md">
                      <tr className="">
                        <th className="bg-[#EFF2F7] text-[#000000] p-3 rounded-tl-lg text-left">
                          Officer
                        </th>
                        <th className="bg-emerald-600 text-[#000000] p-3">
                          Assigned
                        </th>
                        <th className="bg-green-300 text-[#000000] p-3">
                          Resolved
                        </th>
                        <th className="bg-red-300 text-[#000000] p-3">
                          Rejected
                        </th>
                        <th className="bg-yellow-300 text-[#000000] p-3 rounded-tr-lg">
                          Pending
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full ">
                      {stationOfficerData.map((officer) => (
                        <tr className="w-full">
                          <td className="border-b p-4">
                            <Link to={`/admin/${officer.officerID}`}>
                            {officer.officerName}
                            </Link>
                          </td>
                          <td className="border-b p-4 text-center bg-emerald-500">
                            {officer.assignedApplication}
                          </td>
                          <td className="border-b p-4 text-center bg-green-200">
                            {officer.resolvedApplications}
                          </td>
                          <td className="border-b p-4 text-center bg-red-200">
                            {officer.rejectedApplications}
                          </td>
                          <td className="border-b p-4 text-center bg-yellow-200">
                            {officer.pendingApplications}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* <div className="up-bar-chart w-11/12 mx-auto mt-8 flex flex-col">
          <div className="l1">Summary</div>
          <div className="l2 flex flex-row items-center justify-evenly">
            <div className="chart mt-8">
              <Bar options={options} data={data} width={350} height={350} />
            </div>
            <div className="cards">
              <div className="row flex flex-row items-center justify-between gap-4">
                <div className="card h-56 w-56">
                  <ThreeRCard
                    num="214"
                    title="Due Tasks"
                    status=""
                    textColor="text-purple-800"
                    bgColor="rgb(232 234 246)"
                    numSize="text-6xl"
                    titleSize="text-md"
                  />
                </div>
                <div className="card h-56 w-56">
                  <ThreeRCard
                    num="214"
                    title="Due Tasks"
                    status=""
                    textColor="text-green-500"
                    bgColor="rgb(232 245 233)"
                    numSize="text-6xl"
                    titleSize="text-md"
                  />
                </div>
              </div>
              <div className="row flex flex-row items-center justify-between mt-8 gap-2">
                <div className="card h-40 w-24">
                  <ThreeRCard
                    num="12"
                    title="Fixed"
                    status=""
                    textColor="black"
                    bgColor="#8686861e"
                    numSize="text-2xl"
                    titleSize="text-10"
                  />
                </div>
                <div className="card h-40 w-24">
                  <ThreeRCard
                    num="4"
                    title="Won't Fix"
                    status=""
                    textColor="black"
                    bgColor="#8686861e"
                    numSize="text-2xl"
                    titleSize="text-10"
                  />
                </div>
                <div className="card h-40 w-24">
                  <ThreeRCard
                    num="8"
                    title="Re-opened"
                    status=""
                    textColor="black"
                    bgColor="#8686861e"
                    numSize="text-2xl"
                    titleSize="text-10"
                  />
                </div>
                <div className="card h-40 w-24">
                  <ThreeRCard
                    num="2"
                    title="Due Tasks"
                    status=""
                    textColor="black"
                    bgColor="#8686861e"
                    numSize="text-2xl"
                    titleSize="text-10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="up-timeline w-11/12 mx-auto mt-8 flex flex-col">
          <AlternateTimeline />
        </div> */}
      </div>
    </HomeSection>
  );
};

export default Home;

const HomeSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  /* overflow-x: hidden; */
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    &::-webkit-scrollbar {
      display: none;
    }
    .up-bar-chart {
      &::-webkit-scrollbar {
        display: none;
      }
      ${Card}
      background-color: white;
    }
    .up-timeline {
      ${Card}
      background-color: #fff;
    }
  }
`;
