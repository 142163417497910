import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { VscSignOut } from "react-icons/vsc";
import { AiFillMessage } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavbarUserProfile = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const UserProfile = {
    id: 1,
    ProfileName: userDetails.userDisplayName,
    accessLevel: () => {
      if (userDetails.userType === "S") {
        return "Super-Admin";
      } else if (userDetails.userType === "OO") {
        return "Officer";
      } else if (userDetails.userType === "A") {
        return "Admin";
      } else if (userDetails.userType === "SP") {
        return "Superintendent Police";
      } else if (userDetails.userType === "CO") {
        return "Circle Officer";
      }
    },
    profilePicture: userDetails.userProfile,
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          key={UserProfile.id}
          className="up-userProfile cursor-pointer flex flex-row items-center justify-between gap-2 w-52 py-2 hover:bg-gray-100 rounded-full"
        >
          <div className="up-about-content flex-3 flex flex-col">
            <p className="text-sm">{UserProfile.ProfileName}</p>
            <p className="text-10">{UserProfile.accessLevel()}</p>
          </div>
          <div className="up-about-image flex-1 h-10 w-10 rounded-full overflow-hidden">
            {/* <img src={`${UserProfile.profilePicture}`} alt="" /> */}
            <Avatar
              alt={`${UserProfile.ProfileName}`}
              src={`${UserProfile.profilePicture}`}
            />
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2  w-52 rounded-2xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-0">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/My-Profile"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 flex flex-row items-center gap-2 rounded-xl"
                      : "text-gray-700",
                    "block px-4 py-2 text-sm flex flex-row items-center gap-2 rounded-xl"
                  )}
                >
                  <span className="text-xl">
                    <CgProfile />
                  </span>
                  <span> My profile</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="#"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 flex flex-row items-center gap-2 rounded-xl"
                      : "text-gray-700",
                    "block px-4 py-2 text-sm flex flex-row items-center gap-2 rounded-xl"
                  )}
                >
                  <span className="text-xl">
                    <AiFillMessage />
                  </span>
                  <span>Inbox</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/LoginPage"
                  onClick={() => {
                    localStorage.clear();
                  }}
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 flex flex-row items-center gap-2 rounded-xl"
                      : "text-gray-700",
                    "block px-4 py-2 text-sm flex flex-row items-center gap-2 rounded-xl"
                  )}
                >
                  <span className="text-xl">
                    <VscSignOut />
                  </span>
                  <span>Sign out</span>
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NavbarUserProfile;
