import React from "react";
import styled from "styled-components";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import Analytics from "./Analytics";
import Home from "./Home";
import EncroachmentList from "./EncroachmentList";
import { Outlet, Route, Routes } from "react-router-dom";

const Admin = () => {
  return (
    <Section id="admin">
      <div className="flex">
        <Sidebar />
        <div className="flex flex-col h-screen absolute ml-72 overflow-y-auto">
          <Navbar />
          <div className="dashboard mt-16 bg-[#f4f7fa]">
            {/*
            <Routes>
              * Dashboard *
              <Route path="/" element={<Home />} />
              * Pages *
              <Route path="/Project" element={<Project />} />
              <Route path="/Analytics" element={<Analytics />} />
            </Routes> 
            */}
            <Outlet />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Admin;

const Section = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  .dashboard {
    height: calc(100% - 64px);
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
