import React from "react";
import Admin from "./Pages/Admin";
import LoginPage from "./Pages/LoginPage";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import EncroachmentList from "./Pages/EncroachmentList";
import Analytics from "./Pages/Analytics";
import Private from "./Context/Private";
import StationProfile from "./Pages/StationProfile";
import AdminProfile from "./Pages/AdminProfile";
import StationProfileOne from "./Pages/StationProfileOne";
import DataStates from "./Context/DataContext/DataStates";
import AdminProfileOne from "./Pages/AdminProfileOne";
// import EncroachmentDetails from "./Pages/EncroachmentDetails";
import SingleEncroachmentDetail from "./Pages/SingleEncroachmentDetail";
import AdminGrievanceListPage from "./Pages/AdminGrievanceListPage";
import OfficerAllotmentPage from "./Pages/OfficerAllotmentPage";
import MapArea from "./Pages/Map";
import PageNotFound from "./Pages/404Page";
import OfficerProfile from "./Pages/OfficerProfile";
import MyProfile from "./Pages/MyProfile";
import COProfiles from "./Pages/COProfiles";
import CODetails from "./Pages/CODetails";

const App = () => {
  return (
    // <LoginPage />
    <DataStates>
      {/* <Admin /> */}
      <Routes>
        {/* Dashboard */}
        <Route path="/LoginPage" element={<LoginPage />} />
        <Route element={<Private />}>
          <Route path="/" element={<Admin />}>
            <Route path="/" element={<Home />} />
            <Route path="/EncroachmentList" element={<EncroachmentList />} />
            <Route path="/Encroachment-Detail/:encroachmentID" element={<SingleEncroachmentDetail />} />
            <Route path="/AdminProfile" element={<AdminProfile />} />
            <Route path="/OfficerProfile" element={<OfficerProfile />} />
            <Route path="/COProfiles" element={<COProfiles />} />
            <Route path="/CODetails/:coID" element={<CODetails />} />
            <Route path="/stations" element={<StationProfile />} />
            <Route path="/Analytics" element={<Analytics />} />
            <Route path="/station/:station_id" element={<StationProfileOne />} />
            <Route path="/admin/:admin_id" element={<AdminProfileOne />} />
            {/* <Route path="/encroachment-details" element={<EncroachmentDetails />} /> */}
            <Route path="/Admin-Grievance-List" element={<AdminGrievanceListPage />} />
            <Route path="/Grievance-Officer-Allotment/:gID" element={<OfficerAllotmentPage />} />
            <Route path="/map" element={<MapArea />} />
            <Route path="/My-Profile" element={<MyProfile />} />
          </Route>
          <Route path="*" element={<PageNotFound /> } />
        </Route>
      </Routes>
    </DataStates>
  );
};

export default App;
