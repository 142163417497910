// COProfiles

import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { Card } from "../Components/Common/ReusableComponent";
import "react-data-table-component-extensions/dist/index.css";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, IconButton, InputLabel, Tooltip } from "@mui/material";
import DataContext from "../Context/DataContext/DataContext";
import swal from "sweetalert";
import { BsCheckCircleFill, BsStopCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import Avatar from "@mui/material/Avatar";
import { IoInformationCircle } from "react-icons/io5";
import { LoadingButton } from "@mui/lab";
import { FaHourglassEnd } from "react-icons/fa";
import { display } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
  },
});

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const COProfiles = () => {
  // const dataCon = useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // _______________________________________________________
  // station data get useStates -> start
  const [loading, setLoading] = useState(false);
  const [dataColumn, setDataColumn] = useState([]);
  // station data get useStates -> end
  // _______________________________________________________
  // officer data post useStates -> start
  const [openNewOfficerAllotmentModal, setOpenNewOfficerAllotmentModal] =
    useState(false);
  const handleOpenNewOfficerAllotmentModal = () =>
    setOpenNewOfficerAllotmentModal(true);
  const handleCloseNewOfficerAllotmentModal = () =>
    setOpenNewOfficerAllotmentModal(false);

  const [newOfficerName, setNewOfficerName] = useState("");
  const [newOfficerEmail, setNewOfficerEmail] = useState("");
  const [newOfficerDesignation, setNewOfficerDesignation] = useState("");
  const [newAllotOfficerLoading, setNewAllotOfficerLoading] = useState(false);
  // officer data post useStates -> end
  // _______________________________________________________
  // admin data get useStates -> start
  const [adminGetData, setAdminGetData] = useState([]);
  // admin data get useStates -> end
  // _______________________________________________________

  const [accessType, setAccessType] = React.useState("");

  const [stationName, setStationName] = React.useState("");

  // _______________________________________________________
  const allotNewOfficer = async () => {
    setNewAllotOfficerLoading(true);
    const addNewOfficerRes = await fetch(
      `${process.env.REACT_APP_API_URL}admin/add/officer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          admin_name: newOfficerName,
          email_address: newOfficerEmail,
          admin_designation: newOfficerDesignation,
        }),
      }
    );
    const NewOfficerDetails = await addNewOfficerRes.json();
    if (NewOfficerDetails.status != 200) {
      swal(NewOfficerDetails.message, NewOfficerDetails.description, "warning");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      swal(NewOfficerDetails.message, NewOfficerDetails.description, "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    setNewAllotOfficerLoading(false);
  };
  const getAdminData = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/admins`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );
    const allAdminJson = await res.json();
    setAdminGetData(allAdminJson.admins);
  };

  // _______________________________________________________
  const getStationData = async () => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_API_URL}station/get/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ipaddress: "192.168.1.2",
        auth: localStorage.getItem("token"),
      },
    });
    const allStationsJson = await res.json();
    setLoading(false);
    let fiData = [];
    let myStationElement = allStationsJson.stations;

    for (let i = 0; i < myStationElement.length; i++) {
      let upd = {
        station_name: myStationElement[i].station_name,
        // station_address: myStationElement[i].station_address,
        // station_flag: myStationElement[i].station_flag,
        _id: myStationElement[i]._id,
      };
      fiData.push(upd);
    }
    setDataColumn(fiData);
  };
  // _______________________________________________________
  /* get Designation Name Function */
  const getDesignationNameFunc = (userType) => {
    switch (userType) {
      case "OO":
        return "Officer";
      case "A":
        return "Admin";
      case "CO":
        return "Circle Officer";
      case "SP":
        return "Superintendent Police";
      case "S":
        return "SuperAdmin";

      default:
        return "Officer";
    }
  };
  // _______________________________________________________
  /* get Designation Color Function */
  const getDesignationColorFunc = (userType) => {
    switch (userType) {
      case "OO":
        return "bg-[#454545]";
      case "A":
        return "bg-[#2196f3]";
      case "CO":
        return "bg-[#ff8800]";
      case "SP":
        return "bg-[#fb6f92]";
      case "S":
        return "bg-[#dc2f02]";

      default:
        return "bg-[#93C5FD]";
    }
  };
  // _______________________________________________________
  /* userDetails useState */
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const userDetailsCheck = localStorage.getItem("userDetails");
    setUserDetails(JSON.parse(userDetailsCheck));
    getAdminData();
  }, []);

  return (
    <COProfilesSection>
      <div className="up-title mt-5 w-11/12 mx-auto flex flex-row justify-between items-center ">
        <Header category="Dashboard" title="Circle Officer Profile" />
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleOpenNewOfficerAllotmentModal}
            color="primary"
            variant="contained"
            sx={{
              background: "#003049",
              borderRadius: "6px",
              display: `${userDetails.userType === "SP" ? "none" : "block"}`,
            }}
          >
            Add Officer
          </Button>
          <Modal
            open={openNewOfficerAllotmentModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleTwo}>
              <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                  <TextField
                    id="outlined-basic"
                    label="Officer Name"
                    variant="outlined"
                    sx={{
                      width: "50%",
                    }}
                    onChange={(e) => {
                      setNewOfficerName(e.target.value);
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Officer Email"
                    type="email"
                    variant="outlined"
                    className="modal-numType-textfield"
                    sx={{
                      width: "50%",
                    }}
                    onChange={(e) => {
                      setNewOfficerEmail(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="row w-full mx-auto flex flex-row gap-4">
                                  <FormControl fullWidth>
                                    <InputLabel id="access-type-label">
                                      Access Type
                                    </InputLabel>
                                    <Select
                                      labelId="access-type-label"
                                      id="simple-select"
                                      defaultValue="OO"
                                      disabled
                                      // value={accessType}
                                      label="Access Type"
                                      // onChange={handleAccessChange}
                                    >
                                      <MenuItem value="A">Admin</MenuItem>
                                      <MenuItem value="OO">
                                        Other Officer
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div> */}
                <div className="row w-full mx-auto flex flex-row">
                  <TextField
                    id="outlined-multiline-static"
                    label="Officer Designation"
                    sx={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setNewOfficerDesignation(e.target.value);
                    }}
                  />
                </div>
                <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                  <Button
                    id="AP-closeModalBtn"
                    color="secondary"
                    variant="contained"
                    sx={{ borderRadius: "6px" }}
                    onClick={handleCloseNewOfficerAllotmentModal}
                  >
                    Close Modal
                  </Button>
                  <LoadingButton
                    // size="small"
                    id="saveChangesEditProfileButton"
                    color="primary"
                    onClick={allotNewOfficer}
                    loading={newAllotOfficerLoading}
                    loadingIndicator={<FaHourglassEnd size={20} />}
                    variant="contained"
                    sx={{
                      background: "#003049",
                      borderRadius: "6px",
                    }}
                  >
                    Add New officer
                  </LoadingButton>
                </div>
              </div>
            </Box>
          </Modal>
        </ThemeProvider>
      </div>
      <div className="up-cards w-full bg-[#f4f7fa] py-10">
        <div className="up-card w-11/12 mx-auto">
          <ThemeProvider theme={theme}>
            <div className="relative overflow-x-auto sm:rounded-sm">
              {/* table start */}

              <div className="table-body min-h-[500px] relative overflow-x-auto shadow-md sm:rounded-sm">
                <div className="row mb-4">
                  <h1 className="font-semibold">List of Admins</h1>
                  <p className="text-sm w-11/12 mt-2 text-gray-500">
                    These are the list of admins.
                  </p>
                </div>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-100 uppercase dark:bg-gray-50 bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Admin name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Admin designation
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Station name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminGetData.map((e) => (
                      <tr
                        key={e.admin_id}
                        className="border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 "
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap flex flex-row items-center gap-4"
                        >
                          <span>
                            <Avatar
                              alt={`${e.admin_name}`}
                              src={`${e.profile_picture}`}
                            />
                          </span>
                          <span className="flex flex-col">
                            <span>{e.admin_name}</span>
                            <span
                              className={`badge ${getDesignationColorFunc(
                                e.access_type
                              )} w-fit text-white rounded-md px-1 text-12`}
                            >
                              {getDesignationNameFunc(e.access_type)}
                            </span>
                          </span>
                        </th>
                        <td className="px-6 py-4">{e.admin_designation}</td>
                        <td className="px-6 py-4">{e.station_name}</td>

                        <td className="px-6 py-4">
                          {e.admin_flag ? (
                            <BsCheckCircleFill style={{ color: "green" }} />
                          ) : (
                            <BsStopCircleFill style={{ color: "red" }} />
                          )}
                        </td>
                        <td className="px-6 py-4 text-right">
                          <Link to={`/CODetails/${e.admin_id}`}>
                          {/* <Link to={`/CODetails`}> */}
                            <Tooltip title="View" placement="bottom">
                              <IconButton
                                aria-label="fingerprint"
                                color="primary"
                              >
                                <IoInformationCircle />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* table end */}
            </div>
            <BlockUI blocking={loading} />
          </ThemeProvider>
        </div>
      </div>
    </COProfilesSection>
  );
};

export default COProfiles;

const COProfilesSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  /* overflow-x: hidden; */
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    .up-card {
      ${Card}
      background-color: #fff;
      border-radius: 5px;
      padding: 15px;
    }
  }
`;
