import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import { Card } from "../Components/Common/ReusableComponent";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import swal from "sweetalert";
import { BsCheckCircleFill, BsStopCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import Avatar from "@mui/material/Avatar";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
  },
});

const avatarsBackgroundColors = [
  "#180605",
  "#45818e",
  "#6aa84f",
  "#2986cc",
  "#e0a366",
  "#db3c30",
  "#674ea7",
  "#e066a3",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const StationProfile = () => {
  // const dataCon = useContext(DataContext);
  const [dataColumn, setDataColumn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [station_name, setStationName] = useState();
  const [station_contact, setStationContact] = useState();
  const [station_address, setStationAddress] = useState();
  const postStationData = async (e) => {
    let addStationButton = document.getElementById("addStationButton");
    let closeModalButton = document.getElementById("closeModalButton");

    addStationButton.disabled = true;
    addStationButton.innerHTML = "Please Wait...";
    addStationButton.style.backgroundColor = "#858585";
    closeModalButton.disabled = true;

    var sendData = {
      station_name: station_name,
      station_contact: station_contact,
      station_address: station_address,
      co_id : coName,
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}station/add/station`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify(sendData),
      }
    );

    const data = await res.json();

    addStationButton.disabled = false;
    addStationButton.innerHTML = "Add Station";
    addStationButton.style.backgroundColor = "#003049";
    closeModalButton.disabled = false;

    if (data.success) {
      swal(data.message, data.description, "success");
      window.location.reload(1);
    } else {
      swal(data.message, data.description, "error");
    }
  };

  const getStationData = async () => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}station/get/all`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );
    const allStationsJson = await res.json();
    setLoading(false);
    let fiData = [];
    let myStationElement = allStationsJson.stations;

    for (let i = 0; i < myStationElement.length; i++) {
      let upd = {
        station_name: myStationElement[i].station_name,
        station_address: myStationElement[i].station_address,
        station_flag: myStationElement[i].station_flag,
        _id: myStationElement[i]._id,
      };
      fiData.push(upd);
    }
    setDataColumn(fiData);
  };
   // _______________________________________________________

   const [coListData, setCoListData] = useState([]);
   const [coListLoading, setCoListLoading] = useState(false);
   const [coName, setCoName] = useState("");
   const getCOList = async () => {
     setCoListLoading(true);
     const coListAPI = await fetch(
       `${process.env.REACT_APP_API_URL}admin/get/coList`,
       {
         method: "GET",
         headers: {
           "Content-Type": "application/json",
           ipaddress: "192.168.1.2",
           auth: localStorage.getItem("token"),
         },
       }
     );
     const coListAPIData = await coListAPI.json();
     setCoListLoading(false);
 
     let coLists = coListAPIData.data;
     setCoListData(coLists);
   };
   // _______________________________________________________
   const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const userDetailsCheck = localStorage.getItem("userDetails");
    setUserDetails(JSON.parse(userDetailsCheck));
    getCOList();
    getStationData();
  }, []);
  // dataCon.setSD(dataColumn)
  const data = dataColumn;

  return (
    <StationProfileSection>
      <div className="up-title mt-5 w-11/12 mx-auto flex flex-row justify-between items-center">
        <Header category="Dashboard" title="Station Profile" />
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleOpen}
            color="primary"
            variant="contained"
            sx={{ background: "#003049", borderRadius: "6px" }}
          >
            Add Station
          </Button>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="box w-11/12 py-8 mx-auto flex flex-col justify-center items-center gap-8">
                <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                  <TextField
                    id="outlined-basic"
                    label="Station Name"
                    variant="outlined"
                    sx={{
                      width: "50%",
                    }}
                    onChange={(e) => {
                      setStationName(e.target.value);
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Station Contact"
                    type="number"
                    variant="outlined"
                    className="modal-numType-textfield"
                    sx={{
                      width: "50%",
                    }}
                    onChange={(e) => {
                      setStationContact(e.target.value);
                    }}
                  />
                </div>
                <div className="row w-full mx-auto flex flex-row">
                  <TextField
                    id="outlined-multiline-static"
                    label="Station Address"
                    multiline
                    rows={4}
                    sx={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setStationAddress(e.target.value);
                    }}
                    // defaultValue="Default Value"
                  />
                </div>
                <div
                  className={`row w-full mx-auto flex flex-row ${
                    userDetails.userType === "S" ? "block" : "hidden"
                  } `}
                >
                  <FormControl fullWidth>
                    <InputLabel id="station-name-label">
                      Circle Officer
                    </InputLabel>
                    <Select
                      labelId="station-name-label"
                      id="simple-select"
                      label="Circle Officer"
                      sx={{
                        width: "50%",
                      }}
                      // we want to add co list here
                      value={coName}
                      onChange={(event) => {
                        setCoName(event.target.value);
                      }}
                    >
                      {/* <MenuItem value={10}>Ten</MenuItem> */}
                      {coListData.map((item) => (
                        <MenuItem value={item.coID} key={item.coID}>
                          {item.coName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                  <Button
                    id="closeModalButton"
                    color="secondary"
                    variant="contained"
                    sx={{ borderRadius: "6px" }}
                    onClick={handleClose}
                  >
                    Close Modal
                  </Button>
                  <Button
                    id="addStationButton"
                    color="primary"
                    variant="contained"
                    sx={{ background: "#003049", borderRadius: "6px" }}
                    onClick={postStationData}
                  >
                    Add Station
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </ThemeProvider>
      </div>
      <div className="up-cards w-full bg-[#f4f7fa] py-10">
        <div className="up-card w-11/12 mx-auto">
          <div className="relative overflow-x-auto sm:rounded-sm">
            {/* table start */}

            <div className="table-body min-h-[500px] relative overflow-x-auto sm:rounded-sm">
              <div className="row mb-4">
                <h1 className="font-semibold">List of Stations</h1>
                <p className="text-sm w-11/12 mt-2 text-gray-500">
                  This is the list of Stations .
                </p>
              </div>
              <div className="table w-full">
                {data.map((e) => (
                  <Link to={`/station/${e._id}`}>
                    <div
                      key={e._id}
                      className="upp-table-row bg-[#EFF2F7] hover:shadow-lg flex flex-row items-center justify-between my-6 px-6 py-4 rounded-md"
                    >
                      <div className="avatarDetails flex flex-row items-center gap-4">
                        <div className="avatar">
                          <Avatar
                            alt={`${e.station_name.toUpperCase()}`}
                            src={`${e.profile_picture}`}
                            sx={{
                              height: "60px",
                              width: "60px",
                              backgroundColor: `${
                                avatarsBackgroundColors[
                                  Math.floor(Math.random() * 8)
                                ]
                              }`,
                            }}
                          />
                        </div>
                        <div className="details flex flex-col items-start justify-start">
                          <div className="stationName font-semibold">
                            {e.station_name}
                          </div>
                          <div className="stationAddress text-12 text-gray-600 ">
                            {e.station_address}
                          </div>
                        </div>
                      </div>
                      <div className="status">
                        {e.station_flag ? (
                          <BsCheckCircleFill
                            style={{
                              color: "green",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        ) : (
                          <BsStopCircleFill
                            style={{
                              color: "red",
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        )}
                      </div>
                      {/* <div
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap flex flex-row items-center gap-4"
                      >
                          <Avatar
                            alt={`${e.station_name}`}
                            src={`${e.profile_picture}`}
                          />
                        <span>{e.station_name}</span>
                      </div>
                      <div className="px-6 py-4">{e.station_address}</div>
                      <div className="px-6 py-4">
                        {e.station_flag ? (
                          <BsCheckCircleFill style={{ color: "green" }} />
                        ) : (
                          <BsStopCircleFill style={{ color: "red" }} />
                        )}
                      </div> */}
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            {/* table end */}
          </div>
          <BlockUI blocking={loading} />
        </div>
      </div>
    </StationProfileSection>
  );
};

export default StationProfile;

const StationProfileSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  &::-webkit-scrollbar {
    display: none;
  }
  .up-cards {
    .up-card {
      ${Card}
      border-radius: 10px;
      background-color: #fff;
    }
  }
  .modal-numType-textfield {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .upp-table-row {
    .avatarDetails {
      transition: all ease 0.3s;
    }
    .status {
      transition: all ease 0.3s;
    }
    &:hover {
      .avatarDetails {
        transform: scale(1.05);
      }
      .status {
        transform: scale(1.05);
      }
    }
  }
  /* .table-body {
    height: 67vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  } */
`;
