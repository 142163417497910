import React, { useState } from "react";
import DataContext from "./DataContext";

const DataStates = ({ children }) => {
  // const stationRowsData = {};
  const [stationData, setStationData] = useState();
  
  // function setSD(e) {
  //   setStationData(e);
  // }

  return (
    <DataContext.Provider value={{ stationData, setStationData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataStates;
