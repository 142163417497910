import React, { useEffect } from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { BiUserCircle } from "react-icons/bi";
import { IoLocationSharp, IoNewspaperSharp } from "react-icons/io5";
import { RiFullscreenFill } from "react-icons/ri";
import { FaSearch } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import NavbarUserProfile from "./NavbarUserProfile";
import { Link } from "react-router-dom";

const Navbar = () => {
  // const UserProfile = {
  //   id: 1,
  //   ProfileName: "Jean Grey",
  //   accessLevel: "Super-Admin",
  //   profilePicture: "/assets/images/users/JeanGrey.webp",
  // };

  function toggleFullscreen(elem) {
    elem = elem || document.documentElement;

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  useEffect(() => {
    document
      .getElementById("fullScreenToggleButton")
      .addEventListener("click", function () {
        toggleFullscreen();
      });
  }, []);

  return (
    <Section>
      <div className="h-16 flex flex-row items-center justify-between px-8">
        <div className="up-front-icons flex flex-row gap-1">
          <div className="icon">
            <Link to="/My-Profile">
              <Tooltip title="My Profile">
                <IconButton
                  sx={{
                    padding: "10px",
                    fontSize: "20px",
                    color: "#111827",
                  }}
                >
                  <BiUserCircle />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
          <div className="icon">
            <Link to="/map">
              <Tooltip title="Flag Locations">
                <IconButton
                  sx={{
                    padding: "10px",
                    fontSize: "20px",
                    color: "#111827",
                  }}
                >
                  <IoLocationSharp />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
          {/* <div className="icon">
            <Tooltip title="Records">
              <IconButton
                sx={{
                  padding: "10px",
                  fontSize: "20px",
                  color: "#111827",
                }}
              >
                <IoNewspaperSharp />
              </IconButton>
            </Tooltip>
          </div> */}
        </div>
        <div className="up-end-content flex flex-row items-center gap-1">
          <div className="up-icons flex flex-row gap-1">
            <div className="icon">
              <Tooltip title="Fullscreen toggle">
                <IconButton
                  id="fullScreenToggleButton"
                  sx={{
                    padding: "10px",
                    fontSize: "20px",
                    color: "#111827",
                  }}
                >
                  <RiFullscreenFill />
                </IconButton>
              </Tooltip>
            </div>
            {/* <div className="icon">
              <Tooltip title="Search">
                <IconButton
                  sx={{
                    padding: "10px",
                    fontSize: "20px",
                    color: "#111827",
                  }}
                >
                  <FaSearch />
                </IconButton>
              </Tooltip>
            </div> 
            <div className="icon">
              <Tooltip title="Notification">
                <IconButton
                  sx={{
                    padding: "10px",
                    fontSize: "20px",
                    color: "#111827",
                  }}
                >
                  <IoMdNotifications />
                </IconButton>
              </Tooltip>
            </div> */}
          </div>
          {/* userProfile normal code without dropdown */}
          {/* <div
            key={UserProfile.id}
            className="up-userProfile cursor-pointer flex flex-row items-center justify-center w-40 px-3 py-1 hover:bg-gray-100 rounded-full"
          >
            <div className="up-about-content flex-2 flex flex-col">
              <p className="text-sm">{UserProfile.ProfileName}</p>
              <p className="text-10">{UserProfile.accessLevel}</p>
            </div>
            <div className="up-about-image flex-1 h-10 w-10 rounded-full overflow-hidden">
              <img src={`${UserProfile.profilePicture}`} alt="" />
            </div>
          </div> */}
          <NavbarUserProfile />
        </div>
      </div>
    </Section>
  );
};

export default Navbar;

const Section = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --background: #111827;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100% - 288px);
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;
`;
