import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BsClipboardCheck } from "react-icons/bs";
import { FaChartPie, FaHome } from "react-icons/fa";
import { IoMdSchool, IoMdContacts } from "react-icons/io";
import { RiCustomerServiceFill } from "react-icons/ri";
import { MdOutlineLocalPolice } from "react-icons/md";
import { GiPoliceBadge } from "react-icons/gi";

const Sidebar = () => {
  const [userType, setUserType] = useState("A");

  const dynamicLinks = [
    {
      // title: "Dashboard S",
      title: "Dashboard",
      about: "Dashboard links are below",
      links: [
        {
          id: 1,
          name: "Dashboard",
          link: "/",
          icon: <FaHome />,
        },
        {
          id: 2,
          name: "Station Profile",
          link: "/stations",
          icon: <MdOutlineLocalPolice />,
        },
        {
          id: 3,
          name: "Admin Profile",
          link: "/AdminProfile",
          icon: <GiPoliceBadge />,
        },
        {
          id: 4,
          name: "Encroachment List",
          link: "EncroachmentList",
          icon: <BsClipboardCheck />,
        },
        {
          id: 5,
          name: "Grievance",
          link: "Admin-Grievance-List",
          icon: <BsClipboardCheck />,
        },
        // {
        //   name: "Analytics",
        //   link: "Analytics",
        //   icon: <FaChartPie />,
        // },
      ],
    },
    {
      // title: "Dashboard A",
      title: "Dashboard",
      about: "Dashboard links are below",
      links: [
        {
          id: 1,
          name: "Dashboard",
          link: "/",
          icon: <FaHome />,
        },
        {
          id: 2,
          name: "Officer Profile",
          link: "/OfficerProfile",
          icon: <GiPoliceBadge />,
        },
        {
          id: 3,
          name: "Encroachment List",
          link: "EncroachmentList",
          icon: <BsClipboardCheck />,
        },
        {
          id: 4,
          name: "Grievance",
          link: "Admin-Grievance-List",
          icon: <BsClipboardCheck />,
        },
      ],
    },
    {
      // title: "Dashboard SP",
      title: "Dashboard SP",
      about: "Dashboard links are below",
      links: [
        {
          id: 1,
          name: "Dashboard",
          link: "/",
          icon: <FaHome />,
        },
        {
          id: 2,
          name: "CO Profile",
          link: "/COProfiles",
          icon: <GiPoliceBadge />,
        },
        {
          id: 3,
          name: "Encroachment List",
          link: "EncroachmentList",
          icon: <BsClipboardCheck />,
        },
        {
          id: 4,
          name: "Grievance",
          link: "Admin-Grievance-List",
          icon: <BsClipboardCheck />,
        },
      ],
    },
    {
      // title: "Dashboard CO",
      title: "Dashboard CO",
      about: "Dashboard links are below",
      links: [
        {
          id: 1,
          name: "Dashboard",
          link: "/",
          icon: <FaHome />,
        },
        {
          id: 2,
          name: "Admin Profile",
          link: "/AdminProfile",
          icon: <GiPoliceBadge />,
        },
        {
          id: 3,
          name: "Station Profile",
          link: "/stations",
          icon: <MdOutlineLocalPolice />,
        },
        {
          id: 4,
          name: "Encroachment List",
          link: "EncroachmentList",
          icon: <BsClipboardCheck />,
        },
        {
          id: 5,
          name: "Grievance",
          link: "Admin-Grievance-List",
          icon: <BsClipboardCheck />,
        },
      ],
    },
  ];

  let Links = {
    title: "Dashboard",
    about: "Dashboard links are below",
    links: [
      {
        id: 1,
        name: "Dashboard",
        link: "/",
        icon: <FaHome />,
      },
      {
        id: 2,
        name: "Station Profile",
        link: "/stations",
        icon: <MdOutlineLocalPolice />,
      },
      {
        id: 3,
        name: "Admin Profile",
        link: "/AdminProfile",
        icon: <GiPoliceBadge />,
      },
      {
        id: 4,
        name: "Encroachment List",
        link: "EncroachmentList",
        icon: <BsClipboardCheck />,
      },
      {
        id: 5,
        name: "Grievance",
        link: "Grievance",
        icon: <BsClipboardCheck />,
      },
      // {
      //   name: "Analytics",
      //   link: "Analytics",
      //   icon: <FaChartPie />,
      // },
    ],
  };
  if (userType === "S") {
    Links = dynamicLinks[0];
    // console.log("this is super-admin");
  } else if (userType === "A") {
    Links = dynamicLinks[1];
    // console.log("this is admin");
  } else if (userType === "SP") {
    Links = dynamicLinks[2];
    // console.log("this is SP");
  } else if (userType === "CO") {
    Links = dynamicLinks[3];
    // console.log("this is CO");
  }

  useEffect(() => {
    // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userDetailsCheck = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsCheck);
    // console.log(userDetails.userType)
    if (userDetailsCheck) {
      setUserType(userDetails.userType);
    } else {
      setUserType("A");
    }
    if (userType === "S") {
      Links = dynamicLinks[0];
    } else if (userType === "A") {
      Links = dynamicLinks[1];
    }
  }, []);

  return (
    <div>
      <Aside className="w-72 h-screen" aria-label="Sidebar">
        <SidebarDiv className="overflow-y-auto h-full py-4 px-3 bg-[#111827] dark:bg-[#fef8ecff] flex flex-col">
          <Link to="/" className="flex items-center w-11/12 mx-auto pl-2.5">
            <img
              src="/assets/images/logos/Upplogo.png"
              className="h-6 mr-3 sm:h-7"
              alt="Logo"
            />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-[#fef8ecff]">
              UPP
            </span>
          </Link>
          {/* <div className="up-profile self-center flex flex-col items-center justify-center">
            <div className="up-avatar overflow-hidden flex items-center justify-center h-28 w-28 mt-8">
              <Avatar
                sx={{
                  height: "100%",
                  width: "100%",
                  fontSize: "4em",
                }}
                alt={`${UserProfile.ProfileName}`}
                src={`${UserProfile.profilePicture}`}
              />
            </div>
            <div className="up-avatar-about mt-8 text-center">
              <h3 className="text-white">{UserProfile.ProfileName}</h3>
              <h4 className="text-[#669bbcb6] text-12 mt-2">{UserProfile.email}</h4>
            </div>
          </div> */}
          <div className="list">
            <div
              className="sidebar-links text-white w-11/12 mx-auto my-3"
              key={Links.title}
            >
              <div className="side-head uppercase text-sm mt-8 text-[#818cf8] font-semibold px-3">
                {Links.title}
              </div>
              <div className="side-about text-12 mt-1 mb-3 text-[#9ca3af] px-3">
                {Links.about}
              </div>
              <ul>
                {Links.links.map((internalLinks) => (
                  <Link to={`${internalLinks.link}`} key={internalLinks.name}>
                    <li className="flex flex-row items-center justify-start gap-4 py-2 px-4 rounded hover:bg-gray-500">
                      <span className="text-lg my-0.5">
                        {internalLinks.icon}
                      </span>
                      <span className=" text-sm my-0.5">
                        {internalLinks.name}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        </SidebarDiv>
      </Aside>
    </div>
  );
};

export default Sidebar;

const Aside = styled.aside`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  font-family: "Inter", sans-serif;
  /* .up-avatar {
    border-radius: 50%;
    background: url("/assets/images/avatar/singham.webp");
    background-size: cover;
    background-position: center;
  } */
`;
const SidebarDiv = styled.div`
  transition: all ease 0.3s;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9ca3af;
    border-radius: 20px;
    visibility: hidden;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      transition: 300ms;
    }
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px #111827;
  }
`;
