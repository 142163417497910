import { useEffect, useMemo, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import styled from "styled-components";
import { AiFillFlag } from "react-icons/ai";
import BlockUI from "../Components/Common/BlockUi/BlockUI";

export default function Home() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBkn19HqhFXc2fFMqpQ_YOx3WOuPoufhZQ",
  });

  // if (!isLoaded) return <div>Loading....</div>;
  if (!isLoaded) {
    return (
      <MapAreaBlockField>
        <main className="h-full flex items-center justify-center text-3xl font-semibold">
          Loading Map ....
        </main>
      </MapAreaBlockField>
    );
  } else {
    return (
      <MapAreaField>
        <Map />
      </MapAreaField>
    );
  }
}

function Map() {
  const [loading, setLoading] = useState(false);
  const [encroachmentCoordinates, setEncroachmentCoordinates] = useState([]);

  const getGEDataList = async (e) => {
    setLoading(true);

    const getEncroachmentListAPI = await fetch(
      `${process.env.REACT_APP_API_URL}encr/admin/get`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const getEncroachmentList = await getEncroachmentListAPI.json();
    setEncroachmentCoordinates(getEncroachmentList.encr);
    // console.log(encroachmentCoordinates);
    setLoading(false);
  };

  useEffect(() => {
    getGEDataList();
  }, []);

  const center = useMemo(() => ({ lat: 21.2514, lng: 81.629 }), []);

  // for circle
  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const getEncrFlagColor = (flag) => {
    switch (flag) {
      case "0":
        return "#b81515ab";
      case "1":
        return "#ccd828ab";
      case "2":
        return "#15b838ab";

      default:
        return "#b81515ab";
    }
  };
  const getEncrFlagStrokeColor = (flag) => {
    switch (flag) {
      case "0":
        return "#b81515";
      case "1":
        return "#ccd828";
      case "2":
        return "#15b838";

      default:
        return "#b81515";
    }
  };

  return (
    // <LoadScript googleMapsApiKey="AIzaSyBkn19HqhFXc2fFMqpQ_YOx3WOuPoufhZQ">
    <GoogleMap
      zoom={13}
      center={center}
      mapContainerClassName="map-container"
      // onLoad={handleOnLoad}
    >
      <BlockUI blocking={loading} />
      {/* <MarkerF
        icon={{
          path: "M64 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V32C0 14.3 14.3 0 32 0S64 14.3 64 32zm40.8 302.8c-3 .9-6 1.7-8.8 2.6V13.5C121.5 6.4 153 0 184 0c36.5 0 68.3 9.1 95.6 16.9l1.3 .4C309.4 25.4 333.3 32 360 32c26.8 0 52.9-6.8 73-14.1c9.9-3.6 17.9-7.2 23.4-9.8c2.7-1.3 4.8-2.4 6.2-3.1c.7-.4 1.1-.6 1.4-.8l.2-.1c9.9-5.6 22.1-5.6 31.9 .2S512 20.6 512 32V288c0 12.1-6.8 23.2-17.7 28.6L480 288c14.3 28.6 14.3 28.6 14.3 28.6l0 0 0 0-.1 0-.2 .1-.7 .4c-.6 .3-1.5 .7-2.5 1.2c-2.2 1-5.2 2.4-9 4c-7.7 3.3-18.5 7.6-31.5 11.9C424.5 342.9 388.8 352 352 352c-37 0-65.2-9.4-89-17.3l-1-.3c-24-8-43.7-14.4-70-14.4c-27.5 0-60.1 7-87.2 14.8z",
          fillColor: `red`,
          fillOpacity: 0.9,
          scale: 0.05,
          strokeColor: "red",
          strokeWeight: 2,
        }}
        position={{
          lat: 21.2514,
          lng: 81.629,
        }}
      /> */}
      {encroachmentCoordinates.map((coordinate) => (
          <MarkerF
            key={coordinate._id}
            icon={{
              path: "M64 32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V32C0 14.3 14.3 0 32 0S64 14.3 64 32zm40.8 302.8c-3 .9-6 1.7-8.8 2.6V13.5C121.5 6.4 153 0 184 0c36.5 0 68.3 9.1 95.6 16.9l1.3 .4C309.4 25.4 333.3 32 360 32c26.8 0 52.9-6.8 73-14.1c9.9-3.6 17.9-7.2 23.4-9.8c2.7-1.3 4.8-2.4 6.2-3.1c.7-.4 1.1-.6 1.4-.8l.2-.1c9.9-5.6 22.1-5.6 31.9 .2S512 20.6 512 32V288c0 12.1-6.8 23.2-17.7 28.6L480 288c14.3 28.6 14.3 28.6 14.3 28.6l0 0 0 0-.1 0-.2 .1-.7 .4c-.6 .3-1.5 .7-2.5 1.2c-2.2 1-5.2 2.4-9 4c-7.7 3.3-18.5 7.6-31.5 11.9C424.5 342.9 388.8 352 352 352c-37 0-65.2-9.4-89-17.3l-1-.3c-24-8-43.7-14.4-70-14.4c-27.5 0-60.1 7-87.2 14.8z",
              fillColor: `${getEncrFlagColor(coordinate.encr_flag)}`,
              fillOpacity: 0.9,
              scale: 0.05,
              strokeColor: `${getEncrFlagStrokeColor(coordinate.encr_flag)}`,
              strokeWeight: 2,
            }}
            position={{
              lat: parseFloat(coordinate.encr_location.split(",")[0]),
              lng: parseFloat(coordinate.encr_location.split(",")[1]),
            }}
          />
      ))}
      {/* <Circle center={{ lat: 21.2551, lng: 81.6464 }} options={options} /> */}
    </GoogleMap>
    //  </LoadScript>
  );
}

const MapAreaField = styled.section`
  width: calc(100vw - 288px);
  .map-container {
    height: calc(100vh - 4rem);
    width: 100%;
  }
`;
const MapAreaBlockField = styled.section`
  width: calc(100vw - 288px);
  height: calc(100vh - 4rem);
`;
