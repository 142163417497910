import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import BlockUI from "../Components/Common/BlockUi/BlockUI";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { ThemeProvider } from "@mui/system";
import Switch from "@mui/material/Switch";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    custom: {
      main: "#818cf8",
    },
    greenCustom: {
      main: "#00d100",
    },
    redCustom: {
      main: "#d10000",
      background: "#d10000",
    },
  },
});
const switchTheme = createTheme({
  palette: {
    primary: {
      main: "#003049ff",
    },
    secondary: {
      main: "#C1121F",
    },
    custom: {
      main: "#818cf8",
    },
    greenCustom: {
      main: "#00d100",
    },
    redCustom: {
      main: "#d10000",
      background: "#d10000",
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: "#ccc",
        "&$checked": {
          color: "#f2ff00",
        },
      },
      track: {
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          opacity: 0.7,
          backgroundColor: "#fff",
        },
      },
    },
  },
});

const initialState = {
  success: false,
  status: 500,
  message: "Warning",
  admin_id: "Admin Id doesn't match",
  admin_name: "Unknown",
  admin_address: "Unknown",
  admin_contact: "Unknown",
  admin_flag: false,
};

const avatarsBackgroundColors = [
  "#180605",
  "#45818e",
  "#6aa84f",
  "#2986cc",
  "#e0a366",
  "#db3c30",
  "#674ea7",
  "#e066a3",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const grievanceColumns = [
  { field: "grievanceID", headerName: "ID", type: "number", width: 70 },
  { field: "grievanceName", headerName: "Name", width: 130 },
  { field: "grievanceTitle", headerName: "Grievance Title", width: 130 },
  { field: "grievanceMobile", headerName: "Mobile", width: 130 },
  {
    field: "grievanceVillage",
    headerName: "Village",
    width: 90,
  },
];
const encroachmentColumns = [
  { field: "encroacherName", headerName: "Name", width: 200 },
  { field: "encroacherVillage", headerName: "Village", width: 130 },
  { field: "encroacherMobile", headerName: "Mobile", width: 130 },
  {
    field: "allotedOfficerName",
    headerName: "Alloted Officer",
    width: 200,
  },
  { field: "encrFlag", headerName: "Status", width: 60 },
];

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
// ];

const AdminProfileOne = () => {
  const { admin_id } = useParams();
  const [loading, setLoading] = useState();
  const [singleAdminData, setSingleAdminData] = useState(initialState);
  const [pageSize, setPageSize] = useState(5);
  // _______________________________________________________

  const [adminStationData, setAdminStationData] = useState({});
  const [grievanceRowData, setGrievanceRowData] = useState({});
  const [encroachmentRowData, setEncroachmentRowData] = useState({});

  /* editProfile Modal useStates : start*/
  const [openEditProfileModal, setOpenEditProfileModal] = useState();
  const handleOpenEditProfileModal = () => setOpenEditProfileModal(true);
  const handleCloseEditProfileModal = () => setOpenEditProfileModal(false);
  const [admin_name, setAdmin_name] = useState();
  const [admin_designation, setAdmin_designation] = useState();
  /* editProfile Modal useStates : end*/
  // _______________________________________________________
  const postUpdateEditProfileFunction = async () => {
    let saveChangesEditProfileButton = document.getElementById(
      "saveChangesEditProfileButton"
    );
    let closeModalEditProfileButton = document.getElementById(
      "closeModalEditProfileButton"
    );
    saveChangesEditProfileButton.disabled = true;
    saveChangesEditProfileButton.innerHTML = "Please Wait...";
    saveChangesEditProfileButton.style.backgroundColor = "#858585";
    closeModalEditProfileButton.disabled = true;

    let sendData = {
      admin_id: admin_id,
      admin_name: admin_name,
      admin_designation: admin_designation,
    };
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/update/details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
        body: JSON.stringify(sendData),
      }
    );

    const data = await res.json();

    saveChangesEditProfileButton.disabled = false;
    saveChangesEditProfileButton.innerHTML = "Save Changes";
    saveChangesEditProfileButton.style.backgroundColor = "#003049";
    closeModalEditProfileButton.disabled = false;

    if (data.success) {
      swal(data.message, data.description, "success");
      window.location.reload();
    } else {
      swal(data.message, data.description, "error");
    }
  };
  // _______________________________________________________
  /* password editModal useStates : start*/
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  /* password editModal useStates : end*/
  const postUpdatePasswordLogicFunction = async () => {
    let saveChangesButton = document.getElementById("saveChangesButton");
    let closeModalButton = document.getElementById("closeModalButton");

    if (password === confirmPassword) {
      saveChangesButton.disabled = true;
      saveChangesButton.innerHTML = "Please Wait...";
      saveChangesButton.style.backgroundColor = "#858585";
      closeModalButton.disabled = true;

      let sendData = {
        admin_id: admin_id,
        password: password,
      };
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}admin/update/password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "192.168.1.2",
            auth: localStorage.getItem("token"),
          },
          body: JSON.stringify(sendData),
        }
      );

      const data = await res.json();

      saveChangesButton.disabled = false;
      saveChangesButton.innerHTML = "Save Changes";
      saveChangesButton.style.backgroundColor = "#003049";
      closeModalButton.disabled = false;

      if (data.success) {
        swal(data.message, data.description, "success");
      } else {
        swal(data.message, data.description, "error");
      }
    } else {
      swal(
        "Passwords do not match",
        "you have not re-entered your password",
        "warning"
      );
    }
  };
  // _______________________________________________________
  /* Toggle use states : start */
  const [checked, setChecked] = React.useState();
  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const handleToggleFunction = async () => {
    let sendData = {
      admin_id: admin_id,
    };
    if (singleAdminData.admin_flag) {
      // flag is true then make it false
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}admin/admin/block/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "192.168.1.2",
            auth: localStorage.getItem("token"),
          },
          body: JSON.stringify(sendData),
        }
      );

      const data = await res.json();
      setLoading(false);
      if (data.success) {
        swal(data.message, data.description, "success");
        window.location.reload();
      } else {
        swal(data.message, data.description, "error");
      }
    } else {
      // flag is false then make it true
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}admin/admin/unblock/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "192.168.1.2",
            auth: localStorage.getItem("token"),
          },
          body: JSON.stringify(sendData),
        }
      );

      const data = await res.json();
      setLoading(false);
      if (data.success) {
        swal(data.message, data.description, "success");
        window.location.reload();
      } else {
        swal(data.message, data.description, "error");
      }
    }
  };
  /* Toggle use states : end */
  // _______________________________________________________
  /* Officers get data use states : start */
  const [officersData, setOfficersData] = useState([]);
  /* Officers get data use states : end */
  // _______________________________________________________
  const adminUser = async (e) => {
    setLoading(true);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/admin/${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    let adminStationID = data.data.station_id;

    setSingleAdminData(data.data);
    if (data.success) {
    } else {
      swal(data.message, "admin ID doesn't match ", "warning");
    }

    const stationDetails = await fetch(
      `${process.env.REACT_APP_API_URL}status/get/grevAndEncrDetailsByOfficerID/${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const adminAPIData = await stationDetails.json();

    setAdminStationData(adminAPIData);
    // console.log(adminAPIData);
    setGrievanceRowData(adminAPIData.grievanceDetails);
    setEncroachmentRowData(adminAPIData.encroachmentDetails);
    setLoading(false);
  };

  const getOfficersList = async (e) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}admin/get/admin/admin/${admin_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ipaddress: "192.168.1.2",
          auth: localStorage.getItem("token"),
        },
      }
    );

    const data = await res.json();
    const aAndOList = data.admins;
    setOfficersData(aAndOList);
  };

  useEffect(() => {
    adminUser();
  }, []);

  return (
    <AdminProfileOneSection>
      <BlockUI blocking={loading} />
      {/* <div className="">{demoData.success ? "codes are comming soon" : "hosiyari hain hosiyari link chedte ho hain link chedte ho"}</div> */}
      <main className="p-8 w-full flex flex-col gap-8">
        <div className="row w-full">
          <h1 className="font-semibold capitalize">
            {singleAdminData.admin_name}
          </h1>
        </div>
        <div className="row w-full flex flex-row items-start justify-between gap-5">
          <div className="col w-4/12 rounded-sm py-4 flex flex-col bg-white">
            <div className="profileIcons p-4 flex flex-col items-center justify-center ">
              <Avatar
                alt={`${singleAdminData.admin_name.toUpperCase()}}`}
                src={`#`}
                sx={{
                  fontSize: "50px",
                  fontWeight: "bold",
                  // padding : "20px",
                  height: "90px",
                  width: "90px",
                  background: "#b70000",
                }}
              />
              <div className="title mt-2 text-lg capitalize font-semibold">
                {singleAdminData.admin_name}
              </div>
              <div className="sub-title text-sm text-gray-400 capitalize">
                {singleAdminData.admin_designation}
              </div>
              <ThemeProvider theme={theme}>
                <div className="btns flex flex-row items-center justify-center gap-2 h-full mt-2">
                  <div className="btn h-full">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "10px",
                        fontWeight: "300",
                        width: "140px",
                        height: "100%",
                      }}
                      onClick={handleOpenEditProfileModal}
                    >
                      Edit profile
                    </Button>
                    <Modal
                      open={openEditProfileModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div className="box py-8 mx-auto flex flex-col justify-center items-center gap-8">
                          <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                            <TextField
                              id="modal-new-admin_name"
                              label="Name"
                              variant="outlined"
                              sx={{
                                width: "100%",
                              }}
                              defaultValue={singleAdminData.admin_name}
                              onChange={(e) => {
                                setAdmin_name(e.target.value);
                              }}
                            />
                          </div>
                          <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                            <TextField
                              id="outlined-basic"
                              label="Designation"
                              // type="number"
                              variant="outlined"
                              className="modal-numType-textfield"
                              sx={{
                                width: "100%",
                              }}
                              defaultValue={singleAdminData.admin_designation}
                              onChange={(e) => {
                                setAdmin_designation(e.target.value);
                              }}
                            />
                          </div>
                          <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                            <Button
                              id="closeModalEditProfileButton"
                              color="secondary"
                              variant="contained"
                              sx={{ borderRadius: "6px" }}
                              onClick={handleCloseEditProfileModal}
                            >
                              Close Modal
                            </Button>
                            <Button
                              id="saveChangesEditProfileButton"
                              color="primary"
                              variant="contained"
                              sx={{
                                background: "#003049",
                                borderRadius: "6px",
                              }}
                              onClick={postUpdateEditProfileFunction}
                            >
                              Save changes
                            </Button>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>

                  <div className="btn h-full">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "10px",
                        fontWeight: "300",
                        width: "140px",
                        height: "100%",
                      }}
                      onClick={handleOpenEditModal}
                    >
                      Change Password
                    </Button>
                    <Modal
                      open={openEditModal}
                      // onClose={handleCloseEditModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div className="box py-8 mx-auto flex flex-col justify-center items-center gap-8">
                          <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                            <TextField
                              id="modal-new-password"
                              label="New password"
                              type="password"
                              variant="outlined"
                              sx={{
                                width: "100%",
                              }}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                          </div>
                          <div className="row w-full mx-auto flex flex-row items-center justify-center gap-4">
                            <TextField
                              id="outlined-basic"
                              label="Confirm password"
                              // type="number"
                              variant="outlined"
                              className="modal-numType-textfield"
                              sx={{
                                width: "100%",
                              }}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                            />
                          </div>
                          <div className="row w-full mx-auto flex flex-row items-center justify-between mt-12">
                            <Button
                              id="closeModalButton"
                              color="secondary"
                              variant="contained"
                              sx={{ borderRadius: "6px" }}
                              onClick={handleCloseEditModal}
                            >
                              Close Modal
                            </Button>
                            <Button
                              id="saveChangesButton"
                              color="primary"
                              variant="contained"
                              sx={{
                                background: "#003049",
                                borderRadius: "6px",
                              }}
                              onClick={postUpdatePasswordLogicFunction}
                            >
                              Save changes
                            </Button>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </ThemeProvider>
              <div className="toggle-btn mt-5">
                <ThemeProvider theme={switchTheme}>
                  <Switch
                    // checked={demoData.station_flag ? checked : ""}
                    checked={singleAdminData.admin_flag}
                    onChange={handleToggle}
                    color="greenCustom"
                    //   color={checked ? "greenCustom" : "redCustom"}
                    inputProps={{ "aria-label": "controlled" }}
                    onClick={handleToggleFunction}
                  />
                </ThemeProvider>
              </div>
            </div>
            <div className="col-1">
              <div className="station-information p-4 flex flex-col gap-4">
                <h1 className="font-semibold">Admin info :</h1>
                <p className="text-12">
                  {singleAdminData.admin_name} is{" "}
                  {singleAdminData.admin_designation} of{" "}
                  {singleAdminData.station_name},
                  {singleAdminData.station_address} carrying email address{" "}
                  {singleAdminData.email_address}
                </p>
              </div>
              <ul className="p-4 flex flex-col gap-2">
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Email Address : </span>
                  <span className="text-12">
                    {singleAdminData.email_address}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Station : </span>
                  <span className="text-12">
                    {singleAdminData.station_name}
                  </span>
                </li>
                <li className=" flex flex-row items-center gap-3 ">
                  <span className="font-bold text-sm">Address: </span>
                  <span className="text-12">
                    {singleAdminData.station_address}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col w-8/12 rounded-sm flex flex-col gap-8">
            <div className="row  flex flex-row items-start justify-between gap-6">
              <div className="card w-1/3 bg-white p-6 flex flex-col items-start justify-between gap-2">
                <div className="uc text-12">Encroachments Under</div>
                <div className="bc text-10 bg-blue-300 px-2 py-0.5 rounded-md">
                  {adminStationData.station_name}
                </div>
                <div className="mc font-bold text-xl">
                  {" "}
                  {adminStationData.encroachments}{" "}
                </div>
              </div>
              <div className="card w-1/3 bg-white p-6 flex flex-col items-start justify-between gap-2">
                <div className="uc text-12">Grievances Under</div>
                <div className="bc text-10 bg-blue-300 px-2 py-0.5 rounded-md">
                  {adminStationData.station_name}
                </div>
                <div className="mc font-bold text-xl">
                  {" "}
                  {adminStationData.grievances}{" "}
                </div>
              </div>
              <div className="card w-1/3 bg-white p-6 flex flex-col items-start justify-between gap-2">
                <div className="uc text-12">Officer</div>
                <div className="bc">
                  <div className="mc font-semibold text-md">
                    {" "}
                    Encroachments : {encroachmentRowData.length}{" "}
                  </div>
                  <div className="bc font-semibold text-md">
                    {" "}
                    Grievances : {grievanceRowData.length}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row bg-white p-6 data-table-grievances flex flex-col"
              style={{ height: 400, width: "100%" }}
            >
              <p className="font-semibold pb-4">Grievance Details :</p>
              <DataGrid
                getRowId={(row) => row._id}
                rows={grievanceRowData}
                columns={grievanceColumns}
                // pageSize={5}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 7, 10, 12, 15]}
              />
            </div>
            <div
              className="row bg-white p-6 data-table-encroachment flex flex-col"
              style={{ height: 400, width: "100%" }}
            >
              {/* encroachmentRowData */}
              <p className="font-semibold pb-4">Encroachment Details :</p>
              <DataGrid
                getRowId={(row) => row.transactionHash}
                rows={encroachmentRowData}
                columns={encroachmentColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 7, 10, 12, 15]}
              />
            </div>
          </div>
        </div>
      </main>
    </AdminProfileOneSection>
  );
};

export default AdminProfileOne;

const AdminProfileOneSection = styled.section`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  width: calc(100vw - 288px);
  &::-webkit-scrollbar {
    display: none;
  }
  .email-id::-webkit-scrollbar {
    display: none;
  }
  .toggle-btn {
    input[type="checkbox"] {
      // Unchecked Styles
      background-color: green;
      color: green;
    }
    input[type="checkbox"]:checked {
      background-color: red;
      color: red;
      // Checked Styles
    }
  }
`;
