import React from "react";
import styled from "styled-components";

const ThreeRCard = ({ num, title , titleSize , numSize, status, textColor, bgColor }) => {
  return (
    <Div
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div
        className={`flex flex-col items-center justify-center h-full w-full gap-2`}
      >
        <div className={`num ${numSize} font-bold ${textColor}`}>{num}</div>
        <div className={`title ${titleSize} font-semibold ${textColor}`}>{title}</div>
        <div className="status text-sm text-center text-gray-500 ">{status}</div>
      </div>
    </Div>
  );
};

export default ThreeRCard;

const Div = styled.div`
  --barn-red: #780000ff;
  --venetian-red: #c1121fff;
  --floral-white: #fef8ecff;
  --prussian-blue: #003049ff;
  --air-superiority-blue: #669bbcff;
  --text-color: #818cf8;
  --sec-color: #9ca3af;

  box-sizing: border-box;
  box-shadow: 0 3px 32px 0 rgba(31, 38, 135, 0.06);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1.8rem 1rem;
  height: 100%;
  width: 100%;
  .flex {
    .status {
      transform: translateY(50%);
    }
  }
  /* z-index: 10; */
`;
